import React from "react";
import { Col, Row, Divider, Card } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import EmptyData from "../emptydata/EmptyData";
import _ from "lodash";

const UGVStatus = (props) => {
  const { mainStore } = useMobxStores();

  console.log(mainStore.ugv_real_time_data)

  return (
    <Row justify="center" align="center">
      <Col span={24} lg={24} xs={24} sm={24}>
        <Divider orientation="center">UGV status </Divider>
        <Card style={{ borderradius: "15px", minHeight: "400px" }}>
          {mainStore.ugv_real_time_data != "" ? (
            <ul>
              <li>
                <b>Battery :</b> {mainStore.ugv_real_time_data.battery}
              </li>
              <li>
                <b>Session id :</b> {mainStore.ugv_real_time_data.sessionId}
              </li>
              <li>
              <b>sentUtc :</b> {mainStore.ugv_real_time_data_full.header.sentUtc}
              </li>
            </ul>
          ) : (
            <EmptyData />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default observer(UGVStatus);
