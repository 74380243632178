import { makeAutoObservable } from "mobx";
import {
  POST,
  PUBLISH,
  GET_SDMA,
  POST_SDMA,
  DELETE_SDMA,
  PUT_SDMA,
  GET_SDMA_IMAGE,
  POST_Test,
  GET_TAS_CONFIG,
  POST_TAS_CONFIG,
  DELETE_SDMA_MANY,
  GET_SDMA_MANY,
} from "../apis/apis";
import { constants } from "../consts/Variables";
import { v4 as uuidv4 } from "uuid";
import { ControlSaveTiles } from "leaflet.offline";
import axios from "axios";

function calculateAverageLatitude(projects) {
  // Initialize variables to store the sum of latitudes and the count of latitudes
  let sumLat = 0;
  let count = 0;

  // Iterate over each project
  projects.forEach((project) => {
    // Extract the latitude value from the project's attributes and convert it to a number
    const latValue = parseFloat(project.attributes.lat[0]);

    // Check if the latValue is a valid number before adding it to the sum
    if (!isNaN(latValue)) {
      sumLat += latValue;
      count++;
    }
  });

  // Calculate the average by dividing the sum by the count
  const averageLat = count > 0 ? sumLat / count : 0;

  return averageLat;
}

function findLatestHighThreatProject(projects) {
  let latestHigh = null;
  let latestMedium = null;
  let latestLow = null;

  projects.forEach((project) => {
    const threatLevel = project.attributes.threatLevel[0];
    const publishedTime = new Date(project.published);

    if (threatLevel === "High") {
      if (!latestHigh || publishedTime > new Date(latestHigh.published)) {
        latestHigh = project;
      }
    } else if (threatLevel === "Moderate") {
      if (!latestMedium || publishedTime > new Date(latestMedium.published)) {
        latestMedium = project;
      }
    } else if (threatLevel === "Low") {
      if (!latestLow || publishedTime > new Date(latestLow.published)) {
        latestLow = project;
      }
    }
  });

  // Return the highest priority threat level project
  if (latestHigh) {
    return latestHigh;
  } else if (latestMedium) {
    return latestMedium;
  } else {
    return latestLow;
  }
}

function findNumber(arr) {
  // Iterate through the array
  for (let i = 0; i < arr.length; i++) {
    // Convert the string to a number using parseFloat
    const num = parseFloat(arr[i]);

    // Check if the converted value is a number and not NaN
    if (!isNaN(num)) {
      return num; // Return the number as a numeric value
    }
  }

  // Return null if no number is found
  return null;
}

function calculateAverageLongitude(projects) {
  // Initialize variables to store the sum of latitudes and the count of latitudes
  let sumLong = 0;
  let count = 0;

  // Iterate over each project
  projects.forEach((project) => {
    // Extract the latitude value from the project's attributes and convert it to a number
    const longValue = parseFloat(project.attributes.long[0]);

    // Check if the latValue is a valid number before adding it to the sum
    if (!isNaN(longValue)) {
      sumLong += longValue;
      count++;
    }
  });

  // Calculate the average by dividing the sum by the count
  const averageLong = count > 0 ? sumLong / count : 0;

  return averageLong;
}
function findObjectWithHighestConfidence(projects) {
  // Initialize variables to store the object with the highest confidence and the highest confidence value
  let maxConfidence = -Infinity;
  let objectWithMaxConfidence = null;

  // Iterate over each project
  projects.forEach((project) => {
    // Extract the confidence value from the project's attributes and convert it to a number
    const confidenceValue = parseFloat(project.attributes.confidence[0]);

    // Check if the confidenceValue is a valid number and if it is greater than the current maxConfidence
    if (!isNaN(confidenceValue) && confidenceValue > maxConfidence) {
      maxConfidence = confidenceValue;
      objectWithMaxConfidence = project;
    }
  });

  return objectWithMaxConfidence;
}
class FieldHistoryStore {
  constructor({ errStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
  }
  sessions = [
    { text: "realTime", value: "realtime" },
    { text: "session-1", value: "543-342" },
    { text: "session-2", value: "883-342" },
  ];
  exportData = { mission_id: "sdf", operation_id: "ino", session_id: "plp" };
  importData = {};
  configData = {};
  showGroups = false;
  trigger = false;
  blueGroup = [];
  orangeGroup = [];
  redGroup = [];
  purpleGroup = [];
  limeGroup = [];
  greenGroup = [];
  cyanGroup = [];
  yellowGroup = [];

  session_data = [];

  waterSensorH202 = [];
  waterSensorNH3 = [];
  waterSensorY = [];
  gas = null;
  // handle deleting objects
  isItemDeleting = false;

  SessionsProjectId = "";
  addressPoints = [];
  currentOperationId = "";
  coords = "";
  analyzedCharacters = [];
  storedMissions = null;
  alertsOn = false;
  selectedKey = "1";
  dateFormat = "YYYY/MM/DD";
  status = "success";
  audit_items = [];
  audit_item = null;
  transaction = null;
  transactions = [];
  webdata = [];
  field_operation = null;
  changeLogItem = [];
  artefacts = [];
  projects = [];
  artefact = null;
  entities = [];
  entity = null;
  links = [];
  link = null;
  accessLogItem = [];
  currentKey = window.location.pathname;
  date = new Date();

  session_id = localStorage.getItem("session_id") || "";
  isKafkaConnected = "failed";
  transcation_coords = ["23.123456", "23.123456"];
  pageSize = 100000000;
  waterSensorData = [];
  ugvstatus = [];
  classification_module = [];
  clustering_module = [];
  social_network_analysis = [];
  searchedData = [];
  isSearching = false;
  battery = "";
  alerts = [];
  alertsTAS = [];
  x = [];
  acetone = { name: "Acetone", data: [], color: "#31438e" };
  toluene = { name: "Toluene", data: [], color: "#66BB6A" };
  x3 = [];

  y = [];
  sensor_air_data = "";
  sensor_water_data = "";

  ugv_real_time_data_full = "";
  uav_real_time_data = "";
  ugv_real_time_data = {
    gpsCoords: {
      latitude: 24,
      longitude: 20,
    },
    battery: 43,
  };
  ugv_history_data = [];
  uav_history_data = [];
  uav_real_time_data_full = "";
  UV_data = [];
  temp = { body: null, images: [] };
  TAS = "";
  TASWater = "";
  higher_TAS = "";
  higher_TASWater = "";
  higher_TASNtdl = "";
  TASNtdl = "";
  TAS_markers = [];
  wp_connection = false;

  analyse = false;
  ugvSelected = false;
  uavSelected = false;
  analyse_entities = [];
  isMissionModalOpen = false;
  isOperationModalOpen = false;
  clusterDomainId = "";
  projectIdForClustering = "";
  projectIdForSN = "";
  snDomainId = "";
  AlertsTASProjectId = "";
  AlertsProjectId = "";
  drones = [];
  isCreateDroneModalOpen = false;
  isDroneCreating = false;
  droneCreated = false;
  isDroneEditing = false;
  EditingDrone = null;
  drone_id = null;
  non = [];
  isAnalysing = false;
  currentFieldOperationSessions = [];
  currentFieldOperationSessionsData = [];
  chosenSessionData = [];
  chosenSessionDataTasAir = [];
  chosenSessionDataTasWater = [];
  chosenSessionDataTasAirCenter = ["", ""];
  chosenSessionDataTasHighest = "";
  chosenSessionDataTasWaterCenter = ["", ""];

  loadDataByParamSessionId = (paramSessionId) => {
    // console.log(this.currentFieldOperationSessionsData);

    this.waterSensorH202 = [];
    this.waterSensorNH3 = [];
    this.waterSensorY = [];
    this.ugv_history_data = [];
    this.uav_history_data = [];
    this.x = [];
    this.acetone = { name: "Acetone", data: [], color: "#31438e" };
    this.toluene = { name: "Toluene", data: [], color: "#66BB6A" };
    this.x3 = [];
    this.chosenSessionDataTasAirCenter = ["", ""];
    this.chosenSessionDataTasAir = [];
    this.chosenSessionDataTasWater = [];
    this.higher_TAS = "";

    this.y = [];
    console.log([...this.currentFieldOperationSessionsData]);
    let newChosenSessionData = [...this.currentFieldOperationSessionsData].find(
      (session) => {
        return paramSessionId === session.sessionId;
      }
    );
    console.log(newChosenSessionData);
    if (newChosenSessionData) {
      // this.chosenSessionData = newChosenSessionData.arrayOfObjects;

      let chosenSessionDataTasAir = newChosenSessionData.arrayOfObjects.filter(
        (artefact) => {
          return artefact.type === "tas:result:air";
        }
      );
      console.log(chosenSessionDataTasAir);
      // console.log(chosenSessionDataTasWater);
      if (chosenSessionDataTasAir.length > 0) {
        let centerLat = calculateAverageLatitude(chosenSessionDataTasAir);
        let centerLong = calculateAverageLongitude(chosenSessionDataTasAir);
        this.chosenSessionDataTasAirCenter = [centerLat, centerLong];

        this.higher_TAS = findLatestHighThreatProject(chosenSessionDataTasAir);
        this.chosenSessionDataTasAir = chosenSessionDataTasAir;
      }

      let chosenSessionDataTasWater = [
        ...newChosenSessionData.arrayOfObjects,
      ].filter((artefact) => {
        return artefact.type === "tas:result:water";
      });
      console.log(chosenSessionDataTasWater);
      if (chosenSessionDataTasWater.length > 0) {
        let centerLat = calculateAverageLatitude(chosenSessionDataTasWater);
        let centerLong = calculateAverageLongitude(chosenSessionDataTasWater);
        this.chosenSessionDataTasWaterCenter = [centerLat, centerLong];

        this.higher_TASWater = findLatestHighThreatProject(
          chosenSessionDataTasWater
        );
        this.chosenSessionDataTasWater = chosenSessionDataTasWater;
      }

      let chosenSessionDataWaterSensorPeaks = [
        ...newChosenSessionData.arrayOfObjects,
      ].filter((artefact) => {
        return (
          artefact.type === "chemical:detected" &&
          artefact.source === "WaterSensor"
        );
      });
      console.log(newChosenSessionData.arrayOfObjects);
      console.log(chosenSessionDataWaterSensorPeaks);

      let chosenSessionDataUGVStatusBeforeSort =
        newChosenSessionData.arrayOfObjects.filter((artefact) => {
          return (
            artefact.type === "UGV:Status"
            //  &&
            // artefact.source === "WaterSensor"
          );
        });

      let chosenSessionDataUGVStatus =
        chosenSessionDataUGVStatusBeforeSort.sort(
          (a, b) => new Date(a.published) - new Date(b.published)
        );
      console.log(chosenSessionDataUGVStatus);

      for (let i = 0; i < chosenSessionDataWaterSensorPeaks.length; i++) {
        if (
          chosenSessionDataWaterSensorPeaks[i].attributes.gas_concentration_1 &&
          chosenSessionDataWaterSensorPeaks[i].attributes.gas_concentration_2
        ) {
          if (
            chosenSessionDataWaterSensorPeaks[
              i
            ].attributes.gas_concentration_1.includes("NH3")
          ) {
            this.waterSensorNH3.push(
              findNumber(
                chosenSessionDataWaterSensorPeaks[i].attributes
                  .gas_concentration_1
              )
            );
            this.waterSensorH202.push(
              findNumber(
                chosenSessionDataWaterSensorPeaks[i].attributes
                  .gas_concentration_2
              )
            );
            this.waterSensorY.push(
              chosenSessionDataWaterSensorPeaks[i].published
            );
          } else {
            this.waterSensorH202.push(
              findNumber(
                chosenSessionDataWaterSensorPeaks[i].attributes
                  .gas_concentration_1
              )
            );
            this.waterSensorNH3.push(
              findNumber(
                chosenSessionDataWaterSensorPeaks[i].attributes
                  .gas_concentration_2
              )
            );
            this.waterSensorY.push(
              chosenSessionDataWaterSensorPeaks[i].published
            );
          }
        }

        if (
          chosenSessionDataWaterSensorPeaks[i].attributes.gas_concentration_1 &&
          !chosenSessionDataWaterSensorPeaks[i].attributes.gas_concentration_2
        ) {
          if (
            chosenSessionDataWaterSensorPeaks[
              i
            ].attributes.gas_concentration_1.includes("NH3")
          ) {
            this.waterSensorNH3.push(
              chosenSessionDataWaterSensorPeaks[i].attributes
                .gas_concentration_1[1]
            );
            this.waterSensorH202.push("");
            this.waterSensorY.push(
              chosenSessionDataWaterSensorPeaks[i].published
            );
          } else {
            this.waterSensorH202.push(
              chosenSessionDataWaterSensorPeaks[i].attributes
                .gas_concentration_1[1]
            );
            this.waterSensorNH3.push("");
            this.waterSensorY.push(
              chosenSessionDataWaterSensorPeaks[i].published
            );
          }
        }
      }

      for (let i = 0; i < chosenSessionDataUGVStatus.length; i++) {
        this.ugv_history_data.push({
          gpsCoords: {
            latitude: parseFloat(
              chosenSessionDataUGVStatus[i].attributes.gps_latitude[0]
            ),
            longitude: parseFloat(
              chosenSessionDataUGVStatus[i].attributes.gps_longitude[0]
            ),
            altitude: parseFloat(
              chosenSessionDataUGVStatus[i].attributes.gps_altitude[0]
            ),
          },
          battery: parseFloat(
            chosenSessionDataUGVStatus[i].attributes.battery_voltage
          ),
          time: chosenSessionDataUGVStatus[i].attributes.published,
        });
      }

      let chosenSessionDataAirSensorDataBeforeSort =
        newChosenSessionData.arrayOfObjects.filter((artefact) => {
          return (
            artefact.type === "chemical:detected" &&
            artefact.source !== "WaterSensor"
          );
        });

      let chosenSessionDataAirSensorData =
        chosenSessionDataAirSensorDataBeforeSort.sort(
          (a, b) => new Date(a.published) - new Date(b.published)
        );

      console.log(chosenSessionDataAirSensorData);

      for (let i = 0; i < chosenSessionDataAirSensorData.length; i++) {
        let foundAcetone = "";
        let foundToluene = "";

        for (
          let y = 0;
          y < JSON.parse(chosenSessionDataAirSensorData[i].raw).peaks.length;
          y++
        ) {
          for (
            let z = 0;
            z <
            JSON.parse(chosenSessionDataAirSensorData[i].raw).peaks[y].compounds
              .length;
            z++
          ) {
            if (
              JSON.parse(chosenSessionDataAirSensorData[i].raw).peaks[y]
                .compounds[z] === "Acetone"
            ) {
              foundAcetone = JSON.parse(chosenSessionDataAirSensorData[i].raw)
                .peaks[y];
            }
            if (
              JSON.parse(chosenSessionDataAirSensorData[i].raw).peaks[y]
                .compounds[z] === "Toluene"
            ) {
              foundToluene = JSON.parse(chosenSessionDataAirSensorData[i].raw)
                .peaks[y];
            }
          }
          this.uav_history_data.push({
            gpsCoords: {
              latitude: JSON.parse(chosenSessionDataAirSensorData[i].raw).lat,
              longitude: JSON.parse(chosenSessionDataAirSensorData[i].raw).lon,
              altitude: JSON.parse(chosenSessionDataAirSensorData[i].raw).alt,
            },
            battery: 50,
            time: JSON.parse(chosenSessionDataAirSensorData[i].raw).TimeUTC,
          });
        }
        console.log(foundAcetone);
        console.log(foundToluene);
        if (foundAcetone && foundToluene) {
          this.acetone.data.push(foundAcetone.value);
          this.toluene.data.push(foundToluene.value);
        }
        if (foundAcetone && !foundToluene) {
          this.acetone.data.push(foundAcetone.value);
          this.toluene.data.push("");
        }
        if (!foundAcetone && foundToluene) {
          this.acetone.data.push("");
          this.toluene.data.push(foundToluene.value);
        }
        if (!foundAcetone && !foundToluene) {
          this.acetone.data.push("");
          this.toluene.data.push("");
        }

        this.y.push(JSON.parse(chosenSessionDataAirSensorData[i].raw).timeUTC);
        this.setAirDataNew();
        console.log(this.y);
        console.log(this.x3);
      }
    }

    console.log(this.chosenSessionData);
  };

  setwaterSensorH202 = (value) => {
    console.log(value);
    this.waterSensorH202.push(value);
  };
  setwaterSensorNH3 = (value) => {
    console.log(value);
    this.waterSensorNH3.push(value);
  };

  setwaterSensorY = (value) => {
    console.log(value);
    this.waterSensorY.push(value);
  };

  setGas = (value) => {
    this.gas = value;
  };

  setIsMissionModalOpen = (value) => {
    this.isMissionModalOpen = value;
  };
  startReal = () => {
    POST_Test("play");
  };
  stepReal = () => {
    POST_Test("step");
  };
  stopReal = () => {
    POST_Test("stop");
  };

  setAlertsOn = (value) => {
    this.alertsOn = value;
  };
  setAlertsTASOn = (value) => {
    this.alertsTASOn = value;
  };

  SetWSConnection = (value) => {
    this.wp_connection = value;
  };

  setTASMarkers = (value) => {
    this.TAS_markers = value;
  };

  getTasConfig = () => {
    return GET_TAS_CONFIG(`config`)
      .then((data) => {
        console.log(data);
        let response = data;
        // this.errStore.setMessage("SDMA connected");
        this.errStore.setInfo(true);
        this.configData = {
          AirLow: response.tas_thresholds_air[0],
          AirMedium: response.tas_thresholds_air[1],
          AirHigh: response.tas_thresholds_air[2],
          WaterLow: response.tas_thresholds_water[0],
          WaterMedium: response.tas_thresholds_water[1],
          WaterHigh: response.tas_thresholds_water[2],
        };
      })
      .catch((error) => {
        this.errStore.setError(true);
        // this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setNewTasConfig = (newData) => {
    return POST_TAS_CONFIG(`config`, newData)
      .then((data) => {
        console.log(data);
        // this.errStore.setMessage("SDMA connected");
        this.errStore.setInfo(true);
        this.getTasConfig();
      })
      .catch((error) => {
        this.errStore.setError(true);
        // this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setTas = (pid, id) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pid}/artefacts?id=${id}`)
      .then((data) => {
        // console.log("tas data: ", data);
        this.errStore.setMessage("");
        if (data.data != []) {
          if (data.data[0].type === "tas:result:air") {
            this.TAS = JSON.parse(data.data[0].raw);
            // this.TAS_markers.push({
            //   latitude: JSON.parse(data.data[0].raw).latitude,
            //   longitude: JSON.parse(data.data[0].raw).longitude,
            // });
            this.errStore.setLoading(false);
            if (this.higher_TAS === "") {
              this.higher_TAS = JSON.parse(data.data[0].raw);
            }
            if (this.higher_TAS !== "") {
              console.log("New: ", JSON.parse(data.data[0].raw).confidence);
              console.log("Old: ", this.higher_TAS.confidence);
              if (
                this.higher_TAS.confidence <
                JSON.parse(data.data[0].raw).confidence
              ) {
                this.higher_TAS = JSON.parse(data.data[0].raw);
                console.log("true");
              }
            }
          }
          if (data.data[0].type === "tas:result:water") {
            this.TASWater = JSON.parse(data.data[0].raw);
            this.errStore.setLoading(false);
            if (this.higher_TASWater === "") {
              this.higher_TASWater = JSON.parse(data.data[0].raw);
            }
            if (this.higher_TASWater !== "") {
              if (
                this.higher_TASWater.confidence <
                JSON.parse(data.data[0].raw).confidence
              ) {
                this.higher_TASWater = JSON.parse(data.data[0].raw);
              }
            }
          }
          if (data.data[0].type === "tas:result:ntdl") {
            this.TASNtdl = JSON.parse(data.data[0].raw);
            this.errStore.setLoading(false);
            if (this.higher_TASNtdl === "") {
              this.higher_TASNtdl = JSON.parse(data.data[0].raw);
            }
            if (this.higher_TASNtdl !== "") {
              if (
                this.higher_TASNtdl.confidence <
                JSON.parse(data.data[0].raw).confidence
              ) {
                this.higher_TASNtdl = JSON.parse(data.data[0].raw);
              }
            }
          }
        } else {
          this.errStore.setInfo(true);
          this.errStore.setMessage("TAS NOT found");
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("TAS NOT found");
        console.log("catch: ", error);
      });
  };

  setAirDataNew = () => {
    if (this.acetone.data.length > 0 && this.toluene.data.length > 0) {
      this.x3 = [this.acetone, this.toluene];
    }
  };
  SetUV_data = (value) => {
    this.UV_data = value;
  };

  setTemp = (value) => {
    this.temp = value;
  };

  setUGVRealTimeData = (value) => {
    this.ugv_real_time_data = value;
  };

  setUGVRealTimeDataFull = (value) => {
    console.log(value);
    this.ugv_real_time_data_full = value;
  };

  setUAVRealTimeData = (value) => {
    this.uav_real_time_data = value;
  };

  setUAVRealTimeDataFull = (value) => {
    console.log(value);
    this.uav_real_time_data_full = value;
  };

  setWaterSensorData = (value) => {
    this.sensor_water_data = value;
  };

  setAirSensorData = (value) => {
    this.sensor_air_data = value;
  };

  setx = (peaks) => {
    const colorsCategories = ["#f57c00", "#31438e", "#66BB6A", "#E53935"];
    console.log(peaks);
    let newPeaks = peaks;
    for (let i = 0; i < newPeaks.length; i++) {
      newPeaks[i].color = colorsCategories[i];
    }
    this.x = newPeaks;
  };
  sety = (value) => {
    this.y = value;
  };
  setAlerts = (value) => {
    this.alerts = value;
  };
  setAlertsTAS = (value) => {
    this.alertsTAS = value;
  };

  setSessionId = (value) => {
    this.session_id = value;
  };

  setIsSearching = (value) => {
    this.isSearching = value;
  };

  setSearchedData = (value) => {
    let newData = value.transactions;

    this.searchedData = value.transactions;
  };

  setBattery = (value) => {
    this.battery = value;
  };

  setSelectedKey = (value) => {
    this.selectedKey = value;
  };

  deleteAlert = (id) => {
    this.errStore.setLoading(true);
    return DELETE_SDMA(`/api/projects/${this.AlertsProjectId}/entities/${id}`)
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);

        this.GetAlerts();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  deleteAlertTAS = (id) => {
    this.errStore.setLoading(true);
    return DELETE_SDMA(
      `/api/projects/${this.AlertsTASProjectId}/entities/${id}`
    )
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);

        this.GetAlertsTAS();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  deleteSession = (id) => {
    console.log(id);
    this.errStore.setLoading(true);
    return DELETE_SDMA(`/api/projects/${this.SessionsProjectId}/entities/${id}`)
      .then((data) => {
        console.log(data);
        // this.setIsOperationModalOpen(false);
        this.errStore.setInfo(true);

        this.GetSessions();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  clearAlerts = (key) => {
    this.alerts = [];
  };
  clearAlertsTAS = (key) => {
    this.alertsTAS = [];
  };
  addAlert = (value) => {
    this.alerts.push(value);
  };
  addAlertTAS = (value) => {
    this.alertsTAS.push(value);
  };
  GetSessions = (pid) => {
    console.log("rerrr");
    this.errStore.setLoading(true);

    GET_SDMA(`/api/projects/${this.SessionsProjectId}/entities?type=session`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        let sessions = data.data.splice(0, data.data.length - 1).map((item) => {
          return {
            id: item.id,
            session_id: item.attributes.session_id[0],
            operation_id: item.attributes.operation_id[0],
          };
        });
        // .map((alert) => {
        //   return {
        //     id: alert.id,
        //     message: JSON.parse(alert.attributes.message[0]),
        //   };
        // });
        this.sessions = sessions;
        console.log(sessions);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetAlertsTAS = (pid) => {
    console.log("rerrr");
    this.errStore.setLoading(true);

    GET_SDMA(`/api/projects/${this.AlertsTASProjectId}/entities?type=TAS_alert`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        let alertsTAS = data.data.map((alert) => {
          return {
            id: alert.id,
            message: JSON.parse(alert.attributes.message[0]),
          };
        });
        // this.alertsTAS = alertsTAS;
        console.log(alertsTAS);
        let ProjectIds = alertsTAS.map((alert) => {
          return alert.message.header.caseId;
        });
        console.log(ProjectIds);
        let uniqueProjectIds = [...new Set(ProjectIds)];
        console.log(uniqueProjectIds);

        let url_missions = uniqueProjectIds.map((uniqueProjectId) => {
          return `/api/projects/${uniqueProjectId}`;
        });
        let url_operations = uniqueProjectIds.map((uniqueProjectId) => {
          return `/api/projects/${uniqueProjectId}/entities?type=field`;
        });
        // let newAlerts = [...alertsTAS];

        GET_SDMA_MANY(url_missions)
          .then((dataMany) => {
            console.log("analyse-39");
            console.log(dataMany);
            for (let i = 0; i < dataMany.length; i++) {
              for (let y = 0; y < alertsTAS.length; y++) {
                if (
                  alertsTAS[y].message.header.caseId ===
                  dataMany[i].data.data.id
                ) {
                  alertsTAS[y].name = dataMany[i].data.data.name;
                }
              }
            }
            console.log(alertsTAS);
            GET_SDMA_MANY(url_operations)
              .then((dataManyOperations) => {
                console.log(dataManyOperations);
                for (let i = 0; i < dataManyOperations.length; i++) {
                  console.log(dataManyOperations[i]);
                  console.log(dataManyOperations[i].data.data);
                  for (
                    let x = 0;
                    x < dataManyOperations[i].data.data.length;
                    x++
                  ) {
                    for (let y = 0; y < alertsTAS.length; y++) {
                      if (
                        alertsTAS[y].message.body.operationId ===
                        dataManyOperations[i].data.data[x].attributes
                          .operationId[0]
                      ) {
                        alertsTAS[y].operationName =
                          dataManyOperations[i].data.data[x].name;
                      }
                    }
                  }
                }
                console.log(alertsTAS);
                this.alertsTAS = alertsTAS;
              })
              .catch((error) => {
                this.errStore.setError(true);
                this.errStore.setMessage("SDMA ERROR");
                console.log(error);
              });

            // this.alertTAS = alertsTAS;
          })
          .catch((error) => {
            this.errStore.setError(true);
            this.errStore.setMessage("SDMA ERROR");
            console.log(error);
          });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setIsItemDeleting = (value) => {
    this.isItemDeleting = value;
  };

  // drones variables

  setEditingDrone = (value) => {
    this.EditingDrone = value;
  };

  setIsDroneEditing = (value) => {
    this.isDroneEditing = value;
  };

  setIsCreateDroneModalOpen = (value) => {
    this.isCreateDroneModalOpen = value;
  };
  setIsDroneCreating = (value) => {
    this.isDroneCreating = value;
  };
  setDroneCreated = (value) => {
    this.droneCreated = value;
  };

  DroneFields = [
    {
      name: ["id"],
      value: "",
    },
    {
      name: ["name"],
      value: "",
    },
    {
      name: ["status"],
      value: "",
    },
  ];

  ResetDroneFields = () => {
    this.DroneFields = [
      {
        name: ["id"],
        value: "",
      },
      {
        name: ["name"],
        value: "",
      },
      {
        name: ["status"],
        value: "",
      },
    ];
  };

  setDroneFields = (record) => {
    this.DroneFields = [
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["name"],
        value: record.name,
      },
      {
        name: ["title"],
        value: record.title,
      },
    ];
  };

  setIsKafkaConnected = (value) => {
    this.isKafkaConnected = value;
  };

  CreateMission = (values) => {
    this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.AlertsProjectId = data.data.id;
        // return data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  CreateMissionTAS = (values) => {
    this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.AlertsTASProjectId = data.data.id;
        // return data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  CreateMissionSessions = (values) => {
    this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.SessionsProjectId = data.data.id;
        // return data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetProjectIdOfAlerts = () => {
    this.errStore.setLoading(true);
    return GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data);
        let projects = data.data;
        let foundMission = projects.find((project) => {
          return project.name === "AlertsDataBase";
        });
        console.log(foundMission);

        if (!foundMission) {
          this.CreateMission({
            name: "AlertsDataBase",
            description: "AlertsDataBase",
            status: "Enabled",
          });
        } else {
          this.AlertsProjectId = foundMission.id;
          // console.log(this.UsersProjectId);
          // this.GetAllUsers();
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetProjectIdOfAlertsTAS = () => {
    this.errStore.setLoading(true);
    return GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data);
        let projects = data.data;
        let foundMission = projects.find((project) => {
          return project.name === "AlertsTASDataBase";
        });
        console.log(foundMission);

        if (!foundMission) {
          this.CreateMissionTAS({
            name: "AlertsTASDataBase",
            description: "AlertsTASDataBase",
            status: "Enabled",
          });
        } else {
          this.AlertsTASProjectId = foundMission.id;
          // console.log(this.UsersProjectId);
          // this.GetAllUsers();
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetProjectIdOfSessions = () => {
    this.errStore.setLoading(true);
    return GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data);
        let projects = data.data;
        let foundMission = projects.find((project) => {
          return project.name === "SessionsDataBase";
        });

        if (!foundMission) {
          this.CreateMissionSessions({
            name: "SessionsDataBase",
            description: "SessionsDataBase",
            status: "Enabled",
          });
        } else {
          this.SessionsProjectId = foundMission.id;
          // console.log(this.UsersProjectId);
          // this.GetAllUsers();
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Artefacts
  GetArtefacts = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefacts = data.data;
        this.transaction = data.data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetFieldArtefactsByOperation = (pId, oId) => {
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${pId}/artefacts?type=chemical:detected&type=UGV:Status&type=UGV:status&type=tas:result:air&type=tas:result:water&type=chemical:detected&type=WaterSensor:Data`
    )
      .then((data) => {
        this.errStore.setMessage("");
        // this.artefacts = data.data;
        console.log(data.data);

        //new part start

        let newData = [];

        for (let i = 0; i < data.data.length; i++) {
          console.log("@3");
          let obj = "";

          if (
            data.data[i].tags &&
            data.data[i].tags.length > 0 &&
            (data.data[i].tags.includes("operationId:" + oId) ||
              data.data[i].tags.includes("t:operationId:" + oId))
          ) {
            obj = data.data[i];
          }

          // if (
          //   data.data[i].raw &&
          //   JSON.parse(data.data[i].raw).operationId &&
          //   JSON.parse(data.data[i].raw).operationId === id
          // ) {
          //   obj = data.data[i];
          // }
          if (data.data[i].raw) {
            console.log(data.data[i].raw);
            try {
              const parsedRaw = JSON.parse(data.data[i].raw);
              if (parsedRaw.operationId === oId) {
                obj = data.data[i];
              }
            } catch (error) {
              console.error(
                "Failed to parse JSON:",
                error,
                "Raw data:",
                data.data[i].raw
              );
            }
          }

          if (
            data.data[i].attributes &&
            data.data[i].attributes.operationId &&
            data.data[i].attributes.operationId[0] === oId
          ) {
            obj = data.data[i];
          }
          if (obj) {
            newData.push(obj);
          }
        }
        console.log(newData);
        data.data = newData;

        //new part finish

        function containsSessionString(arr) {
          return arr.some((str) => str.toLowerCase().includes("session"));
        }

        function groupBySessionId(objects) {
          // Create a map to store groups by sessionId
          const groups = {};

          // Iterate over each object
          objects.forEach((obj) => {
            // Find the sessionId in the tags
            console.log("333pp");
            if (
              obj.tags &&
              obj.tags.length > 0 &&
              containsSessionString(obj.tags)
            ) {
              console.log("4443pp");
              const sessionIdTag = obj.tags.find((tag) =>
                tag.includes("sessionId:")
              );
              if (sessionIdTag) {
                const sessionId = sessionIdTag.split("onId:")[1];

                // If the sessionId doesn't exist in the map, create a new group
                if (!groups[sessionId]) {
                  groups[sessionId] = {
                    sessionId: sessionId,
                    arrayOfObjects: [],
                  };
                }

                // Add the object to the corresponding group
                groups[sessionId].arrayOfObjects.push(obj);
              }
            } else {
              console.log("ionpp");
              // const sessionId = JSON.parse(obj.raw).sessionId;
              let session = "";
              if (
                JSON.parse(obj.raw).content &&
                JSON.parse(obj.raw).content.sessionId
              ) {
                session = JSON.parse(obj.raw).content.sessionId;
              }
              if (JSON.parse(obj.raw).sessionId) {
                session = JSON.parse(obj.raw).sessionId;
              }
              const sessionId = session;
              console.log(JSON.parse(obj.raw));
              // If the sessionId doesn't exist in the map, create a new group
              if (!groups[sessionId]) {
                groups[sessionId] = {
                  sessionId: sessionId,
                  arrayOfObjects: [],
                };
              }

              // Add the object to the corresponding group
              groups[sessionId].arrayOfObjects.push(obj);
            }
          });

          // Convert the map into an array of grouped objects
          return Object.values(groups);
        }

        // Example usage:
        const objects = data.data;
        console.log(objects);
        const groupedObjects = groupBySessionId(objects);
        console.log(groupedObjects);

        // { text: "realTime", value: "realtime" },
        let newFieldOperationSessions = [];
        newFieldOperationSessions.push({ text: "realTime", value: "realtime" });
        for (let i = 0; i < groupedObjects.length; i++) {
          newFieldOperationSessions.push({
            text: "session - " + (i + 1),
            value: groupedObjects[i].sessionId,
          });
        }
        this.currentFieldOperationSessions = newFieldOperationSessions;
        this.currentFieldOperationSessionsData = groupedObjects;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetUgvstatus = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?type=UGV:Status`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.ugvstatus = data.data.filter((item) => item.type == "UGV:Status");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetWaterSensorDataNew = (pId) => {
    this.errStore.setLoading(true);

    // 66179504d814970001a990cc
    GET_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/artefacts?type=tas:result:water`
    )
      .then((data) => {
        // console.log(data);
        // var temp = data.data.filter(
        //   (item) => JSON.parse(item.raw.WaterSensor) == "WaterSensor"
        // );
        console.log(data);
        // this.errStore.setMessage("");
        // this.errStore.setLoading(false);
        // this.waterSensorData = data.data;
      })
      .catch((error) => {
        // this.errStore.setError(true);
        // this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetWaterSensorData = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?type=WaterSensor:Data`)
      .then((data) => {
        // console.log(data);
        // var temp = data.data.filter(
        //   (item) => JSON.parse(item.raw.WaterSensor) == "WaterSensor"
        // );
        // console.log(temp);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.waterSensorData = data.data;
      })
      .catch((error) => {
        // this.errStore.setError(true);
        // this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetWaterSensorDataTest = (pId) => {
    this.errStore.setLoading(true);
    console.log(pId);
    GET_SDMA(`/api/projects/${pId}/artefacts?type=chemical:detected`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.waterSensorData = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
      });
  };

  GetArtefactByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefact = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateArtefacts = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/artefacts`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  GetEntityByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?tags=t:operationId:${id}`)
      // /entities/${id}
      .then((data) => {
        console.log(data);
        this.errStore.setMessage("");
        this.entity = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateEntities = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/entities`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.entities.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };
}

export default FieldHistoryStore;
