import React, { useEffect, useState } from "react";
import { Col, Row, Divider, Button } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import Icon from "@mdi/react";
import { ReactComponent as TextSearchIcon } from "../../assets/textSearch.svg";
import moment from "moment";
import { FileTextOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import { mdiChevronDoubleLeft, mdiChevronDoubleRight } from "@mdi/js";

const TextTranslationPage = (props) => {
  const { mainStore, webCrawlStore, errorStore } = useMobxStores();

  const [text_words, setTextWords] = useState([]);
  const [resultsTotal, setResultsTotal] = useState("");

  const [pageShown, setPageShown] = useState(-1);
  const [lastPage, setLastPage] = useState(false);

  const [clusterClicked, setClusterClicked] = useState("");

  const [finalPage, setFinalPage] = useState(false);
  const [finalPageItem, setFinalPageItem] = useState(false);

  useEffect(() => {
    if (webCrawlStore.webCrawlPaginationLastPage) {
      setFinalPageItem(webCrawlStore.filteredItems.length);
    } else {
      setFinalPageItem((webCrawlStore.webCrawlPaginationPage - 1) * 4 + 4);
    }
  }, [webCrawlStore.webCrawlPaginationPage, webCrawlStore.filteredItems]);

  useEffect(() => {
    setPageShown(webCrawlStore.webCrawlPaginationPage);
  }, []);

  useEffect(() => {
    setPageShown(webCrawlStore.webCrawlPaginationPage);
  }, [webCrawlStore.webCrawlPaginationPage]);

  useEffect(() => {
    console.log(webCrawlStore.filteredItems);
    setResultsTotal(webCrawlStore.filteredItems.length);
  }, [webCrawlStore.filteredItems]);

  useEffect(() => {
    setLastPage(webCrawlStore.webCrawlPaginationLastPage);
  }, [webCrawlStore.webCrawlPaginationLastPage]);

  const showNextPage = () => {
    webCrawlStore.setNextWebCrawlPaginationPage();
  };

  const showPreviousPage = () => {
    webCrawlStore.setPreviousWebCrawlPaginationPage();
  };

  return (
    <div>
      {webCrawlStore.filteredItems.length > 0 && pageShown !== -1 && (
        <div style={{ width: "100%" }}>
          <Row justify="space-between" align="center">
            <span
              style={{
                marginLeft: "6px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Filtering results: {resultsTotal}
            </span>
            <span>
              <Button
                style={{ width: "100px" }}
                onClick={showPreviousPage}
                disabled={pageShown === 1}
              >
                <span>{/* <UpOutlined /> */}</span>
                <span>
                  {" "}
                  <Icon path={mdiChevronDoubleLeft} size={1} />
                </span>
              </Button>
            </span>

            <span style={{ display: "flex", alignItems: "center" }}>
              {" "}
              {(pageShown - 1) * 4 + 1} - {finalPageItem}{" "}
            </span>
            <span>
              <Button
                style={{ width: "100px", marginRight: "8px" }}
                onClick={showNextPage}
                //  disabled={endIndex >= textTranslation.length}
                disabled={lastPage}
              >
                <span>{/* <DownOutlined /> */}</span>
                <span>
                  {" "}
                  <Icon path={mdiChevronDoubleRight} size={1} />{" "}
                </span>
              </Button>
            </span>
          </Row>
        </div>
      )}
      <Divider
        style={{
          "background-color": "grey",
          marginBottom: "5px",
          marginTop: "3px",
        }}
      ></Divider>
    </div>
  );
};

export default observer(TextTranslationPage);
