import React, { useEffect, useState } from "react";
import { Row, Tooltip, Button } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";

import { useParams } from "react-router-dom";

import { ResponsiveCirclePackingCanvas } from "@nivo/circle-packing";
import WebCrawlStore from "../../stores/WebCrawlStore";

const TextTranslationPage = (props) => {
  const { mainStore, webCrawlStore, errorStore } = useMobxStores();

  const [text_words, setTextWords] = useState([]);

  const [clusterShown, setClusterShown] = useState(false);
  const [itemShown, setItemShown] = useState("");

  const [content, setContent] = useState(null);
  const [textTranslation, setTextTranslation] = useState(
    mainStore.textTranslation
  );
  const [chosenClassification, setChosenClassification] = useState("");
  const [tabContent, setTabContent] = useState("1");
  const [tabTop, setTabTop] = useState("1");
  const [clusterData, setClusterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingNewWeb, setLoadingNewWeb] = useState(false);
  const [clusterClicked, setClusterClicked] = useState("");
  const [clusterClickedColor, setClusterClickedColor] = useState("");
  const [filteredPagesByCluster, setFilteredPagesByCluster] = useState([]);
  const [showClusters, setShowClusters] = useState(false);
  const [finalPage, setFinalPage] = useState(false);

  var _ = require("lodash");
  let params = useParams();

  // Function to handle sorting based on classification

  const handleClusterClick = () => {
    console.log("clusterClicked");
    setShowClusters(true);
    mainStore.Publish({
      topics: ["MA_CLUSTERING_REQUEST"],
      body: JSON.stringify({
        header: {
          topicName: "MA_CLUSTERING_REQUEST",
          sender: "INTRA",
          sentUtc: "2023-01-01T00:00:00+0000",
          source: "DASHBOARD",
          caseId: params.pk,
        },
        body: {
          taskId: params.id,
        },
      }),
    });
  };

  // s

  // Function to update items to show based on selected classification and pagination

  useEffect(() => {
    let temp = [];
    if (mainStore.clustering_module.length > 0) {
      JSON.parse(mainStore.clustering_module[0].content).clusters &&
        JSON.parse(mainStore.clustering_module[0].content).clusters.map(
          (item, index) => {
            temp.push({
              name: item.topics[0].label,
              value: item.documents.length,
            });
          }
        );
    }
    console.log(temp);
    setClusterData(temp);
  }, [mainStore.clustering_module]);
  useEffect(() => {
    setClusterData([]);
  }, [params.id]);

  useEffect(() => {
    // let temp = [];

    // sourceDomainId : "web:page:post002:5"
    if (clusterClicked) {
      if (mainStore.clustering_module.length > 0) {
        if (
          JSON.parse(mainStore.clustering_module[0].content).clusters.length > 0
        ) {
          let clusters = JSON.parse(
            mainStore.clustering_module[0].content
          ).clusters;
          let cluster = clusters.find((cluster) => {
            return cluster.topics[0].label === clusterClicked;
          });
          let sourceDomainIds = cluster.documents.map((document) => {
            return document.document_id;
          });
          console.log(sourceDomainIds);
          setFilteredPagesByCluster(sourceDomainIds);
          webCrawlStore.setFilteredPagesByCluster(sourceDomainIds);
        }
      }
    } else {
      webCrawlStore.setFilteredPagesByCluster([]);
    }
  }, [clusterClicked]);

  return (
    <div
      style={{
        border: "1px solid black",
        marginLeft: "6px",
      }}
    >
      <div>
        <div style={{ backgroundColor: "#CFD8DC", color: "black" }}>
          <Row justify="center" align="center">
            Cluster Analysis
          </Row>
        </div>
        {!showClusters && (
          <div style={{ marginTop: "16px", height: "448px" }}>
            <Row justify="center" align="center">
              <button
                onClick={() => {
                  handleClusterClick();
                }}
              >
                Get Clusters
              </button>
            </Row>{" "}
          </div>
        )}
        {showClusters && (
          <Row
            style={{
              marginTop: "10px",
            }}
          >
            {" "}
            Clusters:
            {clusterData.length > 0 &&
              clusterData.map((item, index) => {
                return (
                  <Tooltip title={item.name}>
                    <span>
                      <div
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "red",
                          width: "30px",
                          height: "22px",
                          color: "black",
                          display: "flex",
                          alignItems: "center", // Center vertically
                          justifyContent: "center",
                        }}
                      >
                        {index + 1}
                      </div>
                    </span>
                  </Tooltip>
                );
              })}
          </Row>
        )}
      </div>
      {showClusters && (
        <div>
          <Row>
            <span
              style={{
                width: "360px",
                height: "440px",
              }}
              // align={"middle"}
              // justify={"center"}
            >
              {clusterData.length > 0 && (
                <ResponsiveCirclePackingCanvas
                  data={{
                    name: "root",
                    children: clusterData,
                  }}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                  id="name"
                  colors={{ scheme: "spectral" }}
                  colorBy="id"
                  childColor={{
                    from: "color",
                    modifiers: [["brighter", 0.4]],
                  }}
                  padding={8}
                  leavesOnly={true}
                  enableLabels={true}
                  label="value"
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 2.4]],
                  }}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.3]],
                  }}
                  animate={true}
                  onClick={handleClusterClick}
                />
              )}
            </span>
            <span>
              {clusterClicked && (
                <div
                  style={{
                    width: "140px",
                    marginTop: "20px",

                    border: "1px solid black",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: clusterClickedColor,
                      borderBottom: "1px solid black",
                    }}
                  >
                    <span
                      style={{
                        marginLeft: "4px",
                      }}
                    >
                      Selected Cluster
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        marginLeft: "4px",
                        fontWeight: "bold",
                      }}
                    >
                      Index:
                    </span>
                    <span></span>
                  </div>
                  <div
                    style={{
                      marginLeft: "4px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Common words:
                    </span>
                    <span style={{}}> {clusterClicked}</span>
                  </div>
                  <div
                    style={{
                      marginLeft: "4px",
                      marginBottom: "4px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      <Button
                        type="default"
                        onClick={() => {
                          setClusterClicked("");
                          // showPage(item, index);
                          // setTabContent("1");
                        }}
                        size="small"
                        style={{
                          backgroundColor: "#CFD8DC",
                          color: "black",
                          marginLeft: "6px",
                          marginTop: "16%",
                        }}
                      >
                        <Row>
                          <span>Deselect</span>
                          <span>
                            <Icon path={mdiClose} size={1} />
                          </span>
                        </Row>
                      </Button>
                    </span>
                  </div>
                </div>
              )}
            </span>
          </Row>
        </div>
      )}
    </div>
  );
};

export default observer(TextTranslationPage);
