import { makeAutoObservable } from "mobx";

class ErrorStore {
  constructor() {
    makeAutoObservable(this);
  }
  status = "success";
  message = "";
  loading = false;
  error_message = "";
  message_placement = "topRight";
  error = false
  info = false
  warning = false

  handlerErrors = (err) => {
    const error =
      (err.response && err.response.data.message
        ? err.response.data.message
        : err.response.data) || err.message;
    this.status = "error";
    this.message = error;
    this.loading = false;
    this.message = err.message;
    if (err.response.data.message === "Invalid Credentials") {
      this.message = "Invalid Credentials";
    }
  };

  handlerCussess = (message) => {
    this.status = "success";
    this.message = message;
    this.loading = false;
  };
  setLoading = (loading) => {
    this.loading = loading;
  };

  setMessage = (message) => {
    this.message = message;
  };

  setStatus = (status) => {
    this.status = status;
  };
  setMessagePlacement = (value) => {
    this.message_placement = value;
  };
  setInfo = (value) => {
    this.info = value;
  };
  setError = (value) => {
    this.error = value;
  };
  setWarning = (value) => {
    this.warning = value;
  };
}

export default ErrorStore;
