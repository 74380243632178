import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import { Card, Row } from "antd";
import EmptyData from "../emptydata/EmptyData";
import Icon from "@mdi/react";
import {
  mdiBatteryOutline,
  mdiBattery10,
  mdiBattery20,
  mdiBattery30,
  mdiBattery40,
  mdiBattery50,
  mdiBattery60,
  mdiBattery70,
  mdiBattery80,
  mdiBattery90,
  mdiBattery,
} from "@mdi/js";

const mapStyle = {
  maxHeight: "440px",
  height: "360px",
  width: "auto",
  borderRadius: "15px",
  backgroundColor: "red",
  marginTop: "40px",
};

const customMarker = new L.icon({
  iconUrl: "../../../../../blue-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const UGVMap = () => {
  const { mainStore } = useMobxStores();

  return (
    <div id="map">
      {mainStore.ugv_real_time_data.gpsCoords ? (
        // {mainStore.ugv_real_time_data != "" ? (
        <MapContainer
          zoom={3}
          minZoom={1}
          scrollWheelZoom={true}
          style={mapStyle}
          center={[
            mainStore.ugv_real_time_data.gpsCoords.latitude,
            mainStore.ugv_real_time_data.gpsCoords.longitude,
          ]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={[
              mainStore.ugv_real_time_data.gpsCoords.latitude,
              mainStore.ugv_real_time_data.gpsCoords.longitude,
            ]}
            icon={customMarker}
          >
            <Popup maxWidth="auto" maxHeight="auto" style={{ width: "auto" }}>
              <Card
                // title={`Cluster ${item.id} (${item.recipe})`}
                style={{
                  width: "420px",
                  minWidth: "auto",
                  textAlign: "left",
                  height: "100px",
                }}
                // bodyStyle={{
                //   textAlign: "left",

                //   // maxHeight: "60vh",
                // }}
              >
                <div>
                  <Row>
                    <span>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Lat:</span>{" "}
                        {mainStore.ugv_real_time_data.gpsCoords.latitude}{" "}
                      </div>
                    </span>
                    <span style={{ marginLeft: "20px" }}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Long:</span>{" "}
                        {mainStore.ugv_real_time_data.gpsCoords.longitude}{" "}
                      </div>
                    </span>
                    <span style={{ marginLeft: "20px" }}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Alt:</span>{" "}
                        {mainStore.ugv_real_time_data.gpsCoords.altitude}{" "}
                      </div>
                    </span>
                    <span style={{ marginLeft: "20px" }}>
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {mainStore.ugv_real_time_data.battery} %
                        </span>{" "}
                        {mainStore.ugv_real_time_data.battery === 0 && (
                          <Icon path={mdiBatteryOutline} size={1} />
                        )}
                        {mainStore.ugv_real_time_data.battery > 0 &&
                          mainStore.ugv_real_time_data.battery <= 10 && (
                            <Icon path={mdiBattery10} size={1} />
                          )}
                        {mainStore.ugv_real_time_data.battery > 10 &&
                          mainStore.ugv_real_time_data.battery <= 20 && (
                            <Icon path={mdiBattery20} size={1} />
                          )}
                        {mainStore.ugv_real_time_data.battery > 20 &&
                          mainStore.ugv_real_time_data.battery <= 30 && (
                            <Icon path={mdiBattery30} size={1} />
                          )}
                        {mainStore.ugv_real_time_data.battery > 30 &&
                          mainStore.ugv_real_time_data.battery <= 40 && (
                            <Icon path={mdiBattery40} size={1} />
                          )}
                        {mainStore.ugv_real_time_data.battery > 40 &&
                          mainStore.ugv_real_time_data.battery <= 50 && (
                            <Icon path={mdiBattery50} size={1} />
                          )}
                        {mainStore.ugv_real_time_data.battery > 50 &&
                          mainStore.ugv_real_time_data.battery <= 60 && (
                            <Icon path={mdiBattery60} size={1} />
                          )}
                        {mainStore.ugv_real_time_data.battery > 60 &&
                          mainStore.ugv_real_time_data.battery <= 70 && (
                            <Icon path={mdiBattery70} size={1} />
                          )}
                        {mainStore.ugv_real_time_data.battery > 70 &&
                          mainStore.ugv_real_time_data.battery <= 80 && (
                            <Icon path={mdiBattery80} size={1} />
                          )}
                        {mainStore.ugv_real_time_data.battery > 80 &&
                          mainStore.ugv_real_time_data.battery <= 90 && (
                            <Icon path={mdiBattery90} size={1} />
                          )}
                        {mainStore.ugv_real_time_data.battery > 90 &&
                          mainStore.ugv_real_time_data.battery <= 100 && (
                            <Icon path={mdiBattery} size={1} />
                          )}
                      </div>
                    </span>
                  </Row>
                </div>

                {/* {` Confidence: ${item.properties.confidence}`} */}
                <br />

                {/* <a href={item.properties.detailsLink}>Details</a> */}
              </Card>
            </Popup>

            {/* <Popup style={{ width: "max-content" }}>
              <Card
                title={"item.title"}
                style={{
                  width: "auto",
                  minWidth: "300px",
                  textAlign: "left",
                }}
                bodyStyle={{
                  width: "auto",
                  textAlign: "left",
                  overflow: "scroll",
                  maxHeight: "60vh",
                  height: "auto",
                }}
              ></Card>
            </Popup> */}
          </Marker>
        </MapContainer>
      ) : (
        <EmptyData />
      )}
    </div>
  );
};

export default observer(UGVMap);
