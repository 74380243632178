import React, { useEffect } from "react";
import Icon from "@mdi/react";
import { mdiChartBubble } from "@mdi/js";
import { mdiFormatListNumbered } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { Col, Row, Divider, Button, Table, Space, Popover, Tag } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useMobxStores } from "../stores/stores";

var _ = require("lodash");

const MissionsTable = () => {
  const { missionsStore, usersStore } = useMobxStores();

  const navigate = useNavigate();
  useEffect(() => {
    console.log(usersStore.currentUser);
    if (
      usersStore.currentUser &&
      usersStore.currentUser.type &&
      !usersStore.currentUser.type.includes("platform_administrator")
    ) {
      navigate(`/missions`);
    }
    if (
      usersStore.currentUser &&
      usersStore.currentUser.type &&
      usersStore.currentUser.type.includes("platform_administrator")
    ) {
      navigate(`/users`);
    }
  }, [usersStore.currentUser]);
  return (
    <div>
      <div>
        <Row
          style={{
            marginTop: "4px",
            backgroundColor: "#003B73",
            color: "white",
            height: "70px",
            marginBottom: "24px",
          }}
          justify="space-between"
        >
          <span
            style={{
              width: "180px",
            }}
          ></span>
          <span
            style={{
              fontSize: "24px",
              marginTop: "18px",
            }}
          ></span>
        </Row>
      </div>
    </div>
  );
};

export default observer(MissionsTable);
