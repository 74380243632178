import React, { useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";

const customMarker = new L.icon({
  iconUrl: "../../../blue-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const NotificationMap = (props) => {
  const { mainStore } = useMobxStores();

  const [map, setMap] = useState(null);

  return (
    <div id="map">
      {props.lon !== undefined && props.lat !== undefined && (
        <MapContainer
          center={[props.lat, props.lon]}
          zoom={6}
          map={map}
          scrollWheelZoom={false}
          style={{ height: "250px" }}
          whenCreated={setMap}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Marker
            position={[props.lat, props.lon]}
            icon={customMarker}
          ></Marker>
        </MapContainer>
      )}
    </div>
  );
};

export default observer(NotificationMap);
