import Keycloak from "keycloak-js";
import { constants } from "../consts/Variables";

const keycloak = new Keycloak({
  url: constants.AUTH_URL,
  realm: constants.AUTH_REALM,
  clientId: constants.AUTH_CLIENT,
  clientSecret:constants.AUTH_CLIENT_SECRET,
});

export default keycloak;
