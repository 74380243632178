import React, { useState, useEffect } from "react";
import { Col, Row, Divider, Card } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import EmptyData from "../emptydata/EmptyData";

const TAS = (props) => {
  const { mainStore, fieldHistoryStore } = useMobxStores();

  useEffect(() => {}, [props.TAS]);

  return (
    <Row justify="center" align="center">
      <Col span={24} lg={24} xs={24} sm={24}>
        <Divider>{props.text}</Divider>
        <Card style={{ borderradius: "15px", minHeight: "400px" }}>
          {props.TAS !== "" ? (
            <>
              {props.text === "Air sensor data" && (
                <div>
                  <p>
                    Last <span style={{ color: "red" }}>highest threat</span>{" "}
                    assessment analysis result
                  </p>
                  {/* )} */}
                  {/* {props.higher_TAS !== "" && ( */}
                  <ul>
                    <li>
                      <b>ThreatLevel: </b>
                      {fieldHistoryStore.higher_TAS.attributes.threatLevel[0]}
                    </li>
                    <li>
                      <b>Confidence: </b>
                      {fieldHistoryStore.higher_TAS.attributes.confidence[0]}
                    </li>
                    <li>
                      <b>Threat: </b>
                      {JSON.parse(fieldHistoryStore.higher_TAS.raw).threat}
                      {/* {props.higher_TAS.threatLevel != undefined &&
                  props.higher_TAS.threat} */}
                    </li>
                    <li>
                      <b>Altitude: </b>
                      {fieldHistoryStore.higher_TAS.attributes.alt[0]}
                    </li>
                    <li>
                      <b>Lat: </b>
                      {fieldHistoryStore.higher_TAS.attributes.lat[0]}
                    </li>
                    <li>
                      <b>Lon: </b>
                      {fieldHistoryStore.higher_TAS.attributes.long[0]}
                    </li>
                  </ul>
                  {/* )} */}
                </div>
              )}
              {props.text === "Water sensor data" && (
                <div>
                  <p>
                    Last <span style={{ color: "red" }}>highest threat</span>{" "}
                    assessment analysis result
                  </p>
                  {/* )} */}
                  {/* {props.higher_TAS !== "" && ( */}
                  <ul>
                    <li>
                      <b>ThreatLevel: </b>
                      {
                        fieldHistoryStore.higher_TASWater.attributes
                          .threatLevel[0]
                      }
                    </li>
                    <li>
                      <b>Confidence: </b>
                      {
                        fieldHistoryStore.higher_TASWater.attributes
                          .confidence[0]
                      }
                    </li>
                    <li>
                      <b>Threat: </b>
                      {JSON.parse(fieldHistoryStore.higher_TASWater.raw).threat}
                      {/* {props.higher_TAS.threatLevel != undefined &&
                 props.higher_TAS.threat} */}
                    </li>
                    <li>
                      <b>Altitude: </b>
                      {fieldHistoryStore.higher_TASWater.attributes.alt[0]}
                    </li>
                    <li>
                      <b>Lat: </b>
                      {fieldHistoryStore.higher_TASWater.attributes.lat[0]}
                    </li>
                    <li>
                      <b>Lon: </b>
                      {fieldHistoryStore.higher_TASWater.attributes.long[0]}
                    </li>
                  </ul>
                  {/* )} */}
                </div>
              )}
            </>
          ) : (
            <EmptyData />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default observer(TAS);
