import React from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { ExclamationCircleFilled } from "@ant-design/icons";

const EmptyData = (props) => {
  const { mainStore } = useMobxStores();

  return (
    <Row justify="center" align="center">
      <Col span={24} lg={24} xs={24} sm={24}>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <ExclamationCircleFilled
            style={{
              fontSize: 20,
            }}
          />
          <p>Data Not Found</p>
        </div>
      </Col>
    </Row>
  );
};

export default observer(EmptyData);
