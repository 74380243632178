import React, { useState } from "react";
import {
  Col,
  Row,
  Space,
  Button,
  Divider,
  Table,
  Tag,
  Modal,
  Upload,
  message,
} from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import { CSVLink } from "react-csv";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";

const dataSource = [
  {
    key: "1",
    name: "Name",
    description: "description",
    date: "1/1/2001",
    criticality: 5,
  },
  {
    key: "2",
    name: "Name",
    description: "description",
    date: "1/1/2001",
    criticality: 9,
  },
  {
    key: "3",
    name: "Name",
    description: "description",
    date: "1/1/2001",
    criticality: 6,
  },
  {
    key: "4",
    name: "Name",
    description: "description",
    date: "1/1/2001",
    criticality: 3,
  },
];

const Reports = (props) => {
  const { textStore } = useMobxStores();
  const [isModalOpen, setIsModalOpen] = useState(props.openModal);

  const [reportModalData, setReportModalData] = useState(
    dataSource.filter((item) => item.key === props.id)[0] || []
  );
  const [csvData, setData] = useState([]);

  const showModal = (record) => {
    setReportModalData(dataSource.filter((item) => item.key === record.key)[0]);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (info) => {
    if (info.file.status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      responsive: ["lg", "sm", "md", "xs"],
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      responsive: ["lg", "sm", "md", "xs"],
      key: "title",
    },
    {
      title: "Date",
      dataIndex: "date",
      responsive: ["lg", "xl"],
      key: "title",
      render: (text) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="orange">{text}</Tag>
        </p>
      ),
    },
    {
      title: "Criticality",
      dataIndex: "criticality",
      responsive: ["lg", "xl"],
      key: "title",
      render: (value) => (
        <p style={{ color: "#00703c" }}>
          <Tag color={value < 5 ? "green" : value <= 7 ? "orange" : "red"}>
            {value}
          </Tag>
        </p>
      ),
    },
    {
      title: "Actions",
      responsive: ["lg", "md"],
      dataSource: "key",
      key: "action",
      width: "auto",
      render: (record) => (
        <Space>
          <Button
            size="small"
            key={record + record}
            type="primary"
            onClick={() => showModal(record)}
            style={{ width: "auto" }}
          >
            {" "}
            More Info
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Row justify="center" align="center" className="full-viewport">
      <Col span={22}>
        <Divider orientation="left">Reports</Divider>
        <Modal
          title="Report Information"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {reportModalData !== [] ? (
            <>
              <p>
                <b>Name:</b> {reportModalData.name}
              </p>
              <p>
                <b>Description:</b> {reportModalData.description}
              </p>
              <p>
                {" "}
                <b>Date:</b> {reportModalData.date}
              </p>
              <p>
                <b>Criticality:</b>{" "}
                <Tag
                  color={
                    reportModalData.criticality < 5
                      ? "green"
                      : reportModalData.criticality <= 7
                      ? "orange"
                      : "red"
                  }
                >
                  {reportModalData.criticality}
                </Tag>
              </p>
            </>
          ) : (
            "No data"
          )}
        </Modal>
        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey={(record) => record.key}
          bordered={true}
          scroll={{ x: "400px" }}
        />
        <Divider />
        <Space block={true} wrap={true}>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="text"
            onChange={onChange}
          >
            <Button
              style={{
                backgroundColor: "green",
                color: "white",
                width: "300px",
              }}
            >
              <ArrowUpOutlined /> Upload Data
            </Button>
          </Upload>
          <CSVLink data={csvData} filename={"report_file.csv"} target="_blank">
            <Button
              type="primary"
              onClick={() => setData(dataSource)}
              style={{ width: "300px" }}
            >
              <ArrowDownOutlined /> {textStore.text["download_CSV_file"]}
            </Button>
          </CSVLink>
        </Space>
      </Col>
    </Row>
  );
};

export default observer(Reports);
