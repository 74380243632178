import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Divider,
  InputNumber,
} from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;

const CreateIngredient = (props) => {
  const { missionsStore, transactionsStore } = useMobxStores();
  const { mainStore } = useMobxStores();
  const [operation_type, setOperationType] = useState(null);
  const [social_media, setSocialMedia] = useState(null);

  const [create_ingredient_form] = Form.useForm();

  const onCreateIngredient = (fields) => {
    let newUuid = uuidv4();
    let newfields = fields;

    newfields.attributes = {
      operationId: [newUuid],
    };
    newfields.name = fields.title;

    newfields.type = "ingredient";
    newfields.threshold = fields.threshold;
    newfields.attributes = {
      threshold: [fields.threshold],
    };

    console.log(newfields);
    transactionsStore.setIsIngredientModalOpen(false);
    return transactionsStore.AddIngredient(newfields);

    // return
  };

  // const handleThresholdChange = (index) => (e) => {
  //   console.log(e.target.value);
  //   console.log(index);
  //   let value = e.target.value;
  //   let oldValues = [...ingredientsValues];
  //   if (!isNaN(value) && value >= 1) {
  //     oldValues[index].threshold = value;
  //     setIngredientsValues(oldValues);
  //   }
  //   if (value === "") {
  //     oldValues[index].threshold = value;
  //     setIngredientsValues(oldValues);
  //   }
  // };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    transactionsStore.setIsIngredientModalOpen(false);
    create_ingredient_form.resetFields();
  };

  const handleCancel = () => {
    transactionsStore.setIsIngredientModalOpen(false);
    create_ingredient_form.resetFields();
  };

  return (
    <div>
      <Modal
        title="Add an ingredient"
        open={transactionsStore.isIngredientModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"60%"}
        style={{ textAlign: "center", height: "100%" }}
        footer={null}
      >
        <Form
          initialValues={{
            remember: true,
          }}
          name="create_ingredient_form"
          // initialValues={{
          //   remember: true,
          // }}
          onFinish={onCreateIngredient}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          size="large"
          form={create_ingredient_form}
        >
          <Row gutter={[20, 20]}>
            <Col span={4}></Col>
            <Col span={16}>
              {" "}
              <Form.Item
                label="Ingredient Name"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input title",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Please input ingredient name!" />
              </Form.Item>
              <Form.Item
                label="Ingredient Threshold"
                name="threshold"
                rules={[
                  {
                    required: true,
                    message: "Please input threshold",
                    type: "number",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Please input ingredient threshold!"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={4}></Col>
          </Row>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default observer(CreateIngredient);
