import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Tooltip,
  Divider,
} from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;

const CreateRecipe = () => {
  const { transactionsStore, missionsStore } = useMobxStores();

  const [create_recipe_form] = Form.useForm();

  const [ingredientsSelected, setIngredientsSelected] = useState([]);

  const [ingredientsValues, setIngredientsValues] = useState([]);

  const handleAmountChange = (index) => (e) => {
    console.log(e.target.value);
    console.log(index);
    let value = e.target.value;
    let oldValues = [...ingredientsValues];

    if (!isNaN(value) && value >= 1 && value <= 100) {
      oldValues[index].amount = value;
      setIngredientsValues(oldValues);
      // console.log(ingredientsValues);
      //   const newValues = [...values];
      //   newValues[index].threshold = value;
      //   setValues(newValues);
    }
    if (value === "") {
      oldValues[index].amount = value;
      setIngredientsValues(oldValues);
    }
  };

  // const findThreshold = (ingredient) => {
  //   if (ingredient.attributes && ingredient.attributes.threshold) {
  //     return " - Threshold:" + ingredient.attributes.threshold[0];
  //   } else {
  //     return "";
  //   }
  // };

  useEffect(() => {
    if (transactionsStore.Ingredients) {
      let newIngredients = transactionsStore.Ingredients.map((ingredient) => {
        if (ingredient.attributes && ingredient.attributes.threshold) {
          return {
            name: ingredient.name,
            amount: ingredient.amount,
            threshold: ingredient.attributes.threshold[0],
          };
        } else {
          return {
            name: ingredient.name,
            amount: ingredient.amount,
            threshold: "",
          };
        }
      });
      setIngredientsValues(newIngredients);
    }
  }, [transactionsStore.Ingredients]);

  // const [value, setValue] = useState("");

  const onCreateRecipe = (fields) => {
    let newUuid = uuidv4();
    let newfields = fields;
    let filteredIngredients = ingredientsValues.filter((ingredient) => {
      return ingredient.amount;
    });
    console.log(filteredIngredients);
    if (filteredIngredients.length > 0) {
      newfields.attributes = {
        // recipeId: [newUuid],
        ingredients: [JSON.stringify(filteredIngredients)],
        alias: [fields.alias],
      };
      newfields.type = "recipe";
      newfields.domainId = "recipe:" + newUuid;
      // fields.status = "Enabled";

      transactionsStore.AddRecipeToProject(fields);
      transactionsStore.setIsRecipeModalOpen(false);
      create_recipe_form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const setChecked = (e) => {
    console.log(e);
    if (e.target.checked === true) {
      ingredientsSelected.push(e.target.value);
    } else {
      let index = ingredientsSelected.indexOf(e.target.value);

      if (index !== -1) {
        ingredientsSelected.splice(index, 1);
      }

      // ingredientsSelected.push(e.target.value);
    }
    // ingredientsSelected
  };

  const setValue = (e) => {
    console.log(e);
    // console.log(e.target.value);
    // if (e.target.checked === true) {
    //   ingredientsSelected.push(e.target.value);
    // } else {
    //   let index = ingredientsSelected.indexOf(e.target.value);

    //   if (index !== -1) {
    //     ingredientsSelected.splice(index, 1);
    //   }

    //   // ingredientsSelected.push(e.target.value);
    // }
    // ingredientsSelected
  };

  const handleOk = () => {
    transactionsStore.setIsRecipeModalOpen(false);
  };

  const handleCancel = () => {
    transactionsStore.setIsRecipeModalOpen(false);
  };

  const participants = [
    {
      label: "Analyst",
      value: "Analyst",
    },
  ];

  const status = [
    {
      label: "Enabled",
      value: "Enabled",
    },
  ];

  return (
    <div>
      <Modal
        title="Add Recipe"
        open={transactionsStore.isRecipeModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"80%"}
        style={{ textAlign: "center", height: "100%" }}
        footer={null}
      >
        <Form
          name="create_recipe_form"
          initialValues={{
            remember: true,
          }}
          onFinish={onCreateRecipe}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          size="large"
          form={create_recipe_form}
        >
          <Row gutter={[20, 20]}>
            <Col span={12} lg={12} md={12} xs={24} sm={24}>
              {" "}
              <Form.Item
                label="Title"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input title!",
                  },
                ]}
              >
                <Input placeholder="Title" />
              </Form.Item>{" "}
            </Col>
            <Col span={12} lg={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Alias"
                name="alias"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input placeholder="Alias" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={24} lg={24} md={24} xs={24} sm={24}>
              <Form.Item
                label="Ingredients"
                name="ingredients"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please choose ingredients!",
                //   },
                // ]}
              >
                <Row>Please choose ingredients</Row>
                <Row style={{ marginTop: "8px" }}>
                  <div
                    style={{
                      width: "100%",
                      border: "solid #CFD8DC 2px",
                      borderRadius: "4px",
                      overflowY: "auto",
                      height: "200px",
                    }}
                  >
                    {ingredientsValues.length > 0 &&
                      ingredientsValues.map((ingredient, index) => {
                        return (
                          <div>
                            <Row
                              key={index}
                              style={{
                                marginTop: "8px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Col span={12} lg={12} md={12} xs={24} sm={24}>
                                {" "}
                                <Row>
                                  <span
                                    style={{
                                      marginLeft: "6px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    Name:
                                  </span>
                                  <span
                                    style={{
                                      marginRight: "6px",
                                    }}
                                  >
                                    {ingredient.name}
                                  </span>{" "}
                                  <span>{ingredient.threshold}</span>{" "}
                                </Row>{" "}
                              </Col>
                              <Col span={12} lg={12} md={12} xs={24} sm={24}>
                                {" "}
                                <Row>
                                  <span
                                    style={{
                                      marginLeft: "6px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    Amount:
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: "6px",
                                      marginTop: "2px",
                                    }}
                                  >
                                    <div>
                                      <Input
                                        placeholder="Integer from 1 to 100"
                                        maxLength={16}
                                        suffix="%"
                                        style={{
                                          width: 200,
                                          marginTop: "-16px",
                                        }}
                                        value={ingredient.amount}
                                        onChange={handleAmountChange(index)}
                                      />
                                    </div>
                                  </span>
                                </Row>{" "}
                              </Col>
                            </Row>
                            <Divider
                              style={{
                                marginTop: "-2px",
                                marginBottom: "4px",
                              }}
                            ></Divider>
                          </div>
                          // <span>
                          //   <Checkbox
                          //     onChange={setChecked}
                          //     value={ingredient.title}
                          //     // checked={webCrawlStore.withTransaltionChecked}
                          //   >
                          //     {ingredient.title}
                          //   </Checkbox>
                          // </span>
                        );
                      })}
                  </div>
                </Row>
                {/* <Input.TextArea placeholder="Ingredients" /> */}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default observer(CreateRecipe);
