import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Card, Select, Spin, Button } from "antd";
import PieChartComponent from "../../components/web-crawl/PieChart";
import ClusterGraph from "../../components/web-crawl/ClusterGraph";
import PagesList from "../../components/web-crawl/PagesList";
import SocialNet from "../../components/socilaNetwork/SocialNteworkNew";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import Loader from "../../components/loader/Loader";
import EmptyData from "../../components/emptydata/EmptyData";
import { mdiTranslate, mdiGraph, mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";
import { ReactComponent as TextSearchIcon } from "../../assets/textSearch.svg";
import moment from "moment";
import { FileTextOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import PageReportView from "../../components/web-crawl/PageReportView";
import SocialMedia from "../../components/web-crawl/SocialMedia";
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";

const { TabPane } = Tabs;

const { Option } = Select;

const text_style = {
  backgroundColor: "white",
  color: "black",
  padding: "1%",
  borderRadius: "0px",
  fontSize: "18px",
};

const TextTranslationPage = (props) => {
  const { mainStore, webCrawlStore, webSocialMediaStore, errorStore } =
    useMobxStores();

  const handlePrint = useReactToPrint({
    content: () => componentRefWebCrawl.current,
  });
  const componentRefWebCrawl = useRef();

  const [text_words, setTextWords] = useState([]);

  const [pageShown, setPageShown] = useState(-1);
  const [itemShown, setItemShown] = useState("");

  const [content, setContent] = useState(null);
  // const [textTranslation, setTextTranslation] = useState(
  //   mainStore.textTranslation
  // );

  const [receivedItems, setReceivedItems] = useState(
    webCrawlStore.receivedItems
  );
  const [receivedSocialMediaItems, setReceivedSocialMediaItems] = useState(
    webSocialMediaStore.receivedItems
  );
  const [chosenClassification, setChosenClassification] = useState("");
  const [tabContent, setTabContent] = useState("1");
  const [tabTop, setTabTop] = useState("1");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingNewWeb, setLoadingNewWeb] = useState(false);
  const [clusterClicked, setClusterClicked] = useState("");
  const [filteredPagesByCluster, setFilteredPagesByCluster] = useState([]);
  const [pageView, setPageView] = useState("list");
  const [isSocialMedia, setIsSocialMedia] = useState(false);

  // const [tab, setTab] = useState("1");

  const [value, setValue] = useState(1);
  const changeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  var _ = require("lodash");
  let params = useParams();

  // pagination

  const [selectedClassification, setSelectedClassification] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setPageView(webCrawlStore.webCrawlView);
  }, [webCrawlStore.webCrawlView]);

  useEffect(() => {
    console.log("kokokoko");
    console.log(webCrawlStore.receivedItems);
    setReceivedItems(webCrawlStore.receivedItems);
  }, [webCrawlStore.receivedItems]);

  useEffect(() => {
    setReceivedSocialMediaItems(webSocialMediaStore.receivedItems);
  }, [webSocialMediaStore.receivedItems]);
  useEffect(() => {
    setIsSocialMedia(webCrawlStore.webCrawlTypeIsSocialMedial);
  }, [webCrawlStore.webCrawlTypeIsSocialMedial]);

  const newTab = (key) => {
    if (key === "1") {
      console.log(1);
    }
    if (key === "2") {
      console.log("SNAAA");
      mainStore.projectIdForSN = params.pk;
      mainStore.snDomainId = "";
      mainStore.Publish({
        topics: ["MA_SNA_REQUEST"],
        body: JSON.stringify({
          header: {
            topicName: "MA_SNA_REQUEST",
            sender: "INTRA",
            sentUtc: moment().format("yyyy-MM-dd'T'HH:mm:ss'Z'"),
            source: "INTRA",
            caseId: params.pk,
          },
          body: {
            taskId: params.id, //enum: ["ABCD_SYNTHETIC","PHONE_DATASET"]
          },
        }),
      });
    }

    setTabTop(key);
  };

  const newContent = (key) => {
    if (key === "3") {
      setTextWords(
        itemShown.attributes.translation
          ? itemShown.attributes.translation[0].split(" ")
          : itemShown.content.split(" ")
      );
      mainStore.setTextWords(
        itemShown.attributes.translation
          ? itemShown.attributes.translation[0].split(" ")
          : itemShown.content.split(" ")
      );
      console.log(itemShown.domainId);
      mainStore.GetAnalyse(params.pk, itemShown.domainId);
    }
    if (key === "4") {
      // mainStore.GetLinksSourceDomainId(
      //   params.pk,
      //   _.cloneDeep(textTranslation)[pageShown].domainId
      // );
      mainStore.GetLinksSourceDomainId(params.pk, itemShown.domainId);
    }
    if (key === "5") {
      mainStore.projectIdForSN = params.pk;
      mainStore.Publish({
        topics: ["MA_SNA_REQUEST"],
        body: JSON.stringify({
          header: {
            topicName: "MA_SNA_REQUEST",
            sender: "INTRA",
            sentUtc: moment().format("yyyy-MM-dd'T'HH:mm:ss'Z'"),
            source: "INTRA",
            caseId: params.pk,
          },
          body: {
            taskId: params.id, //enum: ["ABCD_SYNTHETIC","PHONE_DATASET"]
          },
        }),
      });
    }
    if (key === "6") {
      mainStore.projectIdForClustering = params.pk;
      mainStore.Publish({
        topics: ["MA_CLUSTERING_REQUEST"],
        body: JSON.stringify({
          header: {
            topicName: "MA_CLUSTERING_REQUEST",
            sender: "INTRA",
            sentUtc: "2023-01-01T00:00:00+0000",
            source: "DASHBOARD",
            caseId: params.pk,
          },
          body: {
            taskId: params.id,
          },
        }),
      });
    }

    setTabContent(key);
  };

  const showPage = (item, index) => {
    console.log(item);
    setItemShown(item);
    setPageShown(index);
  };
  useEffect(() => {
    newTab("1");
    setLoadingNewWeb(true); // Show spinner
    setTimeout(() => {
      setLoadingNewWeb(false); // Hide spinner after 1 second
    }, 1000);

    webCrawlStore.GetAllPagesFromWebCrawl(params.pk, params.id);
    webCrawlStore.GetclassificationModule(params.pk);
    // mainStore.GetclassificationModule(params.pk);
    setCurrentPage(1);

    mainStore.currentOperationId = params.id;
    mainStore.GetEntityByID(params.id, params.pk);
    mainStore.projectIdForClustering = params.pk;
    mainStore.Publish({
      topics: ["MA_CLUSTERING_REQUEST"],
      body: JSON.stringify({
        header: {
          topicName: "MA_CLUSTERING_REQUEST",
          sender: "INTRA",
          sentUtc: "2023-01-01T00:00:00+0000",
          source: "DASHBOARD",
          caseId: params.pk,
        },
        body: {
          taskId: params.id,
        },
      }),
    });
    // setSelectedClassification("");
  }, []);

  useEffect(() => {
    newTab("1");
    setLoadingNewWeb(true); // Show spinner
    setTimeout(() => {
      setLoadingNewWeb(false); // Hide spinner after 1 second
    }, 1000);

    webCrawlStore.GetAllPagesFromWebCrawl(params.pk, params.id);
    mainStore.GetclassificationModule(params.pk);
    setCurrentPage(1);

    mainStore.currentOperationId = params.id;
    mainStore.GetEntityByID(params.id, params.pk);
    mainStore.projectIdForClustering = params.pk;
    // mainStore.Publish({
    //   topics: ["MA_CLUSTERING_REQUEST"],
    //   body: JSON.stringify({
    //     header: {
    //       topicName: "MA_CLUSTERING_REQUEST",
    //       sender: "INTRA",
    //       sentUtc: "2023-01-01T00:00:00+0000",
    //       source: "DASHBOARD",
    //       caseId: params.pk,
    //     },
    //     body: {
    //       taskId: params.id,
    //     },
    //   }),
    // });
    // setSelectedClassification("");
  }, [params.id]);

  const renderedText = mainStore.textWords.map((word, index) => {
    const matchedItem = mainStore.analyse_entities.find(
      (item) => item.text == word
    );
    if (matchedItem && matchedItem.color === "red") {
      return (
        <span
          key={index}
          style={{
            backgroundColor: matchedItem.color,
            marginLeft: "3px",
            marginRight: "3px",
            marginTop: "3px",
            marginBottom: "3px",
            color: "white",
          }}
        >
          {word}
        </span>
      );
    }
    if (matchedItem && matchedItem.color === "#00008B") {
      return (
        <span
          key={index}
          style={{
            backgroundColor: matchedItem.color,
            marginLeft: "3px",
            marginRight: "3px",
            marginTop: "3px",
            marginBottom: "3px",
            color: "white",
          }}
        >
          {word}
        </span>
      );
    }
    if (
      (matchedItem && matchedItem.color !== "red") ||
      (matchedItem && matchedItem.color !== "#00008B")
    ) {
      return (
        <span
          key={index}
          style={{
            backgroundColor: matchedItem.color,
            marginLeft: "3px",
            marginRight: "3px",
            marginTop: "3px",
            marginBottom: "3px",
            color: "black",
          }}
        >
          {word}
        </span>
      );
    }

    return (
      <span
        key={index}
        style={{
          marginLeft: "3px",
          marginRight: "3px",
        }}
      >
        {word}
      </span>
    );
  });

  const onSort = (item) => {
    setChosenClassification(item);
    console.log(item);
  };

  return (
    <Row
      justify="center"
      align="center"
      style={{ width: "100%", marginTop: "24px", height: "94%" }}
    >
      <Col
        style={{ height: "94%" }}
        span={24}
        md={24}
        lg={24}
        xs={24}
        sm={24}
        xxl={24}
      >
        {loadingNewWeb && ( // Display spinner if loading state is true
          <div style={{ width: "74vw", height: "380px" }}>
            <Row justify="center">
              <span>
                <Spin style={{ marginTop: "150px" }} />
              </span>
            </Row>
          </div>
        )}
        {!loadingNewWeb && ( // Display spinner if loading state is true
          <div ref={componentRefWebCrawl}>
            <Card style={{ width: "100%", marginTop: "24px", height: "94%" }}>
              <Row justify="end">
                <span>
                  <Button
                    type="default" // Set the button type to "default" to get a white button
                    onClick={handlePrint} // Handle the click event
                    size="medium"
                    style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
                  >
                    <div align="center">
                      <span>
                        <PrinterOutlined />
                      </span>
                    </div>
                  </Button>
                </span>
              </Row>
              <Tabs defaultActiveKey="1" tabPosition="top" onChange={newTab}>
                <TabPane
                  tab={
                    <span>
                      <TextSearchIcon
                        style={{ width: "22px", height: "22px" }}
                      />
                      Page analysis
                    </span>
                  }
                  key="1"
                ></TabPane>

                <TabPane
                  tab={
                    <span>
                      <Icon path={mdiGraph} size={1} />
                      Social Network
                    </span>
                  }
                  key="2"
                ></TabPane>
              </Tabs>
              {tabTop === "1" && (
                <div>
                  {loading && ( // Display spinner if loading state is true
                    <div style={{ width: "74vw", height: "380px" }}>
                      <Row justify="center">
                        <span>
                          <Spin style={{ marginTop: "150px" }} />
                        </span>
                      </Row>
                    </div>
                  )}

                  {receivedItems.length > 0 &&
                    pageView === "list" &&
                    !isSocialMedia &&
                    !loading && (
                      <div>
                        <Row>
                          <Col span={12}>
                            {" "}
                            <PagesList></PagesList>
                          </Col>
                          <Col span={12}>
                            <ClusterGraph />{" "}
                          </Col>
                        </Row>
                      </div>
                    )}
                  {receivedSocialMediaItems.length > 0 &&
                    pageView === "list" &&
                    isSocialMedia &&
                    !loading && (
                      <div>
                        {/* <Row>
                        <Col span={24}>
                          <div>
                            <SocialMedia></SocialMedia>
                          </div>
                        </Col>
                      </Row> */}
                        <Row
                        // style={{ height: "auto" }}
                        >
                          <Col span={12}>
                            {" "}
                            <SocialMedia></SocialMedia>
                          </Col>
                          <Col span={12}>
                            <ClusterGraph />{" "}
                          </Col>
                        </Row>
                      </div>
                    )}

                  {receivedItems.length > 0 && pageView === "report" && (
                    <Card>
                      <PageReportView></PageReportView>
                    </Card>
                  )}
                </div>
              )}
              {tabTop === "2" && (
                <div>
                  <div
                    style={{
                      marginLeft: "40px",
                      width: "52vw",
                      height: "280px",
                      border: "1px  solid black",
                      // overflowY: "auto",
                      marginTop: "-6px",
                      minWidth: "740px",
                    }}
                  >
                    <SocialNet></SocialNet>
                  </div>
                </div>
              )}
            </Card>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default observer(TextTranslationPage);
