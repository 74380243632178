import React from "react";
import { Col, Row, Result } from "antd";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Row justify="center" align="center"  className="full-viewport">
      <Col span={8} lg={8} xs={24} sm={24}>
        <Result
          status={"404"}
          title="This page cannot be visit!"
          extra={[
            <Link to='/' >Back to Dashboard</Link>,
          ]}
        />
      </Col>
    </Row>
  );
};

export default NotFound;
