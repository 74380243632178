import React, { useState, useEffect } from "react";
import { Col, Row, Divider, Card, InputNumber, Form, Button, Spin } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";

const TAS = (props) => {
  const { mainStore } = useMobxStores();
  const [enabled, setEnabled] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [initialData, setInitialData] = useState({
    AirLow: 0,
    AirMedium: 0,
    AirHigh: 0,
    WaterLow: 0,
    WaterMedium: 0,
    WaterHigh: 0,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(mainStore.configData);
    setInitialData(mainStore.configData);
    setEnabled(false);
    setSpinner(false);
  }, [mainStore.configData]);

  useEffect(() => {
    mainStore.getTasConfig();
  }, []);

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const onValuesChange = () => {
    const currentValues = form.getFieldsValue();
    const valuesChanged = Object.keys(initialData).some(
      (key) => initialData[key] !== currentValues[key]
    );
    setEnabled(valuesChanged);
  };

  const onSubmitNewTAsConfig = (fields) => {
    setSpinner(true);
    const newData = {
      tas_thresholds_air: [fields.AirLow, fields.AirMedium, fields.AirHigh],
      tas_thresholds_water: [
        fields.WaterLow,
        fields.WaterMedium,
        fields.WaterHigh,
      ],
    };
    mainStore.setNewTasConfig(newData);
  };

  return (
    <Row justify="center" align="center">
      <Col span={24} lg={24} xs={24} sm={24}>
        {!spinner ? (
          <div>
            {" "}
            <Divider>TAS Config</Divider>
            <Card style={{ borderRadius: "15px", minHeight: "400px" }}>
              <div>
                <Form
                  initialValues={initialData}
                  onFinish={onSubmitNewTAsConfig}
                  onValuesChange={onValuesChange}
                  autoComplete="off"
                  layout="vertical"
                  size="large"
                  form={form}
                >
                  <Row gutter={[20, 20]}>
                    <Col span={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        Air Sensor Thresholds
                      </div>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Low"
                        name="AirLow"
                        rules={[
                          {
                            required: true,
                            message: "Please input threshold",
                            type: "number",
                          },
                        ]}
                      >
                        <InputNumber
                          onKeyPress={handleKeyPress}
                          placeholder="Low"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Medium"
                        name="AirMedium"
                        rules={[
                          {
                            required: true,
                            message: "Please input threshold",
                            type: "number",
                          },
                        ]}
                      >
                        <InputNumber
                          onKeyPress={handleKeyPress}
                          placeholder="Low"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="High"
                        name="AirHigh"
                        rules={[
                          {
                            required: true,
                            message: "Please input threshold",
                            type: "number",
                          },
                        ]}
                      >
                        <InputNumber
                          onKeyPress={handleKeyPress}
                          placeholder="Low"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={[20, 20]}>
                    <Col span={6}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        Water Sensor Thresholds
                      </div>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Low"
                        name="WaterLow"
                        rules={[
                          {
                            required: true,
                            message: "Please input threshold",
                            type: "number",
                          },
                        ]}
                      >
                        <InputNumber
                          onKeyPress={handleKeyPress}
                          placeholder="Low"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Medium"
                        name="WaterMedium"
                        rules={[
                          {
                            required: true,
                            message: "Please input threshold",
                            type: "number",
                          },
                        ]}
                      >
                        <InputNumber
                          onKeyPress={handleKeyPress}
                          placeholder="Low"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="High"
                        name="WaterHigh"
                        rules={[
                          {
                            required: true,
                            message: "Please input threshold",
                            type: "number",
                          },
                        ]}
                      >
                        <InputNumber
                          onKeyPress={handleKeyPress}
                          placeholder="Low"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Form.Item>
                      {/* <Button
                    type="primary"
                    htmlType="submit"
                    size="medium"
                    disabled={!enabled}
                    style={{
                      backgroundColor: enabled ? "#354289" : "grey",
                      color: enabled ? "white" : "black",
                      marginLeft: "6px",
                    }}
                  >
                    <Row align="center">Submit</Row>
                  </Button> */}

                      {enabled ? (
                        <Button
                          type="default" // Set the button type to "default" to get a white button
                          htmlType="submit"
                          size="medium"
                          style={{
                            backgroundColor: "#354289",
                            color: "white",
                            marginLeft: "6px",
                          }} // Set the background color to white and font color to blue
                        >
                          <Row align="center">Submit</Row>
                        </Button>
                      ) : (
                        <Button
                          type="default" // Set the button type to "default" to get a white button
                          size="medium"
                          style={{
                            backgroundColor: "grey",
                            color: "black",
                            marginLeft: "6px",
                          }} // Set the background color to white and font color to blue
                        >
                          <Row align="center">Submit</Row>
                        </Button>
                      )}
                    </Form.Item>
                  </Row>
                </Form>
              </div>
            </Card>
          </div>
        ) : (
          <div>
            {" "}
            <div style={{ width: "100%", minHeight: "400px" }}>
              <Row justify="center">
                <span>
                  <Spin style={{ marginTop: "200px" }} />
                </span>
              </Row>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default observer(TAS);
