import React, { useEffect } from "react";
import { Tag, Card, Col, Divider, Row } from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";

const Audit = (props) => {
  const { mainStore } = useMobxStores();

  useEffect(() => {
    mainStore.GetAuditItems();
  });

  return (
    <Row justify="center" align="center" className="full-viewport">
      <Col span={24} lg={24} xs={24} sm={24}>
        <Divider orientation="left"> Audit Items</Divider>
        {mainStore.audit_items.map((item, index) => {
          return (
            <Card key={index}>
              <Row gutter={[20, 20]}>
                <Col>
                  Build <br /> <Tag color="green">{item.build}</Tag>
                </Col>
                <Col>
                  Request Time <br />{" "}
                  <Tag color="green">{item.requestTime}</Tag>
                </Col>
                <Col>
                  Type <br /> <Tag color="green">{item.type}</Tag>
                </Col>
              </Row>
            </Card>
          );
        })}
        {mainStore.audit_items.length == 0 && "No data available"}
      </Col>
    </Row>
  );
};

export default observer(Audit);
