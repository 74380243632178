import { makeAutoObservable } from "mobx";

class MainStore {
  constructor({ errStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
  }

  english = {
    username: "Username",
    password: "Password",
    download_CSV_file:'Download CSV file',
    login: "Login",
    not_registered: "Don't have an account? Sign-up now!",
    terms_of_use: "Terms of use",
    privacy_policy: "Privacy policy",
    wrong_credentials: "Sign-in failed. Wrong username or password",
    yes: "Yes",
    cancel: "Cancel",
    automatic: "Automatic",
    no_data: "No data",
    map: "Map",
    measurements: "Measurements",
    days: "days",
    day: "day",
    back: "Back",
    last_updated: "Last updated",
    about: "About",
    version: "version",
    zoom_word: "Font size",
    register: "Register",
    create_account: "Create an account",
    repeat_password: "Repeat password",
    email: "Email",
    firstname: "First Name",
    lastname: "Last Name",
    phone: "Phone",
    delete: "Delete",
    create: "Create",
    title: "Title",
    title_native_language: "Title in native language",
    editor: "Editor",
    coordinator: "Coordinator",
    partners: "Partners",
    keyword_category: "Keyword category",
    starting_date: "Starting date",
    ending_date: "Ending date",
    status: "Status",
    funding_source: "Funding source",
    total_budget: "Total budget",
    geographical_location: "Geographical location",
    final_report: "Final report",
    description_in_native_language: "Description in native language",
    description: "Description",
    summary: "Summary",
    audiovisual_material: "Audiovisual material",
    website: "Website",
    practice_abstract_in_native_language:
      "Practice abstract in native language",
    practice_abstract: "Practice abstract",
    context_description: "Context description",
    constring_description: "Constring description",
    additional_information: "Additional information",
    additional_comments: "Additional comments",
    partner: "Partner",
    coordinators: "Coordinators",
    projects: "Projects",
    edit: "Edit",
    name: "Name",
    more_info: "More information",
    GR: " GR",
    EN: " EN",
    project: "Project",
    pk: "pk",
    uuid: "uuid",
    lon: "lon",
    lat: "lat",
    account: "Account",
    logout: "Logout",
    code: "Project Code",
    overview: "Overview",
    please_insert_password: "Please insert password.",
    please_insert_username: "Please insert username.",
    map_display: "Map Display",
    invalid_credentials: "Invalid Credentials",
    notification: "Notification",
    login_message: "Login successed",
    site_title: "Odysseus",
    site_subtitle_1: "Ministry of Rural Development",
    site_subtitle_2: "& Food",
    optional_elements: "Optional Elements",
    please_insert: "Please insert",
    recommended_elements: "Ρecommended Εlements",
    more_options: "More Options",
    project_ou: "Project",
    obligatory_elements: "Obligatory Elements",
    reports: "Reports",
  };

  isEnglish = true;
  isGreek = false;
  text = this.english;

  SetEnglish = () => {
    this.isEnglish = true;
    this.isGreek = false;
    this.text = this.english;
  };

  SetGreek = () => {
    this.isGreek = true;
    this.isEnglish = false;
    this.text = this.greek;
  };

  setStatus = (value) => {
    this.status = value;
  };
}

export default MainStore;
