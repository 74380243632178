import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import _ from "lodash";
import { mdiConsoleNetwork } from "@mdi/js";
import { Row, Col, Divider, Space, Tag } from "antd";

const PieChartComponent = (props) => {
  const { mainStore } = useMobxStores();

  useEffect(() => {
    mainStore.setPieData();
  }, []);

  console.log("props.pieData");
  console.log(props.pieData);

  let dataArray = props.pieData;
  // Sample data for the pie chart

  let series = dataArray.map((item) => {
    return item.value;
  });
  let labels = dataArray.map((item) => {
    return item.name.slice(3) + " - " + item.value;
  });
  let colors = dataArray.map((item) => {
    return item.color;
  });

  let options = {
    labels: labels,
    colors: colors,
    legend: {
      position: "left",
    },
  };

  return (
    <Row>
      <Divider orientation="left">
        Named Entity Distribution in Iban Text
      </Divider>
      <Col span={16}>
        <div className="mixed-chart">
          <ReactApexChart
            options={options}
            series={series}
            type="pie"
            width="400"
          />
        </div>
      </Col>
    </Row>
  );
};

export default observer(PieChartComponent);
