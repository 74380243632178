import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiChartBubble } from "@mdi/js";
import { mdiFormatListNumbered } from "@mdi/js";

import {
  Col,
  Row,
  Divider,
  Button,
  Table,
  Space,
  Popover,
  Tag,
  Modal,
  Form,
  Input,
  message,
  Upload,
} from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useMobxStores } from "../stores/stores";
import CreateMission from "../components/missions/CreateMission";
import { getOverridedColumns } from "../utils/tableLoader";
import { useNavigate } from "react-router-dom";
import { dateForTables } from "../utils/formatDate";

var _ = require("lodash");

const MissionsTable = ({ updateParentMessage }) => {
  const { missionsStore, mainStore, usersStore, transactionsStore } =
    useMobxStores();
  const navigate = useNavigate();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [import_form] = Form.useForm();
  const propA = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const exportRecipes = () => {
    transactionsStore.downloadRecipesANDIngredients();
  };

  const importRecipes = () => {
    // transactionsStore.setOpenModalRecipes(true);
    console.log("jij");
    updateParentMessage();
  };

  const handleFileChange = (info) => {
    if (info.file.status !== "uploading") {
      const file = info.file.originFileObj; // Access the actual file object
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const json = JSON.parse(e.target.result);
            mainStore.importData = json; // Assuming 'importData' is the observable in 'mainStore'
            message.success(`File imported successfully`);
          } catch (error) {
            message.error(`Failed to parse JSON: ${error.message}`);
          }
        };
        reader.readAsText(file); // Read the file as text
      } else {
        message.error(`No file selected`);
      }
    }
  };

  useEffect(() => {
    missionsStore.GetMissions();
    console.log("missionsStore.missions");
  }, []);

  useEffect(() => {
    if (
      usersStore.currentUser &&
      usersStore.currentUser.type &&
      usersStore.currentUser.type === "platform_administrator"
    ) {
      navigate(`/users`);
    }
  }, [usersStore.currentUser]);

  const onAddOperation = (record) => {
    missionsStore.setIsOperationModalOpen(true);
    missionsStore.setPid(record.id);
  };

  const onRowClicked = (e, record) => {
    const redirectDivs = ["TD", "P"];
    redirectDivs.includes(e.target.tagName) &&
      navigate(
        //    `/mission/662a4a3ed814970001a99c3a`
        `/mission/${record.id}`
      );
    missionsStore.setPickedMission(record);
  };

  const columns = [
    {
      title: "Title",
      responsive: ["lg", "sm", "md", "xs"],
      dataIndex: "name",
      key: "name",
      width: "300px",
    },
    {
      title: "Description",
      responsive: ["lg", "sm", "md", "xs"],
      key: "description",
      dataIndex: "description",
      render: (record) =>
        record.includes("?creator_name=") ? (
          <span>{record.split("?creator_name=")[0]} </span>
        ) : (
          <span>{record} </span>
        ),
    },
    {
      title: "Created at",
      responsive: ["lg", "sm", "md", "xs"],
      key: "created",
      // dataIndex: "created",
      // dateForTables
      render: (record) => <span>{dateForTables(record.created)}</span>,
    },
    {
      title: "Created by",
      responsive: ["lg", "sm", "md", "xs"],
      key: "creator",
      dataIndex: "description",
      render: (record) =>
        record.includes("?creator_name=") ? (
          <span>{record.split("?creator_name=")[1]} </span>
        ) : (
          <span> Unknown </span>
        ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="default" // Set the button type to "default" to get a white button
            onClick={() => missionsStore.DeleteMission(record.id)}
            size="medium"
            style={{
              backgroundColor: "red",
              color: "white",
              marginLeft: "6px",
            }} // Set the background color to white and font color to blue
          >
            <Row align="center">
              <span>
                <DeleteFilled
                  style={{ fontSize: "16px", marginRight: "4px" }}
                />
              </span>
              <span>Delete </span>{" "}
            </Row>
          </Button>
          <Button
            type="default" // Set the button type to "default" to get a white button
            size="medium"
            style={{
              backgroundColor: "blue",
              color: "white",
              marginLeft: "6px",
            }} // Set the background color to white and font color to blue
          >
            <Row align="center">
              <span>
                <Icon
                  style={{ color: "white" }}
                  path={mdiFormatListNumbered}
                  size={1}
                />
              </span>
              <span>Edit</span>{" "}
            </Row>
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Modal
        title="Import data"
        open={isImportModalOpen}
        // onOk={handleOk}
        // onCancel={handleCancel}
        width={"80%"}
        style={{ textAlign: "center", height: "100%" }}
        footer={null}
      >
        <Form
          name="import_form"
          initialValues={{
            remember: true,
          }}
          // onFinish={onCreateMission}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          size="large"
          form={import_form}
        >
          <Form.Item
            label="Title"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input title!",
              },
            ]}
          >
            <Input placeholder="Title" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input description!",
              },
            ]}
          >
            <Input.TextArea placeholder="Description" />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div>
        <Row
          style={{
            marginTop: "4px",
            backgroundColor: "#003B73",
            color: "white",
            height: "70px",
            marginBottom: "24px",
          }}
          justify="space-between"
        >
          <span
            style={{
              width: "180px",
            }}
          ></span>
          <span
            style={{
              fontSize: "24px",
              marginTop: "18px",
            }}
          >
            Missions
          </span>

          <span style={{ marginTop: "16px", marginRight: "8px" }}>
            <span style={{ marginRight: "4px" }}>
              <Button
                type="default" // Set the button type to "default" to get a white button
                size="medium"
                onClick={() => exportRecipes()}
                style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
              >
                <div align="center">
                  <span>
                    <PlusOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "4px",
                        color: "white",
                      }}
                    />
                  </span>
                  <span>Export Recipes</span>
                </div>
              </Button>
            </span>
            <span style={{ marginRight: "4px" }}>
              <Button
                type="default" // Set the button type to "default" to get a white button
                size="medium"
                onClick={importRecipes}
                style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
              >
                <div align="center">
                  <span>
                    <PlusOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "4px",
                        color: "white",
                      }}
                    />
                  </span>
                  <span>Import Recipes</span>
                </div>
              </Button>
            </span>
            <span style={{ marginRight: "4px" }}>
              <Button
                type="default" // Set the button type to "default" to get a white button
                size="medium"
                onClick={() => missionsStore.setIsMissionModalOpen(true)}
                style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
              >
                <div align="center">
                  <span>
                    <PlusOutlined
                      style={{
                        fontSize: "20px",
                        marginRight: "4px",
                        color: "white",
                      }}
                    />
                  </span>
                  <span>Add Mission</span>
                </div>
              </Button>
            </span>
          </span>
        </Row>
      </div>
      <div>
        <Row justify="center">
          <CreateMission />
          {/* <Divider orientation="left">Missions</Divider> */}
          {missionsStore.missions.length === 0 && <div> </div>}
          {missionsStore.missions.length !== 0 && (
            <Table
              className="custom-table"
              style={{ width: "96vw" }}
              size="small"
              indentSize={12}
              bordered={true}
              scroll={{ x: "400px" }}
              rowClassName="custom-row-hover"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => onRowClicked(e, record),
                };
              }}
              dataSource={
                missionsStore.missions != null
                  ? _.cloneDeep(missionsStore.missions).filter((x) => {
                      return (
                        x.name !== "RecipesANDIngredientsForTransactions6" &&
                        x.name !== "UsersDataBase" &&
                        x.name !== "AlertsDataBase" &&
                        x.name !== "AlertsTASDataBase" &&
                        x.name !== "SessionsDataBase"
                      );
                    })
                  : Array(5).fill({})
              }
              columns={
                missionsStore.missions != null
                  ? columns
                  : getOverridedColumns(columns)
              }
              rowKey={(record) => record.id}
            />
          )}
        </Row>
      </div>
    </div>
  );
};

export default observer(MissionsTable);
