import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Divider,
  Button,
  Typography,
  Space,
  Modal,
  Card,
  Tag,
  Tooltip,
  Image,
  Select,
  Spin,
  Radio,
  Input,
  Checkbox,
} from "antd";

import PageFilter from "./PageFilter";
import PagesItemsShown from "./PagesItemsShown";
import PagesPagination from "./PagesPagination";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import EmptyData from "../../components/emptydata/EmptyData";
import { mdiTranslate, mdiGraph, mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";
import { ReactComponent as TextSearchIcon } from "../../assets/textSearch.svg";
import moment from "moment";
import { FileTextOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";

const TextTranslationPage = (props) => {
  const { mainStore, errorStore } = useMobxStores();

  const [text_words, setTextWords] = useState([]);

  const [pageShown, setPageShown] = useState(-1);
  const [itemShown, setItemShown] = useState("");

  const [content, setContent] = useState(null);
  const [textTranslation, setTextTranslation] = useState(
    mainStore.textTranslation
  );
  const [chosenClassification, setChosenClassification] = useState("");
  const [tabContent, setTabContent] = useState("1");
  const [tabTop, setTabTop] = useState("1");
  const [clusterData, setClusterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingNewWeb, setLoadingNewWeb] = useState(false);
  const [clusterClicked, setClusterClicked] = useState("");
  const [filteredPagesByCluster, setFilteredPagesByCluster] = useState([]);

  const [finalPage, setFinalPage] = useState(false);

  var _ = require("lodash");
  let params = useParams();

  // Function to handle sorting based on classification

  const handleClusterClick = (node, event) => {
    console.log("Clicked on cluster:", node.data.name);
    setClusterClicked(node.data.name);

    console.log(clusterClicked);
    // You can perform any other actions here
  };

  // Function to update items to show based on selected classification and pagination

  // useEffect(() => {
  //   // let temp = [];

  //   // sourceDomainId : "web:page:post002:5"
  //   if (mainStore.clustering_module.length > 0) {
  //     if (
  //       JSON.parse(mainStore.clustering_module[0].content).clusters.length > 0
  //     ) {
  //       let clusters = JSON.parse(
  //         mainStore.clustering_module[0].content
  //       ).clusters;
  //       let cluster = clusters.find((cluster) => {
  //         return cluster.topics[0].label === clusterClicked;
  //       });
  //       let sourceDomainIds = cluster.documents.map((document) => {
  //         return document.document_id;
  //       });
  //       console.log(sourceDomainIds);
  //       setFilteredPagesByCluster(sourceDomainIds);
  //     }
  //   }
  // }, [clusterClicked]);

  return (
    <div
      style={{
        border: "1px black solid",
        overflowY: "auto",
        // minHeight: "380px",
        height: "100%",
        width: "100%",
      }}
    >
      <div style={{ backgroundColor: "#CFD8DC", color: "black" }}>
        <Row justify="center" align="center">
          Crawling Results (Subpages){" "}
        </Row>
      </div>
      <PageFilter></PageFilter>
      <PagesPagination></PagesPagination>
      <PagesItemsShown></PagesItemsShown>
    </div>
  );
};

export default observer(TextTranslationPage);
