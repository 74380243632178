import React, { useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { Radio } from "antd";
import { constants } from "../../consts/Variables";
import { useMobxStores } from "../../stores/stores";

const VideoPlayer = (props) => {
  const { mainStore } = useMobxStores();
  // console.log(constants.STREAM_SERVER_URL)
  const [thermalStream, setthermalStream] = useState(false);
  const [rgbStream, setrgbStream] = useState(true);

  const onChange = (e) => {
    setthermalStream(!thermalStream);
    setrgbStream(!rgbStream);
  };

  return (
    <div>
      <Radio onChange={onChange} checked={thermalStream}>
        <b>Thermal Stream</b>
      </Radio>
      <Radio onChange={onChange} checked={rgbStream}>
        <b>RGB Stream</b>
      </Radio>
      <br />
      {rgbStream && (
        <>
          <ReactHlsPlayer
            // src={mainStore.uav_real_time_data.rgbStream}
            src={
              constants.UAV_STREAM_SERVER_URL
                ? constants.UAV_STREAM_SERVER_URL
                : mainStore.uav_real_time_data.rgbStream
            }
            autoPlay={true}
            controls={true}
            width="100%"
            height="auto"
            style={{ minHeight: "360px", borderRadius: "15px" }}
          />
        </>
      )}
      {thermalStream && (
        <>
          <ReactHlsPlayer
            // src={mainStore.ugv_real_time_data.thermalStream}
            src={
              constants.UAV_STREAM_SERVER_URL
                ? constants.UAV_STREAM_SERVER_URL
                : mainStore.uav_real_time_data.thermalStream
            }
            autoPlay={true}
            controls={true}
            width="100%"
            height="auto"
            style={{ minHeight: "360px", borderRadius: "15px" }}
          />
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
