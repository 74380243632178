import React, { useState, useEffect } from "react";
import { Col, Row, Divider, Card } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import EmptyData from "../emptydata/EmptyData";

const TAS = (props) => {
  const { mainStore } = useMobxStores();

  useEffect(() => {}, [props.TAS]);

  return (
    <Row justify="center" align="center">
      <Col span={24} lg={24} xs={24} sm={24}>
        <Divider>{props.text}</Divider>
        <Card style={{ borderradius: "15px", minHeight: "400px" }}>
          {props.TAS !== "" ? (
            <div>
              Last <span style={{ color: "green" }}>threat</span> assessment
              analysis result
              <ul>
                <li>
                  <b>ThreatLevel: </b>
                  {props.TAS.threatLevel != undefined &&
                    props.TAS.threatLevel}
                </li>
                <li>
                  <b>Confidence: </b>
                  {props.TAS.confidence != undefined &&
                    props.TAS.confidence}
                </li>
                <li>
                  <b>Threat: </b>
                  {props.TAS.threat != undefined && props.TAS.threat}
                </li>
                <li>
                  <b>Altitude: </b>
                  {props.TAS.altitude != undefined &&
                    props.TAS.altitude}
                </li>
                <li>
                  <b>Lat: </b>
                  {props.TAS.latitude != undefined &&
                    props.TAS.latitude}
                </li>
                <li>
                  <b>Lon: </b>
                  {props.TAS.longitude != undefined &&
                    props.TAS.longitude}
                </li>
              </ul>
              <Divider />
              {props.higher_TAS !== "" && (
                <p>
                  Last <span style={{ color: "red" }}>highest threat</span>{" "}
                  assessment analysis result
                </p>
              )}
              {props.higher_TAS !== "" && (
                <ul>
                  <li>
                    <b>ThreatLevel: </b>
                    {props.higher_TAS.threatLevel != undefined &&
                      props.higher_TAS.threatLevel}
                  </li>
                  <li>
                    <b>Confidence: </b>
                    {props.higher_TAS.threatLevel != undefined &&
                      props.higher_TAS.confidence}
                  </li>
                  <li>
                    <b>Threat: </b>
                    {props.higher_TAS.threatLevel != undefined &&
                      props.higher_TAS.threat}
                  </li>
                  <li>
                    <b>Altitude: </b>
                    {props.higher_TAS.threatLevel != "" &&
                      props.higher_TAS.altitude}
                  </li>
                  <li>
                    <b>Lat: </b>
                    {props.higher_TAS.latitude != "" &&
                      props.higher_TAS.latitude}
                  </li>
                  <li>
                    <b>Lon: </b>
                    {props.higher_TAS.longitude != "" &&
                      props.higher_TAS.longitude}
                  </li>
                </ul>
              )}
            </div>
          ) : (
            <EmptyData />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default observer(TAS);
