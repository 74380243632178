import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Space,
  Button,
  Divider,
  Table,
  Tag,
  Modal,
  Popconfirm,
} from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import CreateDrone from "./CreateUpdateDrone";
import Loader from "../loader/Loader";
import { v4 as uuidv4 } from "uuid";

var _ = require("lodash");

const Drone = (props) => {
  const { mainStore } = useMobxStores();
  const [isModalOpen, setIsModalOpen] = useState(props.openModal);

  useEffect(() => {
    mainStore.GetDrones("86b8b6bd42ce110000000900")
  }, []);

  const [reportModalData, setReportModalData] = useState([]);

  const onDeleteDrone = (record) => {
    mainStore.DeleteDrone(record.id);
  };

  const showModal = (record) => {
    setReportModalData(
      mainStore.drones.filter((item) => item.id == record.id)[0]
    );
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showCreateModal = () => {
    mainStore.setIsCreateDroneModalOpen(true);
  };

  const onEditDrone = (record) => {
    mainStore.setEditingDrone(record);
    mainStore.setDroneFields(record);
    mainStore.setIsDroneEditing(true);
    mainStore.setIsCreateDroneModalOpen(true);
  };

  const handleCreateOk = () => {
    mainStore.setIsCreateDroneModalOpen(false);
    mainStore.ResetDroneFields();
    mainStore.setIsDroneEditing(false);
  };

  const handleCreateCancel = () => {
    mainStore.setIsCreateDroneModalOpen(false);
    mainStore.ResetDroneFields();
    mainStore.setIsDroneEditing(false);
  };

  const columns = [
    {
      title: "Drone Name",
      dataIndex: "name",
      responsive: ["lg", "sm", "md", "xs"],
      key: "name",
    },
    {
      title: "Enable",
      dataIndex: "title",
      responsive: ["lg", "sm", "md", "xs"],
      key: "tags",
      render: (title) => (
        <p style={{ color: "#00703c" }}>
          <Tag color={title == "Enabled" ? "green" : "red"}>{title}</Tag>
        </p>
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      responsive: ["lg", "xl"],
      key: "created",
      render: (text) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="orange">{text}</Tag>
        </p>
      ),
    },
    {
      title: "Actions",
      responsive: ["lg", "sm", "md", "xs"],
      dataSource: "key",
      key: "action",
      width: "auto",
      render: (record) => (
        <Space>
          <Popconfirm
            title="Are you sure to delete this drone?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onDeleteDrone(record)}
          >
            <Button
              size="small"
              type="primary"
              danger
              // onClick={() => onDeleteDrone(record)}
              style={{ width: "auto" }}
            >
              {" "}
              Delete
            </Button>
          </Popconfirm>
          <Button
            size="small"
            type="primary"
            onClick={() => onEditDrone(record)}
            style={{ width: "auto" }}
          >
            {" "}
            Edit
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => showModal(record)}
            style={{ width: "auto", backgroundColor: "green" }}
          >
            {" "}
            More Info
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Row justify="center" align="center" className="full-viewport">
      <Col span={22}>
        <Divider orientation="left">Drones List</Divider>
        <Modal
          title={mainStore.isDroneEditing ? "Edit Drone" : "Create a new Drone"}
          open={mainStore.isCreateDroneModalOpen}
          onOk={handleCreateOk}
          onCancel={handleCreateCancel}
          footer={null}
        >
          {mainStore.isDroneCreating ? <Loader /> : <CreateDrone />}
        </Modal>
        <Modal
          title="Drone Information"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {reportModalData != [] ? (
            <>
              <p>
                <b>Name:</b> {reportModalData.name}
              </p>
              <p>
                <b>Description:</b> {reportModalData.title}
              </p>
              <p>
                {" "}
                <b>Date:</b> {reportModalData.created}
              </p>
            </>
          ) : (
            "No data"
          )}
        </Modal>
        <Space
          style={{ width: "100%" }}
          direction="vertical"
          wrap={true}
          split={true}
        >
          <Table
            columns={columns}
            dataSource={_.cloneDeep(mainStore.drones)}
            rowKey={() => uuidv4()}
            bordered={true}
            scroll={{ x: "400px" }}
          />
          <Button type={"primary"} onClick={() => showCreateModal()}>
            Add Drone
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default observer(Drone);
