import React, { useState, useEffect, useRef } from 'react'
import { Divider, Col, Row, Tag, Table, Button, Checkbox, Spin } from 'antd'
import { useMobxStores } from '../../stores/stores'
import { observer } from 'mobx-react'
import { v4 as uuidv4 } from 'uuid'
import { getOverridedColumns } from '../../utils/tableLoader'
import moment from 'moment'
import { DataSet, Network } from 'vis-network/standalone/umd/vis-network.min.js'

var _ = require('lodash')

const SocialNetwork = () => {
  const { mainStore } = useMobxStores()
  const [trigger, setTrigger] = useState(false)
  const [communities, setCommunities] = useState([])

  const [checkedList, setCheckedList] = useState([])
  const [showSpin, setShowSpin] = useState(false)

  const [keyActors, setKeyActors] = useState(false)
  const [network, setNetwork] = useState(null)
  const [nonKey, setNonKey] = useState([])
  const [filteredCom, setFilteredCom] = useState([])

  console.log(keyActors)
  //data
  const colors = [
    '#389e0d',
    '#4096ff',
    '#FF0000',
    '#FFFF00',
    '#FF7F50',
    '#00FF00',
    '#d3adf7',
    '#FFC0CB',
  ]
  const shadowColors = [
    '#fafafa',
    '#fafafa',
    '#fafafa',
    '#fafafa',
    '#fafafa',
    '#fafafa',
    '#fafafa',
    '#fafafa',
    // 'rgba(56, 158, 13, 0.2)',
    // 'rgba(64, 150, 255, 0.2)',
    // 'rgba(255, 0, 0, 0.2)',
    // 'rgba(255, 255, 0, 0.2)',
    // 'rgba(255, 127, 80, 0.2)',
    // 'rgba(0, 255, 0, 0.2)',
    // 'rgba(211, 173, 247, 0.2)',
    // 'rgba(255, 192, 203, 0.2)',
  ]

  const columns = [
    {
      title: 'Community',
      responsive: ['lg', 'sm', 'md', 'xs'],
      key: 'id',
      width: '20%',
      render: (record) => (
        <p style={{ color: '#00703c' }}>
          <Tag>{`ID: ${record.id}`}</Tag>
        </p>
      ),
    },
    {
      title: 'Size(Members)',
      responsive: ['lg', 'sm', 'md', 'xs'],
      key: 'type',
      width: '50%',
      render: (record) => (
        <p style={{ color: '#00703c' }}>{record.members.length}</p>
      ),
    },
    {
      title: 'View',
      responsive: ['lg', 'sm', 'md', 'xs'],
      key: 'type',
      width: '30%',
      render: (record) => (
        <p>
          <Row gutter={[20, 20]} key={record}>
            <Col span={6}>
              <div
                style={{
                  backgroundColor: colors[record.id - 1],
                  width: '20px',
                  height: '20px',
                }}
              >
                {' '}
              </div>
            </Col>
            <Col span={6}>
              {' '}
              <Checkbox
                onChange={onCheck(record.id)}
                checked={isChecked(record.id)}
              ></Checkbox>
            </Col>
          </Row>
        </p>
      ),
    },
  ]

  // created
  useEffect(() => {
    mainStore.GetSocialNetworkAnalysis()
  }, [])

  // computed
  useEffect(() => {
    if (mainStore.social_network_analysis.length > 0) {
      console.log(mainStore.social_network_analysis[0])
      let firstCommunity = mainStore.social_network_analysis[0]
      setCommunities(JSON.parse(firstCommunity.content).communities)
      let rawData = JSON.parse(firstCommunity.content)

      console.log(rawData)

      let newCommunities = []
      let newOptions = []
      let newChecks = []
      for (let i = 0; i < rawData.communities.length; i++) {
        let newMemberData = rawData.communities[i].members.map((member) => {
          return {
            id: parseInt(member.profile),
            label: member.profile,
            group: parseInt(rawData.communities[i].id),
            color: colors[parseInt(rawData.communities[i].id) - 1],
          }
        })
        newCommunities.push(...newMemberData)

        newOptions.push(parseInt(rawData.communities[i].id))
        newChecks.push(rawData.communities[i].id)
        setCheckedList(newChecks)
      }

      let newEdges = []
      //  rawData.network.map((edge) => {
      //   return {
      //     from: parseInt(edge.originProfile),
      //     to: parseInt(edge.destinationProfile),
      //     arrows: 'to',
      //   }
      // })

      if (networkRef.current) {
        // Create the network
        const data = {
          nodes: new DataSet([]),
          edges: new DataSet([]),
        }
        const options = {
          nodes: {
            scaling: {
              min: 10,
              max: 30,
            },
            font: {
              size: 12,
              face: 'Tahoma',
            },
          },

          physics: true,
        }
        const network = new Network(networkRef.current, data, options)

        // Add nodes and edges to the network
        data.nodes.add([...newCommunities])
        data.edges.add([...newEdges])

        // Cleanup function
        return () => {
          network.destroy()
        }
      }
    }
  }, [mainStore.social_network_analysis])

  useEffect(() => {
    if (mainStore.social_network_analysis.length > 0) {
      let firstCommunity = mainStore.social_network_analysis[0]

      let rawData = JSON.parse(firstCommunity.content)
      let keyActorsData = JSON.parse(
        firstCommunity.content
      ).key_actors[0].users.map((actor) => {
        return actor.profile
      })
      console.log(keyActorsData)
      let nonKeyActors = []
      let newCommunities = []

      for (let i = 0; i < rawData.communities.length; i++) {
        let newMemberData = rawData.communities[i].members.map((member) => {
          return {
            id: parseInt(member.profile),
            label: member.profile,
            group: parseInt(rawData.communities[i].id),
            color: colors[parseInt(rawData.communities[i].id) - 1],
          }
        })
        newCommunities.push(...newMemberData)
      }

      nonKeyActors = newCommunities.filter((member) => {
        return !keyActorsData.includes(member.id.toString())
      })
      setNonKey(nonKeyActors)
      console.log(nonKeyActors)

      // if (keyActors) {
      //   for (let i = 0; i < rawData.communities.length; i++) {
      //     let newMemberData = rawData.communities[i].members.map((member) => {
      //       if (keyActorsData.includes(member.profile)) {
      //         return {
      //           id: parseInt(member.profile),
      //           label: member.profile,
      //           group: parseInt(rawData.communities[i].id),
      //           color: colors[parseInt(rawData.communities[i].id) - 1],
      //         }
      //       } else {
      //         return {
      //           id: parseInt(member.profile),
      //           label: member.profile,
      //           group: parseInt(rawData.communities[i].id),
      //           color: shadowColors[parseInt(rawData.communities[i].id) - 1],
      //         }
      //       }
      //     })
      //     newCommunities.push(...newMemberData)
      //   }
      // } else {
      //   for (let i = 0; i < rawData.communities.length; i++) {
      //     let newMemberData = rawData.communities[i].members.map((member) => {
      //       return {
      //         id: parseInt(member.profile),
      //         label: member.profile,
      //         group: parseInt(rawData.communities[i].id),
      //         color: colors[parseInt(rawData.communities[i].id) - 1],
      //       }
      //     })
      //     newCommunities.push(...newMemberData)
      //   }
      // }

      // console.log(nonKeyActors)

      let filteredCommunities = newCommunities.filter((member) =>
        checkedList.includes(member.group.toString())
      )

      nonKeyActors = filteredCommunities.filter((member) => {
        return !keyActorsData.includes(member.id.toString())
      })
      setNonKey(nonKeyActors)

      setFilteredCom(filteredCommunities)
      // let sliced = filteredCommunities.slice(2,20)
      setKeyActors(false)
      let newEdges = rawData.network.map((edge) => {
        return {
          from: parseInt(edge.originProfile),
          to: parseInt(edge.destinationProfile),
          arrows: 'to',
        }
      })

      if (networkRef.current) {
        // Create the network
        const data = {
          nodes: new DataSet([]),
          edges: new DataSet([]),
        }
        const options = {
          physics: {
            enabled: true,
          },
        }
        const net = new Network(networkRef.current, data, options)

        // Add nodes and edges to the network
        data.nodes.add([...filteredCommunities])
        // data.nodes.add([...sliced]);

        data.edges.add([...newEdges])

        net.once('afterDrawing', () => {
          net.fit() // Adjust view to fit all nodes and edges
          net.moveTo({ scale: 0.6 }) // Set desired zoom level (e.g., 1 for default)
        })

        setNetwork(net)

        setShowSpin(true)

        let timeoutId = setTimeout(
          () => {
            clearTimeout(timeoutId)
            setShowSpin(false)
          },
          2 * checkedList.length * 1000
        )

        setTimeout(
          () => {
            net.setOptions({ physics: { enabled: false } })
          },
          2 * checkedList.length * 1000
        )
        return () => {
          clearTimeout(timeoutId) // Clear the timeout when the component unmounts
        }

        // let timeoutId = setTimeout(
        //   () => {
        //     clearTimeout(timeoutId)
        //     setShowSpin(false)
        //   },
        //   2 * checkedList.length * 1000
        // )

        // setTimeout(
        //   () => {
        //     network.setOptions({ physics: { enabled: false } })
        //   },
        //   2 * checkedList.length * 1000
        // )
        return () => {
          // clearTimeout(timeoutId) // Clear the timeout when the component unmounts
        }
      }
    }
  }, [checkedList, mainStore.social_network_analysis])

  // methods

  const chageNodeColor = (keyActors) => {
    if (keyActors === true) {
      setKeyActors(false)
    } else {
      setKeyActors(true)
    }
  }
  const onClickKeyActors = () => {
    console.log(nonKey)

    chageNodeColor(keyActors)

    if (!keyActors) {
      for (let i = 0; i < nonKey.length; i++) {
        network.body.data.nodes.update({
          id: nonKey[i].id,
          borderWidth: 3, // Adjust as needed
          color: {
            border: '#D1D1D1',
            background: '#D1D1D1',
          },
        })

        network.body.data.edges.update({
          id: nonKey[i].id,
          color: {
            color: '#D1D1D1',
            // highlight: 'g'
          },
          width: 3, // Adjust as needed
        })
      }
    } else {
      for (let i = 0; i < nonKey.length; i++) {
        network.body.data.nodes.update({
          id: nonKey[i].id,
          borderWidth: 3, // Adjust as needed
          color: {
            border: nonKey[i].color,
            background: nonKey[i].color,
          },
        })

        network.body.data.edges.update({
          id: nonKey[i].id,
          color: {
            color: '',
            // highlight: 'g'
          },
          width: 3, // Adjust as needed
        })
      }
    }
  }

  const onClick = () => {
    let checked = [...checkedList]
    setCheckedList([])
    setCheckedList(checked)
    setTrigger(true)
    mainStore.Publish({
      topics: ['MA_SNA_REQUEST'],
      body: JSON.stringify({
        header: {
          topicName: 'MA_SNA_REQUEST',
          sender: 'INTRA',
          sentUtc: moment().format("yyyy-MM-dd'T'HH:mm:ss'Z'"),
          source: 'INTRA',
          caseId: '6423f44ccf6a810001866fc5',
        },
        body: {
          taskId: 'ABCD_SYNTHETIC', //enum: ["ABCD_SYNTHETIC","PHONE_DATASET"]
        },
      }),
    })
  }
  const onCheck = (recordId) => (e) => {
    let value = e.target.checked
    let newCheckedList = [...checkedList] // Create a copy of the array

    if (value === true) {
      newCheckedList.push(recordId) // Push recordId into the array
    } else {
      newCheckedList = newCheckedList.filter((id) => {
        return id !== recordId // Filter out recordId from the array
      })
    }
    // console.log()

    setCheckedList(newCheckedList)
  }

  const isChecked = (recordId) => {
    return checkedList.includes(recordId)
  }

  const networkRef = useRef(null)

  return (
    <div>
      <div>
        <Divider orientation='left'>Social Network</Divider>
        {!trigger && (
          <Button type='primary' onClick={() => onClick()}>
            {' '}
            Display Communities and Key actors in social networks
          </Button>
        )}
        {trigger && (
          <Row justify='top' align='top'>
            <Col span={5}>
              <Table
                columns={
                  _.cloneDeep(mainStore.social_network_analysis).length > 0
                    ? columns
                    : getOverridedColumns(columns)
                }
                dataSource={
                  _.cloneDeep(mainStore.social_network_analysis).length > 0
                    ? _.cloneDeep(communities)
                    : Array(5).fill({})
                }
                rowKey={() => uuidv4()}
                bordered={true}
                rowClassName='custom-row'
                align='center'
                // scroll={{ x: "400px" }}
              />
            </Col>
            <Col span={1}></Col>

            <Col span={12}>
              <div>
                <Checkbox
                  disabled={showSpin}
                  onChange={onClickKeyActors}
                  checked={keyActors}
                >
                  Highlight key actors
                </Checkbox>
              </div>
              <div
                ref={networkRef}
                id='mynetwork'
                style={{
                  width: '1000px',
                  height: '600px',
                  border: '1px solid lightgray',
                }}
              />

              {showSpin && (
                <Spin
                  style={{
                    zIndex: 10,
                    marginTop: '-600px',
                    width: '1000px',
                    minHeight: '600px',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    fontSize: '24px',
                  }}
                  tip='Please wait for the stabilization of the network. It will take a few seconds...'
                >
                  {' '}
                  <div className='content' />{' '}
                </Spin>
              )}
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default observer(SocialNetwork)
