import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import ReactApexChart from "react-apexcharts";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import _ from "lodash";
import { mdiConsoleNetwork } from "@mdi/js";
import { Row, Spin, Col, Divider, Space, Tag, Image } from "antd";
import { relativeTimeRounding } from "moment";
import { mdiHelpCircleOutline } from "@mdi/js";

const Confer = (props) => {
  const { mainStore, webSocialMediaStore, webCrawlStore, errorStore } =
    useMobxStores();

  const [characters, setCharacters] = useState([]);

  const [analyzedCharacters, setAnalyzedCharacters] = useState(
    webSocialMediaStore.analyzedCharacters
  );

  // useEffect(() => {
  //   console.log(props.pk, props.domainId);
  //   mainStore.GetConfer(props.pk, props.domainId);
  // }, []);
  useEffect(() => {
    setAnalyzedCharacters(webSocialMediaStore.analyzedCharacters);
    console.log(analyzedCharacters);
  }, [webSocialMediaStore.analyzedCharacters]);

  const isInRange = (x, ranges) => {
    if (ranges.length > 0) {
      for (let i = 0; i < ranges.length; i++) {
        const [min, max] = ranges[i].range;
        if (x >= min && x <= max) {
          return { inRange: true, max: max, link: ranges[i].wikiLink };
        }
      }
      return { inRange: false, max: null, link: null };
    } else return { inRange: false, max: null, link: null };
  };
  const goToLink = (link) => {
    window.open(link, "_blank");
  };
  const categoryBackgroundColor = (x, ranges) => {
    if (ranges.length > 0) {
      for (let i = 0; i < ranges.length; i++) {
        const [min, max] = ranges[i].range;
        if (x >= min && x <= max) {
          if (ranges[i].category === "substance") {
            return "red";
          }
          if (ranges[i].category === "date") {
            return "#BDB76B";
          }
          if (ranges[i].category === "glassware") {
            return "#FAEBD7";
          }
          if (ranges[i].category === "location") {
            return "#7FFF00";
          }
          if (ranges[i].category === "group") {
            return "#D2691E";
          }
          if (ranges[i].category === "item") {
            return "#FF7F50";
          }
          if (ranges[i].category === "person") {
            return "#40C4FF";
          }
          if (ranges[i].category === "quantity") {
            return "#00FFFF";
          }
          if (ranges[i].category === "status") {
            return "#00008B";
          }
          if (ranges[i].category === "art") {
            return "#E040FB";
          }
        }
      }
      return undefined;
    } else return undefined;
  };

  const categoryColor = (x, ranges) => {
    if (ranges.length > 0) {
      for (let i = 0; i < ranges.length; i++) {
        const [min, max] = ranges[i].range;
        if (x >= min && x <= max) {
          if (ranges[i].category === "substance") {
            return "white";
          }
          if (ranges[i].category === "date") {
            return "black";
          }
          if (ranges[i].category === "glassware") {
            return "black";
          }
          if (ranges[i].category === "location") {
            return "black";
          }
          if (ranges[i].category === "group") {
            return "black";
          }
          if (ranges[i].category === "item") {
            return "black";
          }
          if (ranges[i].category === "person") {
            return "black";
          }
          if (ranges[i].category === "quantity") {
            return "black";
          }
          if (ranges[i].category === "status") {
            return "white";
          }
          if (ranges[i].category === "art") {
            return "black";
          }
        }
      }
      return undefined;
    } else return undefined;
  };

  useEffect(() => {
    setCharacters(webSocialMediaStore.characters);
  }, [webSocialMediaStore.characters]);

  return (
    <Row>
      {" "}
      {/* {
        <div
          style={{
            height: "200px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin tip="Identifying Groups" size="small">
            {" "}
            <div
              style={{
                padding: 50,
                background: "rgba(0, 0, 0, 0.00)",
                borderRadius: 4,
              }}
            ></div>
          </Spin>
        </div>
      } */}
      {characters.length > 0 &&
        characters.map((character, index) => {
          const { inRange, max, link } = isInRange(index, analyzedCharacters);
          return (
            <span style={{ position: "relative", zIndex: 4 }}>
              {/* <div></div> */}
              {/* <div
              style={{
                position: "absoloute",
                zIndex: "3",
                marginBottom: "-12px",
              }}
            >
              {" "}
              <Image
                width={"12px"}
                height={"2px"}
                style={{}}
                src={"/greenLine.svg"}
              />{" "}
            </div> */}
              <div
                style={{
                  // backgroundColor: "purple",
                  // position: "absolute",
                  whiteSpace: "pre-wrap",
                  zIndex: 3,
                  marginBottom: " -2px",
                  color: categoryColor(index, analyzedCharacters),
                }}
                key={index}
              >
                {character}
                {inRange && index === max && link && (
                  <span
                    style={{
                      fontSize: "20px",
                      verticalAlign: "middle", // Ensures the icon is vertically aligned with the text
                    }}
                  >
                    <div
                      style={{
                        marginTop: "-41px",
                        // Ensures the icon is vertically aligned with the text
                      }}
                    >
                      {" "}
                      <Icon
                        onClick={() => goToLink(link)}
                        path={mdiHelpCircleOutline}
                        size={0.7}
                      />
                    </div>

                    {/* Adjust size as needed */}
                  </span>
                )}
              </div>{" "}
              {isInRange(index, analyzedCharacters) && (
                <div>
                  <div
                    style={{
                      backgroundColor: categoryBackgroundColor(
                        index,
                        analyzedCharacters
                      ),

                      zIndex: 2,
                      marginTop: "  -20px",
                      width: "100%",
                      height: "20px",
                    }}
                  >
                    {" "}
                  </div>
                </div>
              )}
            </span>
          );
        })}
    </Row>
  );
};

export default observer(Confer);
