import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./style.css";
import keycloak from "./keycloak/keycloak";
import jwt from "jwt-decode";
import { getStores, StoreProvider } from "./stores/stores";
import { ControlSaveTiles } from "leaflet.offline";

const root = ReactDOM.createRoot(document.getElementById("root"));
const mobxStores = getStores();

const onUpdateToken = () => {
  if (keycloak.isTokenExpired()) {
    // Refresh token
    keycloak
      .updateToken(10)
      .then(function (refreshed) {
        if (refreshed) {
          console.log("Token refreshed");
        } else {
          console.log("Token not refreshed, valid for another 10 minutes");
        }
      })
      .catch(function () {
        console.error("Failed to refresh token");
      });
  }
};

const onLoadUser = async () => {
  return keycloak
    .loadUserProfile()
    .then((profile) => {
      localStorage.setItem("user", JSON.stringify(profile));
    })
    .catch((error) => {
      console.log(error);
    });
};

const onLogout = () => {
  localStorage.removeItem("od_token");
  localStorage.removeItem("c_k");
  localStorage.removeItem("currentUser");
  keycloak.logout({ redirectUri: window.location.origin + "/" });
};

const onLogin = () => {
  keycloak.login({ redirectUri: window.location.origin + "/home" });
};

keycloak.onAuthLogout = function () {
  localStorage.removeItem("currentUser");
  localStorage.removeItem("od_token");
};

keycloak.onAuthSuccess = function () {
  localStorage.setItem("od_token", keycloak.token);
  localStorage.setItem("od_token_decoded", JSON.stringify(jwt(keycloak.token)));
  onLoadUser();
};

keycloak
  .init({
    onLoad: "check-sso",
    checkLoginIframe: false,
  })
  .then((authenticated) => {
    if (authenticated) {
      localStorage.setItem("od_token", keycloak.token);
      onLoadUser();

      // const roles = keycloak.token.realm_access.roles;
      // console.log(roles);
      // console.log(jwt(keycloak.token));
      const roles = jwt(keycloak.token).realm_access.roles;
      console.log(roles);
      console.log(jwt(keycloak.token));
      // mobxStores.usersStore.getKeycloakSessions();
      keycloak.loadUserInfo().then((data) => {
        console.log(data);
      });
      mobxStores.usersStore.GetProjectIdOfUsers().then(() => {
        mobxStores.usersStore.GetAllUsers().then((users) => {
          let domainIds = [];
          if (users.length > 0) {
            domainIds = users.map((user) => {
              return user.domainId;
            });
          }

          if (users.length > 0 && domainIds.includes(jwt(keycloak.token).sub)) {
            console.log("abb");
            mobxStores.usersStore.SetCurrentUser({
              type: roles,
              domainId: jwt(keycloak.token).sub,
              name: jwt(keycloak.token).name,
            });
            console.log(mobxStores.usersStore.currentUser);
          } else {
            console.log("cdd");
            mobxStores.usersStore.AddUserToProject({
              type: roles,
              domainId: jwt(keycloak.token).sub,
              name: jwt(keycloak.token).name,
            });
            console.log(mobxStores.usersStore.currentUser);
          }
        });
      });

      mobxStores.mainStore.GetProjectIdOfAlerts();
      mobxStores.mainStore.GetProjectIdOfAlertsTAS();
      mobxStores.mainStore.GetProjectIdOfSessions();
    } else {
      onLogin();
    }
  });

root.render(
  <React.StrictMode>
    <App
      token={keycloak.token}
      onLogout={onLogout}
      onLogin={onLogin}
      onUpdateToken={onUpdateToken}
      user={onLoadUser}
    />
  </React.StrictMode>
);
