import React from "react";
import { Row, Col, Button, Select, Form, Input } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";

const { Option } = Select;

const drone_fields = [
  {
    label: "Name",
    name: "name",
    type: "string",
    disabled: false,
  },
];

const status_list = [
  {
    label: "Enabled",
    value: "Enabled",
    type: "string",
  },
  {
    label: "Disabled",
    value: "Disabled",
    type: "string",
  },
];

const CreateUpadteDrone = (props) => {
  const { mainStore } = useMobxStores();

  const onCreate = (values) => {
    values.type = "drone";
    values.tags = [];
    values.domainId = uuidv4();
    values.attributes = {
      additionalProp1: [values.status],
      additionalProp2: ["string"],
      additionalProp3: ["string"],
    };
    mainStore.CreateDrone(values);
  };

  const onEdit = (values) => {
    let drone = mainStore.drones.filter((item) => item.id == values.id)[0];
    drone.name = values.name;
    drone.title = values.title;
    mainStore.UpdateDrone(drone);
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  return (
    <Row justify="center" align="center">
      <Col span={24}>
        <Form
          name="drone-form"
          onFinish={mainStore.isDroneEditing ? onEdit : onCreate}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          fields={mainStore.DroneFields}
        >
          {mainStore.isDroneEditing && (
            <Form.Item
              label={"Id"}
              name={"id"}
              rules={[
                {
                  required: true,
                  message: `Please input ${"Status"}!`,
                  type: "string",
                },
              ]}
            >
              <Input disabled={true} placeholder={"Id"} />
            </Form.Item>
          )}
          {drone_fields.map((item, index) => {
            return (
              <Form.Item
                key={index}
                label={item.label}
                name={item.name}
                rules={[
                  {
                    required: true,
                    message: `Please input ${item.label}!`,
                    type: item.type,
                  },
                ]}
              >
                <Input disabled={item.disabled} placeholder={item.label} />
              </Form.Item>
            );
          })}
          <Form.Item
            label={"Status"}
            name={"title"}
            rules={[
              {
                required: true,
                message: `Please input ${"Status"}!`,
                type: "string",
              },
            ]}
          >
            <Select placeholder={"Status"}>
              {status_list.map((item, index) => {
                return (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              {mainStore.isDroneEditing ? "Edit" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default observer(CreateUpadteDrone);
