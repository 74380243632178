import React, { useEffect } from "react";
import { Col, Row, Divider, Card } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import EmptyData from "../emptydata/EmptyData";

var _ = require("lodash");

const WaterSensorData = (props) => {
  const { mainStore } = useMobxStores();

  const formattedDate = (d) => {
    const date = new Date(d); // Assuming 223223 represents milliseconds

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${hours}:${minutes}:${seconds}  ${year}-${month}-${day}`;
  };

  useEffect(() => {}, [mainStore.sensor_water_data, mainStore.gas]);

  console.log(mainStore.sensor_water_data);
  console.log(mainStore.gas);

  return (
    <Row justify="center" align="center">
      <Col span={24} lg={24} xs={24} sm={24}>
        <Divider> Water Sensor Data</Divider>
        <Card style={{ borderradius: "15px", minHeight: "400px" }}>
          {mainStore.sensor_water_data != "" ? (
            <div>
              <div
                style={{
                  marginTop: "12px",
                }}
              >
                <Row justify="end">
                  <div>
                    {" "}
                    {formattedDate(
                      _.cloneDeep(mainStore.sensor_water_data.header.published)
                    )}{" "}
                  </div>
                </Row>
                <Row justify="end">
                  <span style={{ fontWeight: "bold" }}>latitude:</span>
                  <span>
                    {_.cloneDeep(
                      mainStore.sensor_water_data.attributes.gps_latitude
                    )}
                  </span>
                </Row>
                <Row justify="end">
                  <span style={{ fontWeight: "bold" }}>longitude:</span>-
                  <span>
                    {_.cloneDeep(
                      mainStore.sensor_water_data.attributes.gps_longitude
                    )}
                  </span>
                </Row>
                <Row justify="end">
                  <span style={{ fontWeight: "bold" }}>altitude:</span>
                  <span>
                    {_.cloneDeep(
                      mainStore.sensor_water_data.attributes.gps_altitude
                    )}
                  </span>
                </Row>
              </div>
              <Divider
                style={{
                  marginTop: "2px",
                  marginBottom: "-4px",
                }}
              ></Divider>
              <div
                style={{
                  borderRadius: "6px",
                  border: "grey 1px solid ",
                  marginTop: "12px",
                }}
              >
                <Row
                  justify="center"
                  style={{ backgroundColor: "#314881", color: "white" }}
                >
                  <div>{_.cloneDeep(mainStore.gas[0].gas)} </div>
                </Row>
                <Row justify="center">
                  <div>
                    <span style={{ fontSize: "22px" }}>
                      {" "}
                      {_.cloneDeep(mainStore.gas[0].data)}
                    </span>
                    <span> {_.cloneDeep(mainStore.gas[0].units)}</span>
                  </div>
                </Row>
              </div>
              <div
                style={{
                  borderRadius: "6px",
                  border: "grey 1px solid ",
                  marginTop: "12px",
                }}
              >
                <Row
                  justify="center"
                  style={{ backgroundColor: "#f57c00", color: "black" }}
                >
                  <div>{_.cloneDeep(mainStore.gas[1].gas)} </div>
                </Row>
                <Row justify="center">
                  <div>
                    <span style={{ fontSize: "22px" }}>
                      {" "}
                      {_.cloneDeep(mainStore.gas[1].data)}
                    </span>
                    <span> {_.cloneDeep(mainStore.gas[1].units)}</span>
                  </div>
                </Row>
              </div>
            </div>
          ) : (
            <EmptyData />
          )}

          {/* <ul>
            {mainStore.sensor_water_data != "" ? (
              <>
                <li>
                  {<b> Published :</b>}{" "}
                  {_.cloneDeep(mainStore.sensor_water_data.header.published)}
                </li>
                <li>
                  {<b> Target molecule units:</b>}{" "}
                  {_.cloneDeep(mainStore.gas[0].units)}
                </li>
                <li>
                  {<b> Target molecule:</b>} {_.cloneDeep(mainStore.gas[0].gas)}
                </li>
                <li>
                  {<b> Target molecule data:</b>}{" "}
                  {_.cloneDeep(mainStore.gas[0].data)}
                </li>
                <li>
                  {<b> Session ID :</b>}{" "}
                  {_.cloneDeep(
                    mainStore.sensor_water_data.attributes.sessionId
                  )}
                </li>
         
                <li>
                  {<b> GPS latitude :</b>}{" "}
                  {_.cloneDeep(
                    mainStore.sensor_water_data.attributes.gps_latitude
                  )}
                </li>
                <li>
                  {<b> GPS longitude :</b>}{" "}
                  {_.cloneDeep(
                    mainStore.sensor_water_data.attributes.gps_longitude
                  )}
                </li>
                <li>
                  {<b> GPS altitude :</b>}{" "}
                  {_.cloneDeep(
                    mainStore.sensor_water_data.attributes.gps_altitude
                  )}
                </li>
              </>
            ) : (
              <EmptyData />
            )}
          </ul> */}
        </Card>
      </Col>
    </Row>
  );
};

export default observer(WaterSensorData);

var water = {
  header: {
    domainId: "WaterSensor:Data:{12c4783df07d41c9baf76c6fb418cd6c}",
    rawType: "string",
    title: "New Data Point",
    raw: "null",
    source: "WaterSensor",
    published: "2023-11-08T10:35:40.2554Z",
    type: "chemical:detected",
    name: "Chemical Detection mirsuhdiuehskkie3c21e0fae",
  },
  attributes: {
    deviceError: 0,
    "measure d_properties": [
      "case_temperature",
      "cell_temperature",
      "gas_concentration",
    ],
    gas_concentration: [{ units: "ppm", gas: "NH3", data: 0.6894586181640625 }],
    cell_temperature: { units: "K", data: 353.3145751953125 },
    deviceSerialNumber: "MirSenseWaterSensor",
    location_type: "GeoProperty",
    "device Status": 2,
    sessionId: "5f73e5e9-5bfe-4244-9ec6-b6a214e113ec",
    case_temperature: { units: "K", data: 301.79815673828125 },
    gps_latitude: 45.5222147,
    gps_longitude: 11514993667602539,
    gps_altitude: 76.304,
  },
};
