import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import _ from "lodash";
import { mdiConsoleNetwork } from "@mdi/js";
import { Row, Spin, Col, Divider, Space, Tag, Image } from "antd";
import { relativeTimeRounding } from "moment";

const Confer = (props) => {
  const { mainStore, webSocialMediaStore, webCrawlStore, errorStore } =
    useMobxStores();

  const [characters, setCharacters] = useState([]);

  const [blueGroup, setBlueGroup] = useState(webSocialMediaStore.blueGroup);

  const [orangeGroup, setOrangeGroup] = useState(
    webSocialMediaStore.orangeGroup
  );

  const [redGroup, setRedGroup] = useState(webSocialMediaStore.redGroup);

  const [purpleGroup, setPurpleGroup] = useState(
    webSocialMediaStore.purpleGroup
  );

  const [limeGroup, setLimeGroup] = useState(webSocialMediaStore.limeGroup);

  const [greenGroup, setGreenGroup] = useState(webSocialMediaStore.greenGroup);
  const [cyanGroup, setCyanGroup] = useState(webSocialMediaStore.cyanGroup);

  const [yellowGroup, setYellowGroup] = useState(
    webSocialMediaStore.yellowGroup
  );

  const [showGroups, setShowGroups] = useState(webSocialMediaStore.showGroups);

  useEffect(() => {
    console.log(props.pk, props.domainId);
    webSocialMediaStore.GetConfer(props.pk, props.domainId);
  }, []);
  useEffect(() => {
    setShowGroups(webSocialMediaStore.showGroups);
  }, [webSocialMediaStore.showGroups]);

  useEffect(() => {
    setBlueGroup(webSocialMediaStore.blueGroup);
    setOrangeGroup(webSocialMediaStore.orangeGroup);
    setRedGroup(webSocialMediaStore.redGroup);
    setPurpleGroup(webSocialMediaStore.purpleGroup);
    setLimeGroup(webSocialMediaStore.limeGroup);
    setGreenGroup(webSocialMediaStore.greenGroup);
    setCyanGroup(webSocialMediaStore.cyanGroup);
    setYellowGroup(webSocialMediaStore.yellowGroup);
  }, [
    webSocialMediaStore.blueGroup,
    webSocialMediaStore.orangeGroup,
    webSocialMediaStore.redGroup,
    webSocialMediaStore.purpleGroup,
    webSocialMediaStore.limeGroup,
    webSocialMediaStore.greenGroup,
    webSocialMediaStore.cyanGroup,
    webSocialMediaStore.yellowGroup,
  ]);

  const isInRange = (x, ranges) => {
    if (ranges.length > 0) {
      for (let i = 0; i < ranges.length; i++) {
        const [min, max] = ranges[i];
        if (x >= min && x <= max) {
          return true;
        }
      }
      return false;
    } else return false;
  };

  useEffect(() => {
    setCharacters(webSocialMediaStore.characters);
  }, [webSocialMediaStore.characters]);

  return (
    <Row>
      {!showGroups && (
        <div
          style={{
            height: "200px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin tip="Identifying Groups" size="small">
            {" "}
            <div
              style={{
                padding: 50,
                background: "rgba(0, 0, 0, 0.00)",
                borderRadius: 4,
              }}
            ></div>
          </Spin>
        </div>
      )}

      {showGroups &&
        characters.length > 0 &&
        characters.map((character, index) => (
          <span style={{ position: "relative", zIndex: 2 }}>
            {/* <div></div> */}
            {/* <div
              style={{
                position: "absoloute",
                zIndex: "3",
                marginBottom: "-12px",
              }}
            >
              {" "}
              <Image
                width={"12px"}
                height={"2px"}
                style={{}}
                src={"/greenLine.svg"}
              />{" "}
            </div> */}
            <div
              style={{
                // backgroundColor: "purple",
                // position: "absolute",
                whiteSpace: "pre-wrap",
                zIndex: 3,
                marginBottom: " -2px",
              }}
              key={index}
            >
              {character}
            </div>{" "}
            {isInRange(index, blueGroup) && props.groupBlueChecked && (
              <div>
                <div
                  style={{
                    backgroundColor: "blue",

                    zIndex: 4,
                    marginTop: "  1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {(!isInRange(index, blueGroup) || !props.groupBlueChecked) && (
              <div>
                <div
                  style={{
                    backgroundColor: "white",

                    zIndex: 4,
                    marginTop: "  1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {isInRange(index, orangeGroup) && props.groupOrangeChecked && (
              <div>
                <div
                  style={{
                    backgroundColor: "orange",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {(!isInRange(index, orangeGroup) || !props.groupOrangeChecked) && (
              <div>
                <div
                  style={{
                    backgroundColor: "white",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {isInRange(index, redGroup) && props.groupRedChecked && (
              <div>
                <div
                  style={{
                    backgroundColor: "red",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {(!isInRange(index, redGroup) || !props.groupRedChecked) && (
              <div>
                <div
                  style={{
                    backgroundColor: "white",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {isInRange(index, limeGroup) && props.groupLimeChecked && (
              <div>
                <div
                  style={{
                    backgroundColor: "#7FFF00",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {(!isInRange(index, limeGroup) || !props.groupLimeChecked) && (
              <div>
                <div
                  style={{
                    backgroundColor: "white",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {isInRange(index, purpleGroup) && props.groupPurpleChecked && (
              <div>
                <div
                  style={{
                    backgroundColor: "#E040FB",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {(!isInRange(index, purpleGroup) || !props.groupPurpleChecked) && (
              <div>
                <div
                  style={{
                    backgroundColor: "white",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {isInRange(index, greenGroup) && props.groupGreenChecked && (
              <div>
                <div
                  style={{
                    backgroundColor: "green",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {(!isInRange(index, greenGroup) || !props.groupGreenChecked) && (
              <div>
                <div
                  style={{
                    backgroundColor: "white",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {isInRange(index, cyanGroup) && props.groupCyanChecked && (
              <div>
                <div
                  style={{
                    backgroundColor: "#00BCD4",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {(!isInRange(index, cyanGroup) || !props.groupCyanChecked) && (
              <div>
                <div
                  style={{
                    backgroundColor: "white",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {isInRange(index, yellowGroup) && props.groupYellowChecked && (
              <div>
                <div
                  style={{
                    backgroundColor: "yellow",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            {(!isInRange(index, yellowGroup) || !props.groupYellowChecked) && (
              <div>
                <div
                  style={{
                    backgroundColor: "white",

                    zIndex: 4,
                    marginTop: " 1px",
                    width: "100%",
                    height: "3px",
                  }}
                >
                  {" "}
                </div>
              </div>
            )}
            <div
              style={{
                backgroundColor: "black",

                zIndex: 4,
                marginTop: " 6px",
                width: "120%",
                height: "1px",
              }}
            >
              {" "}
            </div>
          </span>
        ))}
    </Row>
  );
};

export default observer(Confer);
