import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import { Card, Button, Row } from "antd";
import EmptyData from "../emptydata/EmptyData";
import Icon from "@mdi/react";

const mapStyle = {
  height: "200px",
  minHeight: "300px",
  width: "auto",
  borderRadius: "15px",
};

const customMarker = new L.icon({
  iconUrl: "../../../../../blue-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const UAVMap = () => {
  const { mainStore } = useMobxStores();

  console.log(mainStore.sensor_air_data);

  return (
    <div id="map">
      {mainStore.sensor_air_data != "" ? (
        <MapContainer
          zoom={3}
          minZoom={1}
          scrollWheelZoom={true}
          style={mapStyle}
          center={[
            mainStore.sensor_air_data.lat != undefined &&
              mainStore.sensor_air_data.lat != 0 &&
              mainStore.sensor_air_data.lat,
            mainStore.sensor_air_data.lon != undefined &&
              mainStore.sensor_air_data.lon != 0 &&
              mainStore.sensor_air_data.lon,
          ]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={[
              mainStore.sensor_air_data.lat != undefined &&
                mainStore.sensor_air_data.lat != 0 &&
                mainStore.sensor_air_data.lat,
              mainStore.sensor_air_data.lon != undefined &&
                mainStore.sensor_air_data.lon != 0 &&
                mainStore.sensor_air_data.lon,
            ]}
            icon={customMarker}
          >
            <Popup maxWidth="auto" maxHeight="auto" style={{ width: "auto" }}>
              <Card
                // title={`Cluster ${item.id} (${item.recipe})`}
                style={{
                  width: "420px",
                  minWidth: "auto",
                  textAlign: "left",
                  height: "100px",
                }}
                // bodyStyle={{
                //   textAlign: "left",

                //   // maxHeight: "60vh",
                // }}
              >
                <Row>
                  <span>
                    <div>
                      <span style={{ fontWeight: "bold" }}>Lat:</span>{" "}
                      {mainStore.sensor_air_data.lat}{" "}
                    </div>
                  </span>
                  <span style={{ marginLeft: "20px" }}>
                    <div>
                      <span style={{ fontWeight: "bold" }}>Long:</span>{" "}
                      {mainStore.sensor_air_data.lon}{" "}
                    </div>
                  </span>
                  <span style={{ marginLeft: "20px" }}>
                    <div>
                      <span style={{ fontWeight: "bold" }}>Alt:</span>{" "}
                      {mainStore.sensor_air_data.alt}{" "}
                    </div>
                  </span>
                </Row>

                {/* {` Confidence: ${item.properties.confidence}`} */}
                <br />

                {/* <a href={item.properties.detailsLink}>Details</a> */}
              </Card>
            </Popup>
          </Marker>
        </MapContainer>
      ) : (
        <EmptyData />
      )}
    </div>
  );
};

export default observer(UAVMap);
