import React from "react";
import { FloatButton } from "antd";

const BackToTop = () => {
  return (
    <FloatButton.BackTop type="primary" />
  );
};

export default BackToTop;
