import moment from "moment";

export function dateForTables(date) {
  // Convert a timestamp from UTC timezone to the local timezone and return the date
  if (date === null || date === "" || date === undefined) {
    return "";
  }
  const newDate = moment(date).format("DD/MM/YYYY HH:MM");
  return newDate;
}
