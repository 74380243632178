import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Divider,
  Tag,
  Space,
  Button,
  Modal,
  Radio,
  message,
  Input,
  Tabs,
  Card,
  Select,
  Upload,
} from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
// import { GetUser } from "../../components/helpers/user";
import { CSVLink } from "react-csv";
import { v4 as uuidv4 } from "uuid";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
import VideoPlayerUAV from "../../components/videoPlayer/VideoPlayerUAV";
import UGVMap from "../../components/field_operator/UGVMap";
import UGVMapHistory from "../../components/field_operator/UGVMapHistory";
import UGVStatus from "../../components/field_operator/UGVStatus";
import AirSensorData from "../../components/field_operator/AirSensorData";
import AirSensorPeaks from "../../components/field_operator/AirSensorPeaks";
import AirSensorPeaksHistory from "../../components/field_operator/AirSensorPeaksHistory";
import UAVMap from "../../components/field_operator/UAVMap";
import UAVMapHistory from "../../components/field_operator/UAVMapHistory";
import TASMap from "../../components/field_operator/TASMap";
import TASMapHistory from "../../components/field_operator/TASMapHistory";
import TAS from "../../components/field_operator/TAS";
import TASHistory from "../../components/field_operator/TASHistory";
import MapATD from "../../components/field_operator/MapATD";
import TAS_CONFIG from "../../components/field_operator/TAS_CONFIG";
import WaterSensorData from "../../components/field_operator/WaterSensorData";
import WaterSensorPeaks from "../../components/field_operator/WaterSensorPeaks";
import WaterSensorPeaksHistory from "../../components/field_operator/WaterSensorPeaksHistory";
import Icon from "@mdi/react";
import { mdiPlay, mdiStop } from "@mdi/js";
import { useParams, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { PrinterOutlined, ExportOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";

import { LoadingOutlined } from "@ant-design/icons";
import "../../components/field_operator/BlinkingDots.css";

import _ from "lodash";

const { Option } = Select;

const { TabPane } = Tabs;

const { TextArea } = Input;

const dataSource = [
  {
    key: "1",
    name: "Field Operation session 1",
    description: "description",
    status: "completed",
    criticality: 5,
  },
  {
    key: "2",
    name: "Field Operation session 2",
    description: "description",
    status: "completed",
    criticality: 9,
  },
  {
    key: "3",
    name: "Field Operation session 3",
    description: "description",
    status: "completed",
    criticality: 6,
  },
  {
    key: "4",
    name: "Field Operation session 4",
    description: "description",
    status: "completed",
    criticality: 3,
  },
];

const FieldOperator = (props) => {
  const { mainStore, errorStore, usersStore, fieldHistoryStore } =
    useMobxStores();
  const [isModalOpen, setIsModalOpen] = useState(props.openModal);
  const [isDronesModalOpen, setIsDronesModalOpen] = useState(false);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [startInternalId, setstartInternalId] = useState(null);
  const [stopInternalId, setstopInternalId] = useState(null);
  const [tabContent, setTabContent] = useState("1");
  const [activeKey, setActiveKey] = useState("1");
  // const [boxHeight, setBoxHeight] = useState(window.innerHeight * 0.5);
  const newContent = (key) => {
    setTabContent(key);
    setActiveKey(key);
  };
  console.log(fieldHistoryStore.sessions);
  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => componentRefField.current,
    documentTitle: "Mission Report", // Optional: you can give a title to the document
    pageStyle: `@page {
      size: landscape; /* Force landscape orientation */
    }`,
  });

  const componentRefField = useRef();
  const handleFileChange = (info) => {
    if (info.file.status !== "uploading") {
      const file = info.file.originFileObj; // Access the actual file object
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const jsonNew = JSON.parse(e.target.result);
            console.log(jsonNew);

            // let fake = {
            //   id: "string",
            //   type: "WaterSensor:Data",
            //   name: "string2",
            //   attributes: {
            //     additionalProp1: ["string"],
            //     additionalProp2: ["string"],
            //     additionalProp3: ["string"],
            //   },
            //   domainId: "sdfi",
            // };
            mainStore.createFieldOperationImported(jsonNew, params.pk);
            // fieldHistoryStore.UploadDataToMission(fake, params.pk); // Assuming 'importData' is the observable in 'mainStore'
            //     message.success(`File imported successfully`);
          } catch (error) {
            message.error(`Failed to parse JSON: ${error.message}`);
          }
        };
        reader.readAsText(file); // Read the file as text
      } else {
        message.error(`No file selected`);
      }
    }
  };
  useEffect(() => {
    console.log(usersStore.currentUser);
    if (
      usersStore.currentUser &&
      usersStore.currentUser.type &&
      (usersStore.currentUser.type.includes("field_operator") ||
        usersStore.currentUser.type.includes("mission_commander"))
    ) {
      setUser("field_operator");
    }
  }, [usersStore.currentUser]);

  let params = useParams();
  const currentDate = new Date();

  const formattedDate = currentDate.toISOString().slice(0, 19) + "+0000";

  const [selectedDrone, setSlectedDrone] = useState(
    JSON.parse(localStorage.getItem("drone"))
  );
  const [session, setSession] = useState(false);
  const [user, setUser] = useState();
  const [exportOperationtionCSV, SetExportOperationCSV] = useState([]);

  const goToSession = (e) => {
    navigate(
      "/mission/" + params.pk + "/field_operator/" + params.id + "/session/" + e
    );
  };

  const onSessionNameChange = (e) => {
    localStorage.setItem("session_name", e.target.value);
  };

  useEffect(() => {
    mainStore.GetDrones("86b8b6bd42ce110000000900");
    // fieldHistoryStore.GetArtefacts(params.pk);
    fieldHistoryStore.GetFieldArtefactsByOperation(params.pk, params.id);
    if (mainStore.sessions) {
      let arraySession = mainStore.sessions;
      let found = arraySession.find((session) => {
        return session.operation_id === params.id;
      });
      if (found) {
        setSessionStarted(true);
      } else {
        setSessionStarted(false);
      }
      console.log(sessionStarted);
    }
    // const handleResize = () => {
    //   if (window.innerHeight <= 790) {
    //     console.log(window.innerHeight);
    //     setBoxHeight(window.innerHeight * 0.5); // Adjust width based on new window width
    //     console.log(boxHeight);
    //   }
    //   if (window.innerHeight > 790 && window.innerHeight <= 940) {
    //     setBoxHeight(window.innerHeight * 0.57 - 30); // Adjust width based on new window width
    //     console.log(boxHeight);
    //   }
    //   if (window.innerHeight > 940 && window.innerHeight <= 1040) {
    //     setBoxHeight(window.innerHeight * 0.63 - 30); // Adjust width based on new window width
    //     console.log(boxHeight);
    //   }
    //   if (window.innerHeight > 1040) {
    //     setBoxHeight(window.innerHeight * 0.68 - 30); // Adjust width based on new window width
    //     console.log(boxHeight);
    //   }
    // };

    // window.addEventListener("resize", handleResize);

    // return () => {
    //   window.removeEventListener("resize", handleResize); // Clean up the event listener
    // };
  }, []);
  useEffect(() => {
    mainStore.GetDrones("86b8b6bd42ce110000000900");

    if (mainStore.sessions) {
      let arraySession = mainStore.sessions;
      let found = arraySession.find((session) => {
        return session.operation_id === params.id;
      });
      if (found) {
        setSessionStarted(true);
      } else {
        setSessionStarted(false);
      }
      console.log(sessionStarted);
    }
  }, [mainStore.sessions]);
  useEffect(() => {
    console.log("new session");
    fieldHistoryStore.GetFieldArtefactsByOperation(params.pk, params.id);
    if (mainStore.sessions) {
      let arraySession = mainStore.sessions;
      let found = arraySession.find((session) => {
        return session.operation_id === params.id;
      });
      if (found) {
        setSessionStarted(true);
      } else {
        setSessionStarted(false);
      }
      console.log(sessionStarted);
    }
    newContent("1");
  }, [params.id]);

  useEffect(() => {
    if (params.sessionId !== "realtime") {
      fieldHistoryStore.loadDataByParamSessionId(params.sessionId);
    }
    // newContent("1");
  }, [params.sessionId]);

  const onExportAll = () => {
    downloadJSON(_.cloneDeep(mainStore.session_data), `session.json`);
  };
  const handleDownload = () => {
    // const data = mainStore.exportData; // Assuming this is a string, object, or array
    mainStore.downloadOperationData(params.pk, params.id);
  };

  function downloadJSON(data, filename) {
    const jsonContent = JSON.stringify(data, null, 2);

    const encodedUri = encodeURI("data:text/json;charset=utf-8," + jsonContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);

    link.click();
  }

  const onClickStartOperation = () => {
    console.log(stopInternalId);
    stopInternalId && clearInterval(stopInternalId);

    // let session_id = props.ids.operationId[0];

    let session_id = uuidv4();
    mainStore.setSessionId(session_id);
    // if (!localStorage.getItem("field_sessions")) {
    //   console.log("no session found");
    //   localStorage.setItem("field_sessions", "[]");
    // } else {
    // }

    // let sessionsArray = JSON.parse(localStorage.getItem("field_sessions"));
    // console.log(sessionsArray);
    // sessionsArray.push({
    //   operation_id: params.id,
    //   session_id: session_id,
    // });
    let fields = {};
    fields.name = "session:" + session_id;
    fields.attributes = {
      session_id: [session_id],
      operation_id: [params.id],
    };
    fields.type = "session";
    mainStore.addSession(fields);
    let kafka_start_message = {
      header: {
        topicName: "FIELD_OPERATION_SESSION_ID",
        sender: "INTRA",
        sentUtc: formattedDate,
        source: "Dashboard",
        caseId: params.pk,
      },
      body: {
        enabled: true,
        operationId: params.id,
        sessionId: session_id,
      },
    };
    console.log(kafka_start_message);

    mainStore.Publish({
      topics: ["FIELD_OPERATION_SESSION_ID"],
      body: JSON.stringify(kafka_start_message),
    });

    var startId = setInterval(function () {
      mainStore.Publish({
        topics: ["FIELD_OPERATION_SESSION_ID"],
        body: JSON.stringify(kafka_start_message),
      });
    }, 30000);

    setstartInternalId(startId);
    console.log(startInternalId);
  };

  const onClickStopOperation = () => {
    console.log(startInternalId);
    startInternalId && clearInterval(startInternalId);

    let foundSessionId = "";
    let arraySession = [];

    if (mainStore.sessions) {
      arraySession = mainStore.sessions;

      let foundSession = arraySession.find((session) => {
        return session.operation_id === params.id;
      });
      if (foundSession) {
        foundSessionId = foundSession.session_id;
        console.log(foundSession);
        let kafka_stop_message = {
          header: {
            topicName: "FIELD_OPERATION_SESSION_ID",
            sender: "INTRA",
            sentUtc: formattedDate,
            source: "Dashboard",
            caseId: params.pk,
          },
          body: {
            enabled: false,
            sessionId: foundSessionId,
          },
        };

        mainStore.Publish({
          topics: ["FIELD_OPERATION_SESSION_ID"],
          body: JSON.stringify(kafka_stop_message),
        });

        var stopId = setInterval(function () {
          mainStore.Publish({
            topics: ["FIELD_OPERATION_SESSION_ID"],
            body: JSON.stringify(kafka_stop_message),
          });
        }, 30000);

        setstopInternalId(stopId);
        console.log(stopId);
        // mainStore.deleteSession();

        // let newArray = arraySession.filter((session) => {
        //   return session.operation_id !== params.id;
        // });

        setSessionStarted(false);
        // localStorage.setItem("field_sessions", JSON.stringify(newArray));

        mainStore.deleteSession(foundSession.id);
      }
    }
  };

  const onClickExportOperation = (record) => {
    let data = dataSource.filter((item) => item.key == record.key);
    SetExportOperationCSV(data);
  };

  const [reportModalData, setReportModalData] = useState(
    dataSource.filter((item) => item.key == props.id)[0] || []
  );

  const showModal = (record) => {
    record &&
      setReportModalData(
        dataSource.filter((item) => item.key == record.key)[0]
      );
    record && setIsModalOpen(true);
    setIsDronesModalOpen(true);
  };

  const onClickDrone = (item) => {
    setSlectedDrone(item);
    localStorage.setItem("drone", JSON.stringify(item));
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsDronesModalOpen(false);
    errorStore.setMessage("Drone Selected");
    errorStore.setInfo(true);
    setSession(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsDronesModalOpen(false);
    setSlectedDrone(null);
  };

  const onAnalyse = () => {
    errorStore.setMessage("Analyse Started");
    errorStore.setInfo(true);
  };

  const onStartOperationClick = (item) => {
    console.log(mainStore.drones);
    onClickDrone(mainStore.drones[0]);
    onClickStartOperation();

    setTimeout(() => {
      setSession(true);
    }, "1000");
  };

  const onChange = (info) => {
    if (info.file.status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const columnsUgvstatus = [
    {
      title: <p>Battery Voltage</p>,
      width: "auto",
      key: "battery_voltage",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="orange">{record.attributes.battery_voltage[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>GPS Altitude</p>,
      width: "auto",
      key: "gps_altitude",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="red">{record.attributes.gps_altitude[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>GPS Latitude</p>,
      width: "auto",
      key: "gps_latitude",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="gray">{record.attributes.gps_latitude[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>GPS Longitude</p>,
      width: "auto",
      key: "gps_longitude",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="green">{record.attributes.gps_longitude[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>Pitch</p>,
      width: "auto",
      key: "pitch",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="brown">{record.attributes.pitch[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>Pitch Rate y</p>,
      width: "auto",
      key: "pitch_rate_y",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="pink">{record.attributes.pitch_rate_y[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>Pos Altitude</p>,
      width: "auto",
      key: "pos_altitude",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="violet">{record.attributes.pos_altitude[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>Pos Latitude</p>,
      width: "auto",
      key: "pos_latitude",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="orange">{record.attributes.pos_latitude[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>Pos Longitude</p>,
      width: "auto",
      key: "pos_longitude",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="orange">{record.attributes.pos_longitude[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>rgb Stream</p>,
      width: "auto",
      key: "rgbStream",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="orange">{record.attributes.rgbStream[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>Roll</p>,
      width: "auto",
      key: "roll",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="green">{record.attributes.roll[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>Roll Rate x</p>,
      width: "auto",
      key: "roll_rate_x",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="yellow">{record.attributes.roll_rate_x[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>thermal Stream</p>,
      width: "auto",
      key: "thermalStream",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="green">{record.attributes.thermalStream[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>u speed</p>,
      width: "auto",
      key: "u_speed",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="gray">{record.attributes.u_speed[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>v speed</p>,
      width: "auto",
      key: "v_speed",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="yellow">{record.attributes.v_speed[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>w speed</p>,
      width: "auto",
      key: "w_speed",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="blue">{record.attributes.w_speed[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>yaw</p>,
      width: "auto",
      key: "yaw",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="green">{record.attributes.yaw[0]}</Tag>
        </p>
      ),
    },
    {
      title: <p>yaw rate z</p>,
      width: "auto",
      key: "yaw_rate_z",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="gray">{record.attributes.yaw_rate_z[0]}</Tag>
        </p>
      ),
    },
  ];

  const onChangeTASTab = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Air Sensor",
      children: (
        <TAS
          text="Air sensor data"
          TAS={mainStore.TAS}
          higher_TAS={mainStore.higher_TAS}
        />
      ),
    },
    {
      key: "2",
      label: "Water Sensor",
      children: (
        <TAS
          text="Water sensor data"
          TAS={mainStore.TASWater}
          higher_TAS={mainStore.higher_TASWater}
        />
      ),
    },
    {
      key: "3",
      label: "Config",
      children: <TAS_CONFIG />,
    },
    // {
    //   key: "3",
    //   label: "NTDL Sensor",
    //   children: (
    //     <TAS
    //       text="NTDL sensor data"
    //       TAS={mainStore.TASNtdl}
    //       higher_TAS={mainStore.higher_TASNtdl}
    //     />
    //   ),
    // },
  ];
  const itemsHistory = [
    {
      key: "1",
      label: "Air Sensor",
      children: (
        <TASHistory
          text="Air sensor data"
          TAS={fieldHistoryStore.higher_TAS}
          higher_TAS={fieldHistoryStore.higher_TAS}
        />
      ),
    },
    {
      key: "2",
      label: "Water Sensor",
      children: (
        <TASHistory
          text="Water sensor data"
          TAS={fieldHistoryStore.higher_TASWater}
          higher_TAS={fieldHistoryStore.higher_TASWater}
        />
      ),
    },
    {
      key: "3",
      label: "Config",
      children: <TAS_CONFIG />,
    },
    // {
    //   key: "3",
    //   label: "NTDL Sensor",
    //   children: (
    //     <TAS
    //       text="NTDL sensor data"
    //       TAS={mainStore.TASNtdl}
    //       higher_TAS={mainStore.higher_TASNtdl}
    //     />
    //   ),
    // },
  ];
  const columnsWaterSensorData = [
    {
      title: (
        <p>
          case <br /> temperature
        </p>
      ),
      width: "auto",
      key: "case_temperature",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="orange">{record.attributes.case_temperature[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          cell <br /> temperature
        </p>
      ),
      width: "auto",
      key: "cell_temperature",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="gray">{record.attributes.cell_temperature[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          device <br /> Error
        </p>
      ),
      width: "auto",
      key: "deviceError",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="red">{record.attributes.deviceError[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          device <br />
          Serial <br />
          Number
        </p>
      ),
      width: "auto",
      key: "deviceSerialNumber",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="gray">{record.attributes.deviceSerialNumber[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          device
          <br />
          Status
        </p>
      ),
      width: "auto",
      key: "deviceStatus",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="black">{record.attributes.deviceStatus[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          gas
          <br /> concentration <br />1
        </p>
      ),
      width: "auto",
      key: "gas_concentration_1",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="gray">{record.attributes.gas_concentration_1[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          gps
          <br />
          altitude
        </p>
      ),
      width: "auto",
      key: "gps_altitude",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="violet">{record.attributes.gps_altitude[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          gps
          <br />
          latitude
        </p>
      ),
      width: "auto",
      key: "gps_latitude",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="yellow">{record.attributes.gps_latitude[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          case
          <br />
          temperature
        </p>
      ),
      width: "auto",
      key: "case_temperature",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="brown">{record.attributes.case_temperature[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          gps
          <br />
          longitude
        </p>
      ),
      width: "auto",
      key: "gps_longitude",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="green">{record.attributes.gps_longitude[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          location
          <br />
          type
        </p>
      ),
      width: "auto",
      key: "location_type",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="green">{record.attributes.location_type[0]}</Tag>
        </p>
      ),
    },
    {
      title: (
        <p>
          measured
          <br />
          properties
        </p>
      ),
      width: "auto",
      key: "title",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="blue">
            {record.attributes.measured_properties.map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
          </Tag>
        </p>
      ),
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      responsive: ["lg", "sm", "md", "xs"],
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      responsive: ["lg", "sm", "md", "xs"],
      key: "title",
    },
    {
      title: "Date",
      dataIndex: "date",
      responsive: ["lg", "xl"],
      key: "title",
      render: (text) => (
        <p style={{ color: "#00703c" }}>
          <Tag color="orange">{text}</Tag>
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["lg", "xl"],
      key: "title",
      render: (value) => (
        <p style={{ color: "#00703c" }}>
          <Tag color={value == "completed" ? "green" : "red"}>{value}</Tag>
        </p>
      ),
    },
    {
      title: "Actions",
      responsive: ["lg", "sm", "md", "xs"],
      dataSource: "key",
      key: "action",
      width: "auto",
      render: (record) => (
        <Space>
          <Button
            size="small"
            type="primary"
            danger
            onClick={() => console.log(record)}
            style={{ width: "auto" }}
          >
            Delete
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => onAnalyse(record)}
            style={{ width: "auto" }}
          >
            {" "}
            Analyse
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => showModal(record)}
            style={{ width: "auto" }}
          >
            More Info
          </Button>
          <CSVLink
            data={exportOperationtionCSV}
            filename={"operation.csv"}
            target="_blank"
          >
            <Button
              size="small"
              type="default"
              onClick={() => onClickExportOperation(record)}
              style={{ width: "auto" }}
            >
              Export
            </Button>
          </CSVLink>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {mainStore.battery}
      <Row
        justify="center"
        align="center"
        //  className="full-viewport"
      >
        <Col span={24} lg={24} xl={24} xxl={24} xs={24} sm={24}>
          <div ref={componentRefField}>
            <Card style={{ width: "100%", marginTop: "24px" }}>
              <Row justify="end">
                <span style={{ marginRight: "8px" }}>
                  <Button
                    type="default" // Set the button type to "default" to get a white button
                    onClick={handleDownload} // Handle the click event
                    size="medium"
                    style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
                  >
                    <div align="center">
                      <span>
                        <ExportOutlined
                          style={{
                            fontSize: "20px",
                            marginRight: "4px",
                            color: "white",
                          }}
                        />
                      </span>
                      <span>Export Data</span>
                    </div>
                  </Button>
                </span>
                <span>
                  <Button
                    type="default" // Set the button type to "default" to get a white button
                    onClick={handlePrint} // Handle the click event
                    size="medium"
                    style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
                  >
                    <div align="center">
                      <span>
                        <PrinterOutlined />
                      </span>
                    </div>
                  </Button>
                </span>
              </Row>
              <Row justify="space-between" style={{ marginTop: "12px" }}>
                <span>
                  {" "}
                  {sessionStarted && (
                    <div>
                      <p>
                        <b style={{ fontWeight: "bold", color: "red" }}>
                          The Session has started
                        </b>{" "}
                        <div className="blinking-dots">....</div>
                        {/* {JSON.stringify(localStorage.getItem("session_id"))} */}
                      </p>
                    </div>
                  )}
                </span>
                <span>
                  <Row justify="space-between">
                    <span>
                      <span>Select session: </span>
                      <Select
                        onChange={(e) => {
                          goToSession(e);
                        }}
                        style={{ width: "140px", marginLeft: "16px" }}
                      >
                        {fieldHistoryStore.currentFieldOperationSessions.map(
                          (item, index) => {
                            return (
                              <Option key={index} value={item.value}>
                                {item.text}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </span>
                    <span>
                      {params.sessionId === "realtime" && (
                        <>
                          {user === "field_operator" &&
                            (!sessionStarted ? (
                              <Button
                                type="default"
                                style={{ color: "green", marginLeft: "10px" }}
                                onClick={() => onStartOperationClick()}
                                success
                              >
                                <Row align="center">
                                  <span>
                                    <Icon path={mdiPlay} size={1} />
                                  </span>
                                  <span>Start session</span>{" "}
                                </Row>
                              </Button>
                            ) : (
                              <Button
                                type="default"
                                onClick={() => onClickStopOperation()}
                                disabled={false}
                                danger
                              >
                                <Row align="center">
                                  <span>
                                    <Icon path={mdiStop} size={1} />
                                  </span>
                                  <span>Stop session</span>{" "}
                                </Row>
                              </Button>
                            ))}{" "}
                        </>
                      )}
                    </span>
                  </Row>
                </span>
              </Row>
              <Modal
                title="Field Operation"
                open={isDronesModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <TextArea
                  showCount
                  rows={1}
                  maxLength={30}
                  size="large"
                  placeholder="Field operation name"
                  onChange={onSessionNameChange}
                ></TextArea>

                <Divider>Available drones</Divider>
                {mainStore.drones != []
                  ? mainStore.drones.map((item, index) => {
                      return (
                        <div key={index}>
                          {item.name}
                          <Radio
                            checked={
                              selectedDrone != null &&
                              item.id == selectedDrone.id
                                ? true
                                : false
                            }
                            onClick={() => onClickDrone(item)}
                          ></Radio>{" "}
                          <br />
                        </div>
                      );
                    })
                  : "There are none available drones"}
              </Modal>
              <Modal
                title="Field Operation session information"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                {reportModalData != [] ? (
                  <>
                    <p>
                      <b>Name:</b> {reportModalData.name}
                    </p>
                    <p>
                      <b>Description:</b> {reportModalData.description}
                    </p>
                    <p>
                      {" "}
                      <b>Date:</b> {reportModalData.date}
                    </p>
                    <p>
                      <b>Criticality:</b>{" "}
                      <Tag
                        color={
                          reportModalData.criticality < 5
                            ? "green"
                            : reportModalData.criticality <= 7
                            ? "orange"
                            : "red"
                        }
                      >
                        {reportModalData.criticality}
                      </Tag>
                    </p>
                  </>
                ) : (
                  "No data"
                )}
              </Modal>
              <Tabs
                style={{ marginTop: "-20px" }}
                // defaultActiveKey="1"
                onChange={newContent}
                activeKey={activeKey}
                tabPosition="top"
              >
                <TabPane tab={<span>UGV drone</span>} key="1"></TabPane>
                <TabPane tab={<span>UAV drone</span>} key="2"></TabPane>
                <TabPane tab={<span>TAS component</span>} key="3"></TabPane>
                <TabPane tab={<span>ATDL</span>} key="4"></TabPane>
              </Tabs>

              <div
                style={{
                  marginTop: "-20px",
                  // border: "1px black solid",
                  // overflowY: "auto",
                  // height: boxHeight,
                  width: "100%",
                }}
              >
                {tabContent === "1" && (
                  <div>
                    <Row
                      justify="center"
                      // align="top"
                      gutter={[20, 20]}
                      style={{
                        padding: "10px",
                        // background: "whitesmoke",
                        background: "white",
                        height: "380px",
                      }}
                    >
                      <Col span={12}>
                        <Divider orientation="center">Drone on Map </Divider>
                        {params.sessionId === "realtime" ? (
                          <UGVMap />
                        ) : (
                          <UGVMapHistory />
                        )}
                      </Col>
                      <Col span={12}>
                        <Divider orientation="center">
                          Real time video stream{" "}
                        </Divider>
                        <VideoPlayer />
                      </Col>
                    </Row>

                    <div
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <Row
                        // justify="center"
                        // align="top"
                        gutter={[20, 20]}
                        style={{
                          marginTop: "20px",
                          padding: "10px",
                          // background: "whitesmoke",
                          background: "white",
                          height: "580px",
                        }}
                      >
                        <Col span={24}>
                          {params.sessionId === "realtime" ? (
                            <WaterSensorPeaks />
                          ) : (
                            <WaterSensorPeaksHistory />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                {tabContent === "2" && (
                  <Row style={{ padding: "10px", background: "whitesmoke" }}>
                    <Col span={24}>
                      <Row justify="center" align="top" gutter={[20, 20]}>
                        <Col span={12}>
                          <Divider orientation="center">UAV on map </Divider>

                          {params.sessionId === "realtime" ? (
                            <UAVMap />
                          ) : (
                            <UAVMapHistory />
                          )}
                        </Col>

                        <Col span={12}>
                          <Divider orientation="center">
                            Real time video stream{" "}
                          </Divider>
                          <VideoPlayerUAV />
                        </Col>

                        {/* <Col span={12}>
                        <AirSensorData />
                      </Col> */}
                        <Col span={24}>
                          {params.sessionId === "realtime" ? (
                            <AirSensorPeaks />
                          ) : (
                            <AirSensorPeaksHistory />
                          )}
                        </Col>

                        {/* <Col span={24}>
                        {" "}
                        <AirSensorPeaks />{" "}
                      </Col> */}
                      </Row>
                    </Col>
                  </Row>
                )}
                {tabContent === "3" && (
                  <Row style={{ padding: "10px", background: "whitesmoke" }}>
                    <Col span={24}>
                      {params.sessionId === "realtime" ? (
                        <Row gutter={[20, 20]}>
                          <Col span={12}>
                            <Divider orientation="center">TAS on map </Divider>
                            <TASMap />
                          </Col>
                          <Col span={12}>
                            <Tabs
                              defaultActiveKey="1"
                              items={items}
                              onChange={onChangeTASTab}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row gutter={[20, 20]}>
                          <Col span={12}>
                            <Divider orientation="center">TAS on map </Divider>
                            <TASMapHistory />
                          </Col>
                          <Col span={12}>
                            <Tabs
                              defaultActiveKey="1"
                              items={itemsHistory}
                              onChange={onChangeTASTab}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                )}
                {tabContent === "4" && (
                  <div>
                    <Row style={{ padding: "10px", background: "whitesmoke" }}>
                      <div
                        style={{
                          display: "flex",
                          // alignItems: "center", // Center vertically
                          justifyContent: "center",
                          height: "400px",
                          width: "100%",
                        }}
                      >
                        <MapATD></MapATD>
                      </div>
                    </Row>
                  </div>
                )}
              </div>
            </Card>{" "}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default observer(FieldOperator);
