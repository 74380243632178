import { makeAutoObservable } from "mobx";
import Map from "../components/map/Map";
import Reports from "../components/reports/Reports";
import Dashboard from "../pages/Dashboard";
import Drone from "../components/drone/Drone";
import Audit from "../components/audit/Audit";
import Profile from "../components/profile/Profile";
import { WebSocketDemo } from "../components/webSocket/WebSocket";
import Admin from "../components/admin/Admin";
import VisualAnalisysPage from "../components/visual-analysis/VisulaAnalysisPage";
import SocialNetwork from "../components/socilaNetwork/SocialNtework";
import VideoPlayer from "../components/videoPlayer/VideoPlayer";
import { constants } from "../consts/Variables";

class ComponentsStore {
  constructor(props) {
    makeAutoObservable(this);
  }

  component = `${constants.ONLINE}` == "true" ? <Dashboard /> : <Drone />;

  SetCurrentleyComponent = () => {
    localStorage.getItem("c_k") &&
      this.setComponent({ key: localStorage.getItem("c_k") });
  };

  setComponent = (e) => {
    if (`${constants.ONLINE}` == "true") {
      switch (e.key) {
        case "1":
          return (this.component = <Dashboard />);
        case "3":
          return (this.component = null);
        case "7":
          return (this.component = (
            <Reports id={e.id} openModal={e.openModal} />
          ));
        case "8":
          return (this.component = <Map />);
        case "16":
          return (this.component = <Drone />);
        case "17":
          return (this.component = <Audit />);
        case "18":
          return (this.component = <Profile />);
        case "19":
          return (this.component = <WebSocketDemo />);
        case "20":
          return (this.component = <Admin />);
        case "26":
          return (this.component = <VisualAnalisysPage />);
        case "27":
          return (this.component = <SocialNetwork />);
        case "28":
          return (this.component = <VideoPlayer />);
        case "22":
        default:
          return (this.component = <Dashboard />);
      }
    } else {
      switch (e.key) {
        case "20":
          return (this.component = <Admin />);
        case "16":
          return (this.component = <Drone />);
        case "18":
          return (this.component = <Profile />);
        default:
          return (this.component = <Drone />);
      }
    }
  };
}

export default ComponentsStore;
