import React, { useState, useEffect } from "react";
import { Col, Row, Divider, Button, Image, Card, Modal, Tag } from "antd";
import Loader from "../loader/Loader";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { getOverridedColumns } from "../../utils/tableLoader";

var _ = require("lodash");

const VisualAnalisysPage = (props) => {
  const [analysis, setAnalysis] = useState(false);
  const { mainStore, errorStore } = useMobxStores();
  const [isModalOpen, setIsModalOpen] = useState(props.openModal);
  const [content, setContent] = useState("");
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {}, [mainStore.UV_data]);

  console.log(mainStore.visual_images);

  const onMoreData = () => {
    setPageSize(pageSize + 10);
    mainStore.GetVU("86b8b6bd42ce110000000900", pageSize);
  };

  useEffect(() => {
    mainStore.GetVU("86b8b6bd42ce110000000900", pageSize);
  }, []);

  const handleModalOk = () => {
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleModalopen = (content) => {
    setIsModalOpen(true);
    setContent(content);
  };

  const onClickMedia = (id, media) => {
    if (Object.keys(media).length !== 0) {
      mainStore.GetVisualImages(id, media);
      setAnalysis(true);
    } else {
      errorStore.setWarning(true);
      errorStore.setMessage("No media for Visualization");
    }
  };

  const handleOk = () => {
    setAnalysis(false);
  };

  const handleCancel = () => {
    setAnalysis(false);
  };

  const columns = [
    {
      title: "Title",
      responsive: ["lg", "sm", "md", "xs"],
      key: "tags",
      render: (record) => (
        <p style={{ color: "black" }}>{record && record.title}</p>
      ),
    },
    {
      title: "Id",
      responsive: ["lg", "sm", "md", "xs"],
      render: (record) => (
        <p style={{ color: "black" }}>{record && record.id}</p>
      ),
    },
    {
      title: "Created",
      responsive: ["lg", "sm", "md", "xs"],
      render: (record) => (
        <Tag style={{ color: "#00703c" }}>{record && record.created}</Tag>
      ),
    },
    {
      title: "Content",
      responsive: ["lg", "sm", "md", "xs"],
      render: (record) => (
        <Button type="primary" onClick={() => handleModalopen(record.content)}>
          Content
        </Button>
      ),
    },
    {
      title: "",
      responsive: ["lg", "sm", "md", "xs"],
      render: (record) => (
        <Button
          disabled={Object.keys(record.media).length == 0 ? true : false}
          type="primary"
          onClick={() => onClickMedia(record.media)}
        >
          Media
        </Button>
      ),
    },
  ];

  return (
    <Row justify="center" align="center" className="full-viewport">
      <Col span={24} lg={24} xs={24} sm={24}>
        <Divider orientation="left">Visual Analysis </Divider>
        <div>
          <Divider orientation="left">
            Available Images for visual analysis
          </Divider>
          {mainStore.UV_data.length ? (
            <div>
              {mainStore.UV_data.map((item, index) => {
                return (
                  <div key={index}>
                    <Card
                      headStyle={{
                        color: "white",
                        backgroundColor: "blue",
                        textAlign: "left",
                      }}
                      title={
                        <Row
                          justify={"center"}
                          align={"top"}
                          gutter={[20, 20]}
                          style={{
                            height: "100%",
                          }}
                        >
                          <Col
                            span={20}
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {item.title}
                          </Col>{" "}
                          <Col
                            span={4}
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {item.name}
                          </Col>
                        </Row>
                      }
                      actions={[
                        <Button
                          disabled={
                            Object.keys(item.media).length == 0 ? true : false
                          }
                          type="primary"
                          onClick={() => onClickMedia(item.id, item.media)}
                        >
                          Media
                        </Button>,
                      ]}
                    >
                      <Row
                        justify={"center"}
                        align={"top"}
                        gutter={[20, 20]}
                        style={{
                          backgroundColor: "whitesmoke",
                          height: "100%",
                        }}
                      >
                        <Col
                          span={20}
                          style={{
                            textAlign: "justify",
                            backgroundColor: "white",
                          }}
                        >
                          {item.content}
                        </Col>
                        <Col
                          style={{
                            backgroundColor: "whitesmoke",
                            height: "100%",
                          }}
                          span={4}
                        >
                          {item.created}
                        </Col>
                      </Row>
                    </Card>
                    <Divider />
                  </div>
                );
              })}
              <Button
                style={{ marginLeft: "45%" }}
                type="primary"
                onClick={() => onMoreData()}
                loading={errorStore.loading}

              >
                More Data ({mainStore.UV_data.length})
              </Button>
            </div>
          ) : (
            <Loader />
          )}

          {/* <Table
            dataSource={
              mainStore.UV_data.length
                ? _.cloneDeep(mainStore.UV_data)
                : Array(5).fill({})
            }
            columns={
              mainStore.UV_data.length ? columns : getOverridedColumns(columns)
            }
            rowKey={() => uuidv4()}
            scroll={{ x: "400px" }}
          /> */}
        </div>
      </Col>
      <Modal
        title="Content"
        open={isModalOpen}
        onOk={handleModalOk}
        onCancel={handleModalOk}
      >
        <p>{content != "" ? content : "Not Content"}</p>
      </Modal>
      <Modal
        title={"Visual Analysis Images"}
        open={analysis}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ alignItems: "center", textAlign: "center" }}
        width={"50%"}
      >
        <Row justify="center" align="middle">
          <Col>
            {errorStore.loading ? (
              <Loader />
            ) : (
              <div>
                {mainStore.visual_images.map((item) => {
                  return (
                    <>
                      <Image width={"100%"} src={item} /> <Divider />
                    </>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>
      </Modal>
    </Row>
  );
};

export default observer(VisualAnalisysPage);
