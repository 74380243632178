import React from "react";
import ErrorStore from "./ErrorStore";
import MainStore from "./MainStore";
import FieldHistoryStore from "./FieldHistoryStore";
import WebCrawlStore from "./WebCrawlStore";
import TextStore from "./TextStore";
import UserStore from "./UserStore";
import UsersStore from "./UsersStore";
import ComponentsStore from "./ComponentsStore";
import MissionsStore from "./MissionsStore";
import TransactionsStore from "./TransactionsStore";
import WebSocialMediaStore from "./WebSocialMediaStore";

const isServer = typeof window === "undefined";
let clientSideStores;

export function getStores() {
  var errorStore = new ErrorStore();
  var webSocialMediaStore = new WebSocialMediaStore({ errStore: errorStore });
  if (isServer) {
    return {
      errorStore: errorStore,
      transactionsStore: new TransactionsStore({ errStore: errorStore }),
      mainStore: new MainStore({ errStore: errorStore }),
      fieldHistoryStore: new FieldHistoryStore({ errStore: errorStore }),
      textStore: new TextStore({ errStore: errorStore }),
      webCrawlStore: new WebCrawlStore({
        errStore: errorStore,
        webSocialMediaStore: webSocialMediaStore,
      }),
      webSocialMediaStore: webSocialMediaStore,
    };
  }
  if (!clientSideStores) {
    clientSideStores = {
      errorStore: errorStore,
      transactionsStore: new TransactionsStore({ errStore: errorStore }),
      mainStore: new MainStore({ errStore: errorStore }),
      fieldHistoryStore: new FieldHistoryStore({ errStore: errorStore }),
      textStore: new TextStore({ errStore: errorStore }),
      webCrawlStore: new WebCrawlStore({
        errStore: errorStore,
        webSocialMediaStore: webSocialMediaStore,
      }),
      webSocialMediaStore: webSocialMediaStore,
      userStore: new UserStore({ errStore: errorStore }),
      usersStore: new UsersStore({ errStore: errorStore }),
      componentStore: new ComponentsStore({ errStore: errorStore }),
      missionsStore: new MissionsStore({ errStore: errorStore }),
    };
  }

  return clientSideStores;
}

const StoreContext = React.createContext();

export function StoreProvider(props) {
  return (
    <StoreContext.Provider value={props.value}>
      {props.children}
    </StoreContext.Provider>
  );
}

export function useMobxStores() {
  return React.useContext(StoreContext);
}
