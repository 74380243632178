import React, { useEffect } from "react";
import { Row, Col, Spin } from "antd";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.token !== null) {
      navigate("/");
    }
  }, [props.token]);

  useEffect(() => {
    if (props.token == null) {
      props.onLogin();
    }
  }, [props.token]);

  return (
    <Row justify="center" align="middle" style={{ height: "300px" }}>
      <Row justify="center" align="middle">
        <Col span={4}>
          <Spin tip="Loading" size="large" />
        </Col>
      </Row>
    </Row>
  );
};

export default Login;
