import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Space,
  Modal,
  Collapse,
  Drawer,
  Divider,
} from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import ETDMap from "./ETDMap";
import TransactionsTable from "./TransactionsTable";
import { Tabs } from "antd";
const { TabPane } = Tabs;

var _ = require("lodash");

const { Panel } = Collapse;

const TransactionsETD = (props) => {
  const { mainStore } = useMobxStores();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isItemsModalOpen, setItemsIsModalOpen] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const [shownClusterId, setShownClusterId] = useState("");

  const [coords, setCoords] = useState([]);
  const [transactionsData, setTransactionsData] = useState([]);
  const [tabContent, setTabContent] = useState("1");
  const newContent = (key) => {
    setTabContent(key);
  };

  const recipes_options = [
    { name: "tatp", value: mainStore.transactionsETD.tatp },
    { name: "tnt", value: mainStore.transactionsETD.tnt },
    { name: "flash powder", value: mainStore.transactionsETD.flash_powder },
    { name: "hmtd", value: mainStore.transactionsETD.hmtd },
    { name: "black powder", value: mainStore.transactionsETD.black_powder },
    {
      name: "mercury fulminate",
      value: mainStore.transactionsETD.mercury_fulminate,
    },
    { name: "anfo hydrazine", value: mainStore.transactionsETD.anfo_hydrazine },
    { name: "anfo", value: mainStore.transactionsETD.anfo },
  ];

  useEffect(() => {
    // console.log(_.cloneDeep(mainStore.transactionsETD));
    recipes_options.map((recipe) => {
      if (recipe.value) {
        {
          Object.entries(recipe.value).map((value) => {
            return value.map((cluster, index) => {
              if (cluster.centroid) {
                coords.push({
                  lon: cluster.centroid[0],
                  lat: cluster.centroid[1],
                });
              }
            });
          });
        }
      }
    });
  }, [mainStore.transactionsETD]);

  const showModal = (record) => {
    // setReportModalData(record);
    console.log(record);
    console.log(record.transactions);
    const keysArray = Object.values(record.transactions);
    console.log(keysArray);
    let temp = [];

    keysArray.map((item) => {
      // console.log(item);
      temp.push(item);
    });

    let transactionD = [];
    Object.entries(record.transactions).map((item) => {
      transactionD.push(item[1]);
    });
    setTransactionsData(transactionD);
    setShownClusterId(record.properties.clusterId);
    console.log(temp);
    // setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showItemsModal = (record) => {
    console.log(record);
    setItemsData(record.items);
    setItemsIsModalOpen(true);
  };

  const handleItemsModalOk = () => {
    setItemsIsModalOpen(false);
  };
  const handleItemsModalCancel = () => {
    setItemsIsModalOpen(false);
  };

  let user = window.localStorage.getItem("user");
  user = JSON.parse(user);

  const itemColumns = [
    {
      title: "classificationViolation",
      responsive: ["lg", "sm", "md", "xs"],
      key: "classificationViolation",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.classificationViolation ? "true" : "false"}</>
        </p>
      ),
    },
    {
      title: "concentration",
      responsive: ["lg", "sm", "md", "xs"],
      key: "concentration",
      dataIndex: "concentration",
    },
    {
      title: "name",
      responsive: ["lg", "sm", "md", "xs"],
      key: "name",
      dataIndex: "name",
    },
    {
      title: "precursorName",
      responsive: ["lg", "sm", "md", "xs"],
      key: "precursorName",
      dataIndex: "precursorName",
    },
    {
      title: "price",
      responsive: ["lg", "sm", "md", "xs"],
      key: "price",
      dataIndex: "price",
    },
    {
      title: "quantity",
      responsive: ["lg", "sm", "md", "xs"],
      key: "quantity",
      dataIndex: "quantity",
    },
    {
      title: "quantityViolation",
      responsive: ["lg", "sm", "md", "xs"],
      key: "quantityViolation",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.quantityViolation ? "true" : "false"}</>
        </p>
      ),
    },
    {
      title: "regulationViolation",
      responsive: ["lg", "sm", "md", "xs"],
      key: "regulationViolation",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.regulationViolation ? "true" : "false"}</>
        </p>
      ),
    },
    {
      title: "totalquantity",
      responsive: ["lg", "sm", "md", "xs"],
      key: "totalquantity",
      dataIndex: "totalquantity",
    },
    {
      title: "unitsize",
      responsive: ["lg", "sm", "md", "xs"],
      key: "unitsize",
      dataIndex: "unitsize",
    },
    {
      title: "iscomponentof",
      responsive: ["lg", "sm", "md", "xs"],
      key: "iscomponentof",
      render: (record) =>
        record.iscomponentof.map((item) => {
          return <li>{item}</li>;
        }),
    },
  ];

  const transactions = [
    {
      title: "buyer",
      responsive: ["lg", "sm", "md", "xs"],
      key: "buyer",
      dataIndex: "buyer",
    },
    {
      title: "buyerlicence",
      responsive: ["lg", "sm", "md", "xs"],
      key: "buyerlicence",
      dataIndex: "buyerlicence",
    },
    {
      title: "cardNumber",
      responsive: ["lg", "sm", "md", "xs"],
      key: "cardNumber",
      dataIndex: "cardNumber",
    },
    {
      title: "country",
      responsive: ["lg", "sm", "md", "xs"],
      key: "country",
      dataIndex: "country",
    },
    {
      title: "hash",
      responsive: ["lg", "sm", "md", "xs"],
      key: "hash",
      dataIndex: "hash",
    },
    {
      title: "id",
      responsive: ["lg", "sm", "md", "xs"],
      key: "id",
      dataIndex: "id",
    },
    {
      title: "latitude",
      responsive: ["lg", "sm", "md", "xs"],
      key: "latitude",
      dataIndex: "latitude",
    },
    {
      title: "longitude",
      responsive: ["lg", "sm", "md", "xs"],
      key: "longitude",
      dataIndex: "longitude",
    },
    {
      title: "report",
      responsive: ["lg", "sm", "md", "xs"],
      key: "report",
      dataIndex: "report",
    },
    {
      title: "seller",
      responsive: ["lg", "sm", "md", "xs"],
      key: "seller",
      dataIndex: "seller",
    },
    {
      title: "suspicious",
      responsive: ["lg", "sm", "md", "xs"],
      key: "suspicious",
      dataIndex: "suspicious",
    },
    {
      title: "timedate",
      responsive: ["lg", "sm", "md", "xs"],
      key: "timedate",
      dataIndex: "timedate",
    },
    {
      title: "transactionType",
      responsive: ["lg", "sm", "md", "xs"],
      key: "transactionType",
      dataIndex: "transactionType",
    },
    {
      title: "items",
      responsive: ["lg", "sm", "md", "xs"],
      key: "items",
      render: (record) => {
        // console.log(record);
        return (
          <Button
            size="small"
            key={record + record}
            type="primary"
            onClick={() => showItemsModal(record)}
            disabled={false}
          >
            Transaction Items
          </Button>
        );
      },
    },
  ];

  const clusterColumns = [
    {
      title: "Cluster Id",
      responsive: ["lg", "sm", "md", "xs"],
      key: "clusterId",
      align: "center",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.properties.clusterId}</>
        </p>
      ),
    },
    {
      title: "confidence",
      responsive: ["lg", "xl"],
      key: "Buyer",
      align: "center",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.properties.confidence}</>
        </p>
      ),
    },
    {
      title: "count",
      responsive: ["lg", "xs"],
      key: "title",
      align: "center",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.properties.count}</>
        </p>
      ),
    },
    {
      title: "description",
      responsive: ["lg", "sm", "md", "xs"],
      key: "title",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <>{record && record.properties.description}</>
        </p>
      ),
    },
    {
      title: "limit",
      responsive: ["lg", "xl"],
      key: "title",
      align: "center",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.properties.limit}</>
        </p>
      ),
    },
    {
      title: "transactions",
      responsive: ["lg", "sm", "md", "xs"],
      key: "action",
      width: "auto",
      align: "center",
      render: (record) => {
        // let transactionData = [];
        // Object.entries(record.transactions).map((item) => {
        //   transactionData.push(item[1]);
        // });
        // return (
        //   <Space direction="vertical">
        //     <Drawer
        //       title="Transactions"
        //       onClose={handleCancel}
        //       width={"100%"}
        //       open={isModalOpen}
        //       zIndex={{ zIndex: 999 }}
        //     >
        //       <Table
        //         rowKey={() => uuidv4()}
        //         bordered={true}
        //         scroll={{ x: "400px" }}
        //         dataSource={transactionData}
        //         columns={transactions}
        //       />
        //     </Drawer>
        //     <Button
        //       size="small"
        //       key={record + record}
        //       type="primary"
        //       onClick={() => showModal(record)}
        //       style={{ width: "100px" }}
        //       disabled={false}
        //     >
        //       transactions
        //     </Button>
        //   </Space>
        // );
      },
    },
  ];

  function isOdd(number) {
    return Math.floor(number / 2) * 2 !== number;
  }

  return (
    <div>
      {transactionsData.length > 0 && (
        <div>
          <TransactionsTable
            onClick={(data) => {
              props.onClick(data);
            }}
            onGoToClusters={() => {
              setTransactionsData([]);
            }}
            transactions={transactionsData}
            clusterId={shownClusterId}
          />
        </div>
      )}
      {transactionsData.length === 0 && (
        <div>
          {" "}
          <Row>
            <span>Clusters found</span>
            <span style={{ marginLeft: "400px", marginTop: "-12px" }}>
              <Tabs
                defaultActiveKey="1"
                onChange={newContent}
                tabPosition="top"
              >
                <TabPane
                  tab={
                    <span>
                      {/* <FileTextOutlined
                        style={{ width: "22px", height: "22px" }}
                      /> */}
                      List View
                    </span>
                  }
                  key="1"
                ></TabPane>
                <TabPane
                  tab={
                    <span>
                      {/* <Icon path={mdiTranslate} size={1} /> */}
                      Map View
                    </span>
                  }
                  key="2"
                ></TabPane>
              </Tabs>
            </span>
          </Row>{" "}
          {tabContent === "2" && (
            <ETDMap onShowModalFromMap={(cluster) => showModal(cluster)} />
          )}
          {tabContent === "1" && (
            <div
              style={{
                border: "1px black solid",
                overflowY: "auto",
                height: "240px",
                width: "100%",
              }}
            >
              <Drawer
                title="Transactions"
                onClose={handleCancel}
                width={"100%"}
                open={isModalOpen}
                zIndex={{ zIndex: 999 }}
              >
                <Table
                  rowKey={() => uuidv4()}
                  bordered={true}
                  scroll={{ x: "400px" }}
                  dataSource={transactionsData}
                  columns={transactions}
                />
              </Drawer>
              <Modal
                width={"100%"}
                open={isItemsModalOpen}
                onCancel={handleItemsModalCancel}
                onOk={handleItemsModalOk}
                title={"Transactions Items"}
                zIndex={100000}
              >
                <Table
                  rowKey={() => uuidv4()}
                  bordered={true}
                  scroll={{ x: "400px" }}
                  dataSource={itemsData}
                  columns={itemColumns}
                />
              </Modal>

              {mainStore.transactionsETD != [] && (
                <Row justify="center" align="center">
                  <Col span={24} md={24} lg={24} xs={24} sm={24} xxl={24}>
                    {_.cloneDeep(recipes_options).map((recipe, recipeIndex) => {
                      return (
                        <div style={{ marginTop: "4px" }}>
                          {/* <Collapse defaultActiveKey={[]}> */}
                          <div header={recipe.name} key={recipeIndex}>
                            {recipe.value ? (
                              <div>
                                {Object.entries(recipe.value).map((value) => {
                                  return value.map((cluster, index) => (
                                    <div key={index}>
                                      {cluster && cluster.properties && (
                                        <div>
                                          <Row justify="center">
                                            <span
                                              style={{ fontWeight: "bold" }}
                                            >
                                              cluster id:
                                            </span>
                                            <span>
                                              {cluster.properties.clusterId}
                                            </span>
                                          </Row>
                                          <Divider
                                            style={{
                                              marginTop: "-2px",
                                              marginBottom: "-2px",
                                            }}
                                          ></Divider>
                                          <Row justify="space-between">
                                            <span>
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  marginLeft: "20px",
                                                }}
                                              >
                                                recipe:
                                              </span>
                                              <span>{recipe.name}</span>
                                            </span>
                                            <span>
                                              <span
                                                style={{ fontWeight: "bold" }}
                                              >
                                                confidence:
                                              </span>
                                              <span>
                                                {Number(
                                                  cluster.properties.confidence
                                                ).toFixed(2)}
                                              </span>
                                            </span>

                                            <span>
                                              <span
                                                style={{ fontWeight: "bold" }}
                                              >
                                                count:
                                              </span>
                                              <span>
                                                {cluster.properties.count}
                                              </span>
                                            </span>
                                            <span
                                              style={{
                                                marginRight: "20px",
                                              }}
                                            >
                                              <Button
                                                type="default" // Set the button type to "default" to get a white button
                                                size="medium"
                                                onClick={() =>
                                                  showModal(cluster)
                                                }
                                                style={{
                                                  backgroundColor: "#354289",
                                                  color: "white",
                                                  marginLeft: "6px",
                                                }} // Set the background color to white and font color to blue
                                              >
                                                <Row align="center">
                                                  <span></span>
                                                  <span>
                                                    View transactions
                                                  </span>{" "}
                                                </Row>
                                              </Button>
                                            </span>
                                          </Row>
                                          <Divider
                                            style={{
                                              marginTop: "-2px",
                                              marginBottom: "-2px",
                                            }}
                                          ></Divider>
                                          <Row justify="start">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                marginLeft: "20px",
                                              }}
                                            >
                                              description:
                                            </span>
                                            <span>
                                              <div>
                                                {cluster.properties.description}{" "}
                                              </div>
                                            </span>
                                          </Row>
                                          <Divider
                                            style={{
                                              marginTop: "-2px",
                                              marginBottom: "-2px",
                                            }}
                                          ></Divider>
                                          <Row justify="start">
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                marginLeft: "20px",
                                              }}
                                            >
                                              limit:
                                            </span>
                                            <span>
                                              <div>
                                                {cluster.properties.limit}{" "}
                                              </div>
                                            </span>
                                          </Row>
                                          <Divider
                                            style={{
                                              "background-color": "#90A4AE",

                                              // borderTop: "2px #B0BEC5 solid",
                                              // borderRadius: "6px",
                                              marginBottom: "-3px",
                                              marginTop: "-3px",
                                            }}
                                          ></Divider>
                                        </div>

                                        // <div>{cluster.properties.count}</div>
                                      )}
                                    </div>
                                  ));
                                })}
                              </div>
                            ) : (
                              "No data found"
                            )}
                          </div>
                          {/* </Collapse> */}
                        </div>
                      );
                    })}
                  </Col>
                  {/* <Col span={12} md={12} lg={12} xs={12} sm={12} xxl={12}>
            <ETDMap />
          </Col> */}
                </Row>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(TransactionsETD);
