import React, { useEffect, useState, useSyncExternalStore } from "react";
import { Modal, Button, Form, Input, Select, Row, Col, Divider } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;

const CreateOperation = (props) => {
  const { missionsStore } = useMobxStores();
  const { mainStore, usersStore } = useMobxStores();
  const [operation_type, setOperationType] = useState(null);
  const [social_media, setSocialMedia] = useState(null);

  const [web_analysts, set_web_analysts] = useState([]);
  const [webAnalystsAssignees, setWebAnalystsAssignees] = useState([]);

  const [allUsers, setAllUsers] = useState([]);
  const [transaction_analysts, set_transaction_analysts] = useState([]);
  const [transactionAnalystsAssignees, setTransactionAnalystsAssignees] =
    useState([]);
  const [field_operators, set_field_operators] = useState([]);
  const [fieldOperatorsAssignees, setFieldOperatorsAssignees] = useState([]);

  useEffect(() => {
    usersStore.GetAllUsers();

    // usersStore.FieldOperators();
  }, []);

  useEffect(() => {
    set_web_analysts(
      usersStore.allUsers
        .filter((user) => {
          return user.attributes.webanalyst[0] === "true";
        })
        .map((user) => {
          return { value: user.name, label: user.name };
        })
    );
    set_transaction_analysts(
      usersStore.allUsers
        .filter((user) => {
          return user.attributes.transaction_analyst[0] === "true";
        })
        .map((user) => {
          return { value: user.name, label: user.name };
        })
    );
    set_field_operators(
      usersStore.allUsers
        .filter((user) => {
          return user.attributes.field_operator[0] === "true";
        })
        .map((user) => {
          return { value: user.name, label: user.name };
        })
    );
  }, [usersStore.allUsers]);

  const [create_operation_form] = Form.useForm();

  const onCreateOperation = (fields) => {
    let newUuid = uuidv4();
    let newfields = fields;

    if (fields.type === "web_crawl") {
      newfields.attributes = {
        operationId: [newUuid],
        assignee: webAnalystsAssignees,
      };
    }
    if (fields.type === "transaction analysis") {
      newfields.attributes = {
        operationId: [newUuid],
        assignee: transactionAnalystsAssignees,
      };
    }
    if (fields.type === "field") {
      newfields.attributes = {
        operationId: [newUuid],
        assignee: fieldOperatorsAssignees,
      };
    }

    return missionsStore.AddOperationToMission(newfields).then(() => {
      console.log("collectionStart");
      if (fields.type === "web_crawl" && social_media === "dark_web") {
        let correct = {
          type: "Web",
          status: "Adhoc",
          projectId: props.projectId,
          endpointRunStatus: "Waiting",
          nextScheduled: "2023-04-18T09:30:00.000Z",
          expiresAfter: "2023-04-20T09:30:00.000Z",
          maxTaskInterval: 10,
          minTaskInterval: 5,
          depth: fields.depth,
          attributes: {
            assignee: [fields.web_analyst].flat(),
            urls: [fields.url],
            protocol: ["Web"],
            operationId: [newUuid],
            depth: [fields.depth],
          },
        };
        mainStore.StartWebDataCollection(correct);
      }

      if (
        fields.type === "web_crawl" &&
        social_media === "RedditSubredditScrape"
      ) {
        let correct = {
          type: "RedditSubredditScrape",
          status: "Adhoc",
          projectId: props.projectId,
          endpointRunStatus: "Waiting",
          nextScheduled: "2023-04-18T09:30:00.000Z",
          expiresAfter: "2023-04-20T09:30:00.000Z",
          maxTaskInterval: 10,
          minTaskInterval: 5,
          depth: fields.depth,
          attributes: {
            urls: [fields.url],
            assignee: [fields.web_analyst].flat(),
            // protocol: ["Web"],
            operationId: [newUuid],
            depth: [fields.depth],
            keyword_search: [fields.keywords],
            keyword_search_type: [fields.redditSearchType],
          },
        };
        mainStore.StartWebDataCollection(correct);
      }
      // https://www.reddit.com/r/SubredditSimulator/comments/gucnhx/fortunately_when_i_took_three_of_them_grant_my/
      //  https://https//www.sciencemadness.org/whisper/viewthread.php?tid=160198
      if (
        fields.type === "web_crawl" &&
        social_media === "YoutubeVideoScrape"
      ) {
        let correct = {
          type: "YoutubeVideoScrape",
          status: "Adhoc",
          projectId: props.projectId,
          endpointRunStatus: "Waiting",
          nextScheduled: "2023-04-18T09:30:00.000Z",
          expiresAfter: "2023-04-20T09:30:00.000Z",
          maxTaskInterval: 10,
          minTaskInterval: 5,
          depth: fields.depth,
          attributes: {
            urls: [fields.url],
            assignee: [fields.web_analyst].flat(),
            // protocol: ["Web"],
            operationId: [newUuid],
            depth: [fields.depth],
            keyword_search: [fields.keywords],
            keyword_search_type: [fields.youtubeSearchType],
          },
        };
        mainStore.StartWebDataCollection(correct);
      }

      if (fields.type === "web_crawl" && social_media === "TikTokScrape") {
        let correct = {
          type: "TikTokScrape",
          status: "Adhoc",
          projectId: props.projectId,
          endpointRunStatus: "Waiting",
          nextScheduled: "2023-04-18T09:30:00.000Z",
          expiresAfter: "2023-04-20T09:30:00.000Z",
          maxTaskInterval: 10,
          minTaskInterval: 5,
          depth: fields.depth,
          attributes: {
            urls: [fields.url],
            assignee: [fields.web_analyst].flat(),
            // protocol: ["Web"],
            operationId: [newUuid],
            depth: [fields.depth],
            keyword_search: [fields.keywords],
            keyword_search_type: [fields.tiktokSearchType],
          },
        };

        mainStore.StartWebDataCollection(correct);
      }
      if (fields.type === "transaction analysis") {
        missionsStore.updateMissionContainsTrans(props.projectId, true);
      }
    });
    // return
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    missionsStore.setIsOperationModalOpen(false);
    create_operation_form.resetFields();
  };

  const handleCancel = () => {
    missionsStore.setIsOperationModalOpen(false);
    create_operation_form.resetFields();
  };

  const types = [
    {
      label: "web crawl",
      value: "web_crawl",
    },
    {
      label: "field",
      value: "field",
    },
    {
      label: "transaction analysis",
      value: "transaction analysis",
    },
  ];
  const levels = [
    {
      label: "1 level",
      value: 1,
    },
    {
      label: "2 levels",
      value: 2,
    },
    // {
    //   label: "3",
    //   value: 3,
    // },
  ];
  const handleWebAnalystsAssignees = (value) => {
    setWebAnalystsAssignees(value);
  };
  const handleTransactionAnalystsAssignees = (value) => {
    setTransactionAnalystsAssignees(value);
  };
  const handleFieldOperatorsAssignees = (value) => {
    setFieldOperatorsAssignees(value);
  };

  const media = [
    {
      label: "Surface & Dark Web",
      value: "dark_web",
    },
    {
      label: "Reddit",
      value: "RedditSubredditScrape",
    },
    {
      label: "Youtube",
      value: "YoutubeVideoScrape",
    },
    {
      label: "Tik tok",
      value: "TikTokScrape",
    },
  ];

  const searchTypeYoutube = [
    {
      label: "Date",
      value: "date",
    },
    {
      label: "Rating",
      value: "rating",
    },
    {
      label: "Relevance",
      value: "relevance (default)",
    },
    {
      label: "Title",
      value: "title",
    },
    {
      label: "Video Count",
      value: "videoCount",
    },
    {
      label: "View Count",
      value: "viewCount",
    },
  ];

  const searchTypeReddit = [
    {
      label: "Relevance",
      value: "relevance",
    },
    {
      label: "Hot",
      value: "hot",
    },
    {
      label: "New",
      value: "new",
    },
    {
      label: "Top",
      value: "top",
    },
    {
      label: "Comments",
      value: "comments",
    },
  ];

  const searchTypeTiktok = [
    {
      label: "Top",
      value: "top",
    },
    {
      label: "Accounts",
      value: "accounts",
    },
    {
      label: "Videos",
      value: "videos",
    },
  ];

  return (
    <div>
      <Modal
        title="Create an Operation for this mission"
        open={missionsStore.isOperationModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"80%"}
        style={{ textAlign: "center", height: "100%" }}
        footer={null}
      >
        <Form
          initialValues={{
            remember: true,
            redditSearchType: "relevance", // Set default value for redditSearchType
            youtubeSearchType: "relevance", // Set default value for youtubeSearchType
            tiktokSearchType: "top", // Set default value for tiktokSearchType
          }}
          name="create_operation_form"
          // initialValues={{
          //   remember: true,
          // }}
          onFinish={onCreateOperation}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          size="large"
          form={create_operation_form}
        >
          {missionsStore.pickedMission.description &&
          missionsStore.pickedMission.description.includes("?creator_name=") &&
          usersStore.currentUser.name ===
            missionsStore.pickedMission.description.split(
              "?creator_name="
            )[1] ? (
            <div>
              <Row gutter={[20, 20]}>
                <Col span={8}>
                  {" "}
                  <Form.Item
                    label="Operation Title"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please input title",
                        type: "string",
                      },
                    ]}
                  >
                    <Input placeholder="Please input operation title!" />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    label="Operation Description"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Description",
                        type: "string",
                      },
                    ]}
                  >
                    <Input placeholder="Please input operation description!" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Type of operation"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Please input operation type!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={"operation type"}
                      onChange={(e) => setOperationType(e)}
                    >
                      {types.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {operation_type === "field" && (
                <div>
                  <Form.Item
                    style={{ marginTop: "-20px" }}
                    label="fiel operator"
                    name="field_operator"
                    rules={[
                      {
                        required: true,
                        message: "Please select field operator!",
                      },
                    ]}
                  >
                    <Select
                      mode="tags"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleFieldOperatorsAssignees}
                      tokenSeparators={[","]}
                      options={field_operators}
                    >
                      {field_operators.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              )}
              {operation_type === "transaction analysis" && (
                <div>
                  <Form.Item
                    style={{ marginTop: "-20px" }}
                    label="transaction analyst"
                    name="transaction_analyst"
                    rules={[
                      {
                        required: true,
                        message: "Please select transaction analyst!",
                      },
                    ]}
                  >
                    <Select
                      mode="tags"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleTransactionAnalystsAssignees}
                      tokenSeparators={[","]}
                      options={transaction_analysts}
                    >
                      {transaction_analysts.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              )}

              {operation_type === "web_crawl" && (
                <div>
                  <Form.Item
                    style={{ marginTop: "-20px" }}
                    label="Web analyst"
                    name="web_analyst"
                    rules={[
                      {
                        required: true,
                        message: "Please select web analyst!",
                      },
                    ]}
                  >
                    <Select
                      mode="tags"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleWebAnalystsAssignees}
                      tokenSeparators={[","]}
                      options={web_analysts}
                    />

                    {/* <Select placeholder={"depth"}>
                      {web_analysts.map((item, index) => {
                        return (
                          <Option key={index} value={item.value}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select> */}
                  </Form.Item>
                  <Divider>Web crawl operation details</Divider>
                  <Row gutter={[20, 20]}>
                    <Col span={8}>
                      <Form.Item
                        style={{ marginTop: "-20px" }}
                        label="Web crawl url"
                        name="url"
                        rules={[
                          {
                            required: true,
                            message: "Please input Web crawl url!",
                            type: "url",
                          },
                        ]}
                      >
                        <Input placeholder="Please input Web crawl url!" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        style={{ marginTop: "-20px" }}
                        label="Depth"
                        name="depth"
                        rules={[
                          {
                            required: true,
                            message: "Please input depth!",
                          },
                        ]}
                      >
                        <Select placeholder={"depth"}>
                          {levels.map((item, index) => {
                            return (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      {" "}
                      <Form.Item
                        style={{ marginTop: "-20px" }}
                        label="Social media"
                        name="social_media"
                        rules={[
                          {
                            required: true,
                            message: "Please select social media !",
                          },
                        ]}
                      >
                        <Select
                          onChange={(e) => setSocialMedia(e)}
                          placeholder={"social media to be crawled"}
                        >
                          {media.map((item, index) => {
                            return (
                              <Option key={index} value={item.value}>
                                {item.label}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}

              {operation_type === "web_crawl" &&
                (social_media === "RedditSubredditScrape" ||
                  social_media === "YoutubeVideoScrape" ||
                  social_media === "TikTokScrape") && (
                  <div>
                    <Row gutter={[20, 20]}>
                      <Col span={12}>
                        <Form.Item
                          style={{ marginTop: "-20px" }}
                          label="Keywords"
                          name="keywords"
                          rules={[
                            {
                              required: true,
                              message: "Please input keywords",
                              type: "string",
                            },
                          ]}
                        >
                          <Input placeholder="Please input keywords!" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        {operation_type === "web_crawl" &&
                          social_media === "RedditSubredditScrape" && (
                            <div>
                              <Form.Item
                                style={{ marginTop: "-20px" }}
                                label="Keywords search type"
                                name="redditSearchType"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input keywords",
                                    type: "string",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={"keywords search type"}
                                  defaultValue={"relevance"}
                                >
                                  {searchTypeReddit.map((item, index) => {
                                    return (
                                      <Option key={index} value={item.value}>
                                        {item.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          )}

                        {operation_type === "web_crawl" &&
                          social_media === "YoutubeVideoScrape" && (
                            <div>
                              <Form.Item
                                style={{ marginTop: "-20px" }}
                                label="Keywords search type"
                                name="youtubeSearchType"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input keywords",
                                    type: "string",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={"keywords search type"}
                                  defaultValue={"relevance"}
                                >
                                  {searchTypeYoutube.map((item, index) => {
                                    return (
                                      <Option key={index} value={item.value}>
                                        {item.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          )}

                        {operation_type === "web_crawl" &&
                          social_media === "TikTokScrape" && (
                            <div>
                              <Form.Item
                                style={{ marginTop: "-20px" }}
                                label="Keywords search type"
                                name="tiktokSearchType"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input keywords",
                                    type: "string",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={"keywords search type"}
                                  defaultValue={"top"}
                                >
                                  {searchTypeTiktok.map((item, index) => {
                                    return (
                                      <Option key={index} value={item.value}>
                                        {item.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          )}
                      </Col>
                    </Row>
                  </div>
                )}

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 24,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
              </Form.Item>
            </div>
          ) : (
            <div> Only the creator of the mission can add operations</div>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default observer(CreateOperation);
