import React from "react";
import { Row, Col } from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";

const UploadData = (props) => {
  const { mainStore } = useMobxStores();

  function handleFileUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const contents = e.target.result;
      convertCSVtoJSON(contents);
    };

    reader.readAsText(file);
  }

  function convertCSVtoJSON(csv) {
    const lines = csv.split("\n");
    const result = [];

    const headers = lines[0].split(",");
    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(",");

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }

      result.push(obj);
    }

    mainStore.StoreData(props.type, JSON.stringify(result));
    console.log(result);
  }

  return (
    <Row>
      <Col span={24}>
        <input type="file" onChange={handleFileUpload} />
      </Col>
    </Row>
  );
};
export default observer(UploadData);
