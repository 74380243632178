import React from "react";
import { Statistic, Row, Col } from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
const { Countdown } = Statistic;

const SessionCounter = (props) => {
  const { mainStore } = useMobxStores();

  const onFinish = () => {
    props.onLogout();
  };

  return (
    <Row>
      <Col span={24}>
        {" "}
        <Countdown
          value={mainStore.session}
          onFinish={onFinish}
          format="mm:ss"
          valueStyle={{ color: "black" }}
        />
      </Col>
    </Row>
  );
};

export default observer(SessionCounter);
