import React from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";

const { Option } = Select;

const CreateMission = () => {
  const { missionsStore, usersStore } = useMobxStores();

  const [create_mission_form] = Form.useForm();

  const onCreateMission = (fields) => {
    fields.status = "Enabled";
    fields.attributes = {
      creatorName: [usersStore.currentUser.name],
      creatorId: [usersStore.currentUser.domainId],
    };
    fields.attributes = {
      creatorName: [usersStore.currentUser.name],
      creatorId: [usersStore.currentUser.domainId],
    };
    fields.description =
      fields.description + "?creator_name=" + usersStore.currentUser.name;
    // fields.type = usersStore.currentUser.name;
    fields.tags = ["noTransaction"];
    console.log(fields);
    missionsStore.CreateMission(fields);
    create_mission_form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    missionsStore.setIsMissionModalOpen(false);
  };

  const handleCancel = () => {
    missionsStore.setIsMissionModalOpen(false);
  };

  const participants = [
    {
      label: "Analyst",
      value: "Analyst",
    },
  ];

  const status = [
    {
      label: "Enabled",
      value: "Enabled",
    },
  ];

  return (
    <div>
      <Modal
        title="Create Mission"
        open={missionsStore.isMissionModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"80%"}
        style={{ textAlign: "center", height: "100%" }}
        footer={null}
      >
        {usersStore.currentUser &&
        usersStore.currentUser.type &&
        usersStore.currentUser.type.includes("mission_commander") ? (
          <Form
            name="create_mission_form"
            initialValues={{
              remember: true,
            }}
            onFinish={onCreateMission}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            size="large"
            form={create_mission_form}
          >
            <Form.Item
              label="Title"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input title!",
                },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
            >
              <Input.TextArea placeholder="Description" />
            </Form.Item>
            {/* <Form.Item
           label="Status"
           name="status"
           rules={[
             {
               required: true,
               message: "Please input status!",
             },
           ]}
         >
           <Select placeholder={"status"}>
             {status.map((item, index) => {
               return (
                 <Option key={index} value={item.value}>
                   {item.label}
                 </Option>
               );
             })}
           </Select>
         </Form.Item> */}
            {/* <Form.Item
           label="Participants"
           name="participants"
           rules={[
             {
               required: true,
               message: "Please input participant!",
             },
           ]}
         >
          <Select placeholder={"participant"}>
                 {participants.map((item, index) => {
                   return (
                     <Option key={index} value={item.value}>
                       {item.label}
                     </Option>
                   );
                 })}
               </Select>
         </Form.Item> */}
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 24,
              }}
            >
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div> Only mission commanders can create missions!</div>
        )}
      </Modal>
    </div>
  );
};

export default observer(CreateMission);
