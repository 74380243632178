import {
  Button,
  Row,
  Col,
  Badge,
  Tag,
  Modal,
  Collapse,
  Image,
  Card,
  Divider,
  Drawer,
  Space,
  Skeleton,
  Switch,
} from "antd";
import { DeleteFilled } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import {
  LogoutOutlined,
  LoginOutlined,
  WarningOutlined,
  RedoOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Timer from "../session-counter/SessionCounter";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import NotificationMap from "./NotificationMap";
import { PageHeader } from "@ant-design/pro-layout";
import EmptyData from "../emptydata/EmptyData";

const { Panel } = Collapse;

const MainHeader = (props) => {
  const { textStore, mainStore, errorStore, usersStore } = useMobxStores();
  const [count, setCount] = useState(mainStore.alerts.length);
  const [countTAS, setCountTAS] = useState(mainStore.alertsTAS.length);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTASOpen, setIsModalTASOpen] = useState(false);
  const [sec, setSec] = useState(0);
  const [secTAS, setSecTAS] = useState(0);

  const [activeKey, setActiveKey] = useState([]);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handlePanelChange = (key) => {
    // Check if a panel is being closed (i.e., the activeKey is being removed)
    // if (
    //   Array.isArray(key)
    //     ? activeKey.length > key.length
    //     : activeKey.includes(key)
    // ) {
    //   console.log("closed" + activeKey);
    //   mainStore.deleteNotification(activeKey);
    // }
    // Update the state with the new active keys
    setActiveKey(key);
  };

  const deleteAlert = (id) => {
    // Check if a panel is being closed (i.e., the activeKey is being removed)

    // console.log(key);
    mainStore.deleteAlert(id);
  };
  const deleteAlertTAS = (id) => {
    // Check if a panel is being closed (i.e., the activeKey is being removed)

    // console.log(key);
    mainStore.deleteAlertTAS(id);
  };

  const deleteAllAlerts = () => {
    // mainStore.clearAlerts();
    mainStore.deleteAllAlerts();
  };
  const deleteAllAlertsTAS = () => {
    // mainStore.clearAlerts();
    mainStore.deleteAllAlertsTAS();
    setIsModalTASOpen(false);
  };

  const onAlertsSwitch = () => {
    mainStore.setAlertsOn(!mainStore.alertsOn);
  };

  const onClickReset = () => {
    mainStore.resetSession();
    errorStore.setInfo(true);
    errorStore.setMessage("Session Updated!");
    errorStore.setMessagePlacement("bottomRight");
    props.onUpdateToken();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleTASOk = () => {
    setIsModalTASOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {}, [sec]);
  useEffect(() => {
    setCountTAS(mainStore.alertsTAS.length);
  }, [mainStore.alertsTAS.length]);

  // useEffect(() => {}, [mainStore.notifications]);

  const onAlertsClick = () => {
    setCount(0);
    setSec(0);
    setIsModalOpen(true);
    const timer = setTimeout(() => {
      setSec(1);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  };
  const onAlertsTASClick = () => {
    // setCountTAS(0);
    setSecTAS(0);
    setIsModalTASOpen(true);
    const timerTAS = setTimeout(() => {
      setSecTAS(1);
    }, 3000);

    return () => {
      clearTimeout(timerTAS);
    };
  };

  return (
    <Row style={{ boxShadow: `0 5px 2px -2px #73C2FB` }}>
      {/* <Col span={8}>
        {" "}
        <Image
          src="/odysseus_logo.png"
          width={"200px"}
          height={"60px"}
          preview={false}
        />
      </Col> */}
      <Col style={{ backgroundColor: "white" }} span={1}>
        {" "}
        <Image
          style={{ marginLeft: "12px" }}
          src="/odysseus_logoNew.png"
          width={"50px"}
          // height={"45px"}
          preview={false}
        />
      </Col>
      <Col style={{ backgroundColor: "white" }} span={7}>
        <div
          style={{
            marginLeft: "20px",
            fontSize: "30px",
            fontFamily: "sans-serif",
          }}
        >
          {" "}
          Odysseus
        </div>
      </Col>

      <Col style={{ backgroundColor: "white" }} span={16}>
        <Row justify="end" align="middle" style={{ backgroundColor: "white" }}>
          <span>
            {usersStore.currentUser &&
              usersStore.currentUser.type &&
              (usersStore.currentUser.type.includes("mission_commander") ||
                usersStore.currentUser.type.includes("field_operator")) && (
                <div>
                  <Button
                    type="default" // Set the button type to "default" to get a white button
                    onClick={() => onAlertsTASClick()}
                    size="medium"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      marginLeft: "6px",
                    }} // Set the background color to white and font color to blue
                  >
                    <Row align="center">
                      <span>Field alerts </span>{" "}
                      <span>
                        {countTAS > 0 && (
                          <Badge
                            disabled
                            count={countTAS}
                            onClick={() => onAlertsTASClick()}
                            key="1"
                          ></Badge>
                        )}
                      </span>
                    </Row>
                  </Button>
                </div>
              )}
          </span>
          <span>
            {usersStore.currentUser &&
              usersStore.currentUser.type &&
              (usersStore.currentUser.type.includes("mission_commander") ||
                usersStore.currentUser.type.includes(
                  "transaction_analyst"
                )) && (
                <div>
                  <Button
                    type="default" // Set the button type to "default" to get a white button
                    onClick={() => onAlertsClick()}
                    size="medium"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      marginLeft: "6px",
                    }} // Set the background color to white and font color to blue
                  >
                    <Row align="center">
                      <span>Transaction alerts </span>{" "}
                      <span>
                        <Badge
                          disabled
                          count={mainStore.alerts.length}
                          onClick={() => onAlertsClick()}
                          key="1"
                        ></Badge>
                      </span>
                    </Row>
                  </Button>
                </div>
              )}
          </span>

          {/* <span>
            <Badge
              disabled
              count={mainStore.alerts.length}
              onClick={() => onAlertsClick()}
              key="1"
            >
              <WarningOutlined style={{ fontSize: "24px", cursor: "grab" }} />
            </Badge>
          </span> */}
          <span
            style={{
              marginLeft: "20px",
              width: "250px",
              border: "grey solid 1px ",
              borderRadius: "4px",
            }}
          >
            <Row justify="space-between" align="middle">
              <span style={{ marginLeft: "6px" }}> Session ending in </span>
              <span style={{ marginLeft: "6px" }}>
                <Timer onLogout={props.onLogout} />
              </span>
              <span style={{ marginLeft: "6px", marginRight: "6px" }}>
                <RedoOutlined
                  style={{ fontSize: "24px" }}
                  onClick={() => onClickReset()}
                />
              </span>
            </Row>
          </span>
          <span style={{ marginLeft: "20px", marginRight: "20px" }}>
            <MenuUnfoldOutlined
              style={{ fontSize: "34px" }}
              onClick={showDrawer}
              key="3"
            />
          </span>
          <span
            style={{
              marginLeft: "-6px",
              width: "146px",
              border: "grey solid 1px ",
              borderRadius: "4px",
            }}
          >
            Notifications{" "}
            <Switch
              style={{
                margin: "7px",
                // marginLeft: "8px",
              }}
              checked={mainStore.alertsOn}
              onChange={() => onAlertsSwitch()}
            />
          </span>
        </Row>
      </Col>

      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        open={open}
        style={{ textAlign: "center" }}
      >
        <Image
          src="/odysseus_logo.png"
          width={"200px"}
          height={"60px"}
          preview={false}
        />
        <Divider></Divider>
        <UserOutlined
          onClick={() => navigate("/profile")}
          style={{ fontSize: "24px" }}
        />{" "}
        Profile
        <Divider></Divider>
        {!window.localStorage.getItem("od_token") ? (
          <>
            <Button
              onClick={() => props.onLogin()}
              type="link"
              style={{ color: "black" }}
            >
              <LoginOutlined /> {textStore.text["account"]}
            </Button>
          </>
        ) : (
          <Button
            onClick={() => props.onLogout()}
            type="link"
            style={{ color: "black" }}
          >
            <LogoutOutlined style={{ fontSize: "24px" }} />{" "}
            {textStore.text["logout"]}
          </Button>
        )}
      </Drawer>
      <Modal
        title="Alerts List"
        open={isModalTASOpen}
        // onOk={handleTASOk}
        // onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        {/* <Collapse activeKey={activeKey} onChange={handlePanelChange}> */}
        {mainStore.alertsTAS.length > 0 &&
          mainStore.alertsTAS.map(
            (item, index) => {
              return (
                <div>
                  <Row>
                    <span>
                      {" "}
                      <Button
                        onClick={() => {
                          setIsModalTASOpen(false);
                          navigate(
                            "/mission/" +
                              item.message.header.caseId +
                              "/field_operator/" +
                              item.message.body.operationId +
                              "/session/realtime"
                          );
                        }}
                        style={{ height: "50px", width: "360px" }}
                      >
                        <div>
                          {" "}
                          <div>{item.name}</div>
                          <div>{item.operationName}</div>
                        </div>
                      </Button>
                    </span>
                    <span>
                      {usersStore.currentUser.type.includes(
                        "mission_commander"
                      ) && (
                        <DeleteFilled
                          onClick={(event) => {
                            event.stopPropagation(); // Stop the panel from opening
                            deleteAlertTAS(item.id);
                          }}
                          style={{ fontSize: "16px", color: "red" }}
                        />
                      )}
                    </span>
                  </Row>

                  {/* <div>{item.message.header}</div>
                    <div>{item.message.body}</div> */}
                </div>
              );
            }

            //  {usersStore.currentUser.type.includes(
            //         "mission_commander"
            //       ) && (
            //         <DeleteFilled
            //           onClick={(event) => {
            //             event.stopPropagation(); // Stop the panel from opening
            //             deleteAlert(item.id);
            //           }}
            //           style={{ fontSize: "16px", color: "red" }}
            //         />
            //       )}
            // {Object.entries(item).map((item, index) => {
            //   return (
            //     <li key={index}>
            //       {<b>{item[0]}</b>}:{" "}
            //       {item[0] == "report" ? (
            //         <a
            //           target="_blank"
            //           href={`https:  std-api.odysseus-online.rid-intrasoft.eu/std/report/${item[1]}`}
            //         >
            //           {`https:  std-api.odysseus-online.rid-intrasoft.eu/std/report/${item[1]}`}
            //         </a>
            //       ) : (
            //         item[1]
            //       )}
            //     </li>
            //   );
            // })}
          )}
        {/* </Collapse> */}

        {mainStore.alertsTAS.length > 0 && (
          <div style={{ marginTop: "8px" }}>
            <Row justify="space-between">
              <span>
                {usersStore.currentUser.type.includes("mission_commander") && (
                  <div>
                    <Button
                      type="default" // Set the button type to "default" to get a white button
                      onClick={() => deleteAllAlertsTAS()}
                      size="medium"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        marginLeft: "6px",
                      }} // Set the background color to white and font color to blue
                    >
                      <Row align="center">
                        <span>
                          <DeleteFilled
                            style={{ fontSize: "16px", marginRight: "4px" }}
                          />
                        </span>
                        <span>Remove all </span>{" "}
                      </Row>
                    </Button>
                  </div>
                )}
              </span>
              <span>
                <Button
                  type="default" // Set the button type to "default" to get a white button
                  onClick={handleTASOk}
                  // Handle the click event
                  size="medium"
                  style={{
                    backgroundColor: "#354289",
                    color: "white",
                    marginLeft: "6px",
                  }} // Set the background color to white and font color to blue
                >
                  <Row align="center">
                    <span></span>
                    <span>Ok</span>{" "}
                  </Row>
                </Button>
              </span>
            </Row>
          </div>
        )}

        <div style={{ marginTop: "10px" }}>
          {mainStore.alertsTAS.length == 0 && (
            //  && sec == 1
            <div>
              <EmptyData />
              <div style={{ marginTop: "8px" }}>
                <Row justify="end">
                  <span>
                    <Button
                      type="default" // Set the button type to "default" to get a white button
                      onClick={handleTASOk}
                      // Handle the click event
                      size="medium"
                      style={{
                        backgroundColor: "#354289",
                        color: "white",
                        marginLeft: "6px",
                      }} // Set the background color to white and font color to blue
                    >
                      <Row align="center">
                        <span></span>
                        <span>Ok</span>{" "}
                      </Row>
                    </Button>
                  </span>
                </Row>
              </div>
            </div>
          )}{" "}
          {mainStore.alertsTAS.length == 0 && (
            // sec < 1 &&
            <Skeleton title={false} active={true} loading={true} />
          )}
        </div>
      </Modal>
      <Modal
        title="Alerts List"
        open={isModalOpen}
        // onOk={handleOk}
        // onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        <Collapse activeKey={activeKey} onChange={handlePanelChange}>
          {mainStore.alerts.length > 0 &&
            mainStore.alerts.map((item, index) => {
              return (
                <Panel
                  key={index}
                  header={
                    <Row>
                      <Col span={16}>{item.message.amount}</Col>
                      <Col span={7}>
                        {parseFloat(item.message.suspicious) < 5 ? (
                          <Tag color="green">Low criticality</Tag>
                        ) : item.danger < 7 ? (
                          <Tag color="orange">Medium criticality</Tag>
                        ) : (
                          <Tag color="red">High criticality</Tag>
                        )}
                      </Col>
                      <Col span={1}>
                        {usersStore.currentUser.type.includes(
                          "mission_commander"
                        ) && (
                          <DeleteFilled
                            onClick={(event) => {
                              event.stopPropagation(); // Stop the panel from opening
                              deleteAlert(item.id);
                            }}
                            style={{ fontSize: "16px", color: "red" }}
                          />
                        )}
                      </Col>
                    </Row>
                  }
                >
                  {/* {Object.entries(item).map((item, index) => {
                    return (
                      <li key={index}>
                        {<b>{item[0]}</b>}:{" "}
                        {item[0] == "report" ? (
                          <a
                            target="_blank"
                            href={`https:  std-api.odysseus-online.rid-intrasoft.eu/std/report/${item[1]}`}
                          >
                            {`https:  std-api.odysseus-online.rid-intrasoft.eu/std/report/${item[1]}`}
                          </a>
                        ) : (
                          item[1]
                        )}
                      </li>
                    );
                  })} */}
                  <Divider />
                  <NotificationMap
                    lon={item.message.longitude}
                    lat={item.message.latitude}
                  />
                </Panel>
              );
            })}
        </Collapse>

        {mainStore.alerts.length > 0 && (
          <div style={{ marginTop: "8px" }}>
            <Row justify="space-between">
              <span>
                {usersStore.currentUser.type.includes("mission_commander") && (
                  <div>
                    <Button
                      type="default" // Set the button type to "default" to get a white button
                      onClick={() => deleteAllAlerts()}
                      size="medium"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        marginLeft: "6px",
                      }} // Set the background color to white and font color to blue
                    >
                      <Row align="center">
                        <span>
                          <DeleteFilled
                            style={{ fontSize: "16px", marginRight: "4px" }}
                          />
                        </span>
                        <span>Remove all </span>{" "}
                      </Row>
                    </Button>
                  </div>
                )}
              </span>
              <span>
                <Button
                  type="default" // Set the button type to "default" to get a white button
                  onClick={handleOk}
                  // Handle the click event
                  size="medium"
                  style={{
                    backgroundColor: "#354289",
                    color: "white",
                    marginLeft: "6px",
                  }} // Set the background color to white and font color to blue
                >
                  <Row align="center">
                    <span></span>
                    <span>Ok</span>{" "}
                  </Row>
                </Button>
              </span>
            </Row>
          </div>
        )}

        <div style={{ marginTop: "10px" }}>
          {mainStore.alerts.length == 0 && sec == 1 && (
            <div>
              <EmptyData />
              <div style={{ marginTop: "8px" }}>
                <Row justify="end">
                  <span>
                    <Button
                      type="default" // Set the button type to "default" to get a white button
                      onClick={handleOk}
                      // Handle the click event
                      size="medium"
                      style={{
                        backgroundColor: "#354289",
                        color: "white",
                        marginLeft: "6px",
                      }} // Set the background color to white and font color to blue
                    >
                      <Row align="center">
                        <span></span>
                        <span>Ok</span>{" "}
                      </Row>
                    </Button>
                  </span>
                </Row>
              </div>
            </div>
          )}
          {mainStore.alerts.length == 0 && sec < 1 && (
            <Skeleton title={false} active={true} loading={true} />
          )}
        </div>
      </Modal>
    </Row>
  );
};

export default observer(MainHeader);
