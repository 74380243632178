import React, { useState, useEffect } from "react";
import { Col, Row, Divider, Card } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import EmptyData from "../emptydata/EmptyData";

const AirSensorData = (props) => {
  const { mainStore } = useMobxStores();

  const [sensor_air_data, setsensor_air_data] = useState(
    mainStore.sensor_air_data
  );

  useEffect(() => {}, [sensor_air_data]);

  return (
    <Row justify="center" align="center">
      <Col span={24} lg={24} xs={24} sm={24}>
        <Divider> Air Sensor Data</Divider>
        <Card style={{ borderradius: "15px", minHeight: "400px" }}>
          <ul>
            {mainStore.sensor_air_data != ""
              ? Object.entries(mainStore.sensor_air_data)
                  .filter((item) => item[0] != "peaks")
                  .map((item, index) => {
                    return (
                      <li key={index}>
                        {<b>{item[0]}</b>}: {item[1]}
                      </li>
                    );
                  })
              : <EmptyData/>}
          </ul>
        </Card>
      </Col>
    </Row>
  );
};

export default observer(AirSensorData);
