import React, { useEffect, useState, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  useMap,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import { Card, Row } from "antd";
import EmptyData from "../emptydata/EmptyData";
import Icon from "@mdi/react";
import {
  mdiBatteryOutline,
  mdiBattery10,
  mdiBattery20,
  mdiBattery30,
  mdiBattery40,
  mdiBattery50,
  mdiBattery60,
  mdiBattery70,
  mdiBattery80,
  mdiBattery90,
  mdiBattery,
} from "@mdi/js";

import "leaflet-arrowheads";

const mapStyle = {
  // maxHeight: "440px",
  minHeight: "380px",
  width: "auto",
  borderRadius: "15px",
  backgroundColor: "red",
};

const customMarker = new L.icon({
  iconUrl: "../../../../../blue-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const ArrowheadPolyline = ({ positions, color }) => {
  const map = useMap();
  const polylineRef = useRef(null);

  useEffect(() => {
    if (polylineRef.current) {
      const arrowheadOptions = {
        size: "14px",
        frequency: "allvertices",
        fill: true,

        yawn: 40,
      };
      L.polyline(positions, { color: color })
        .arrowheads(arrowheadOptions)
        .addTo(map);
    }
  }, [map, positions, color]);

  return <Polyline ref={polylineRef} positions={positions} color={color} />;
};

const UAVMapHistory = () => {
  const { mainStore, fieldHistoryStore } = useMobxStores();

  return (
    <div id="map">
      {fieldHistoryStore.uav_history_data.length > 0 ? (
        // {mainStore.ugv_history_data != "" ? (
        <MapContainer
          zoom={9}
          minZoom={1}
          scrollWheelZoom={true}
          style={mapStyle}
          center={[
            fieldHistoryStore.uav_history_data[0].gpsCoords.latitude,
            fieldHistoryStore.uav_history_data[0].gpsCoords.longitude,
          ]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {fieldHistoryStore.uav_history_data.map((marker) => {
            return (
              <Marker
                position={[
                  marker.gpsCoords.latitude,
                  marker.gpsCoords.longitude,
                ]}
                icon={customMarker}
              >
                <Popup
                  maxWidth="auto"
                  maxHeight="auto"
                  style={{ width: "auto" }}
                >
                  <Card
                    // title={`Cluster ${item.id} (${item.recipe})`}
                    style={{
                      width: "420px",
                      minWidth: "auto",
                      textAlign: "left",
                      height: "100px",
                    }}
                    // bodyStyle={{
                    //   textAlign: "left",

                    //   // maxHeight: "60vh",
                    // }}
                  >
                    <div>
                      <Row>
                        <span>
                          <div>
                            <span style={{ fontWeight: "bold" }}>Lat:</span>{" "}
                            {marker.gpsCoords.latitude}{" "}
                          </div>
                        </span>
                        <span style={{ marginLeft: "20px" }}>
                          <div>
                            <span style={{ fontWeight: "bold" }}>Long:</span>{" "}
                            {marker.gpsCoords.longitude}{" "}
                          </div>
                        </span>
                        <span style={{ marginLeft: "20px" }}>
                          <div>
                            <span style={{ fontWeight: "bold" }}>Alt:</span>{" "}
                            {marker.gpsCoords.altitude}{" "}
                          </div>
                        </span>
                        {/* <span style={{ marginLeft: "20px" }}>
                          <div>
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {marker.battery} %
                            </span>{" "}
                            {marker.battery === 0 && (
                              <Icon path={mdiBatteryOutline} size={1} />
                            )}
                            {marker.battery > 0 && marker.battery <= 10 && (
                              <Icon path={mdiBattery10} size={1} />
                            )}
                            {marker.battery > 10 && marker.battery <= 20 && (
                              <Icon path={mdiBattery20} size={1} />
                            )}
                            {marker.battery > 20 && marker.battery <= 30 && (
                              <Icon path={mdiBattery30} size={1} />
                            )}
                            {marker.battery > 30 && marker.battery <= 40 && (
                              <Icon path={mdiBattery40} size={1} />
                            )}
                            {marker.battery > 40 && marker.battery <= 50 && (
                              <Icon path={mdiBattery50} size={1} />
                            )}
                            {marker.battery > 50 && marker.battery <= 60 && (
                              <Icon path={mdiBattery60} size={1} />
                            )}
                            {marker.battery > 60 && marker.battery <= 70 && (
                              <Icon path={mdiBattery70} size={1} />
                            )}
                            {marker.battery > 70 && marker.battery <= 80 && (
                              <Icon path={mdiBattery80} size={1} />
                            )}
                            {marker.battery > 80 && marker.battery <= 90 && (
                              <Icon path={mdiBattery90} size={1} />
                            )}
                            {marker.battery > 90 && marker.battery <= 100 && (
                              <Icon path={mdiBattery} size={1} />
                            )}
                          </div>
                        </span> */}
                      </Row>
                    </div>

                    {/* {` Confidence: ${item.properties.confidence}`} */}
                    <br />

                    {/* <a href={item.properties.detailsLink}>Details</a> */}
                  </Card>
                </Popup>
              </Marker>
            );
          })}
          {fieldHistoryStore.uav_history_data.length > 1 &&
            fieldHistoryStore.uav_history_data.map((position, index) => {
              console.log(index);
              if (index !== fieldHistoryStore.uav_history_data.length - 1) {
                return (
                  <ArrowheadPolyline
                    positions={[
                      [
                        fieldHistoryStore.uav_history_data[index].gpsCoords
                          .latitude,
                        fieldHistoryStore.uav_history_data[index].gpsCoords
                          .longitude,
                      ],
                      [
                        fieldHistoryStore.uav_history_data[index + 1].gpsCoords
                          .latitude,
                        fieldHistoryStore.uav_history_data[index + 1].gpsCoords
                          .longitude,
                      ],
                    ]}
                    color="blue"
                  >
                    <Tooltip
                      direction="top"
                      offset={[0, -40]}
                      opacity={1}
                      permanent
                    >
                      <div>sfdsf</div>
                      <div>sfdsf</div> <div>sfdsf</div>
                      Tooltip content here
                    </Tooltip>
                  </ArrowheadPolyline>
                );
              }
            })}
        </MapContainer>
      ) : (
        <EmptyData />
      )}
    </div>
  );
};

export default observer(UAVMapHistory);
