import React from "react";
import { Row, Col, Tabs } from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import Messages from "../messages/Messages";
import MainMap from "./MainMap";

const Map = (props) => {
  const { textStore } = useMobxStores();

  return (
    <div>
      <Row
        justify="space-around"
        align="top"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Col span={19} sm={24} xs={24} md={19} lg={19} xl={19} xxl={19}>
          <Tabs tabPosition="top">
            <Tabs.TabPane tab={textStore.text["map_display"]} key="item-1">
              <MainMap />
            </Tabs.TabPane>
          </Tabs>
        </Col>
        <Messages />
      </Row>
      <Row
        justify="middle"
        align="top"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Col span={24} sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}></Col>
      </Row>
    </div>
  );
};

export default observer(Map);
