import React, { useEffect } from "react";
import Icon from "@mdi/react";
import { mdiChartBubble } from "@mdi/js";
import { mdiPencil } from "@mdi/js";

import {
  Col,
  Row,
  Divider,
  Button,
  Table,
  Space,
  Popover,
  Tag,
  Checkbox,
} from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useMobxStores } from "../stores/stores";
import CreateMission from "../components/missions/CreateMission";
import { getOverridedColumns } from "../utils/tableLoader";
import { useNavigate } from "react-router-dom";
import { dateForTables } from "../utils/formatDate";

var _ = require("lodash");

const MissionsTable = (props) => {
  const { missionsStore, usersStore } = useMobxStores();
  const navigate = useNavigate();

  useEffect(() => {
    usersStore.GetProjectIdOfUsers(() => {
      usersStore.GetAllUsers();
    });
  }, []);
  useEffect(() => {
    if (
      usersStore.currentUser &&
      usersStore.currentUser.type &&
      !usersStore.currentUser.type.includes("platform_administrator")
    ) {
      navigate(`/missions`);
    }
  }, [usersStore.currentUser]);

  const columns = [
    {
      title: "Name",
      responsive: ["lg", "sm", "md", "xs"],
      dataIndex: "name",
      key: "name",
      width: "300px",
    },
    {
      title: "Roles",
      responsive: ["lg", "sm", "md", "xs"],
      key: "description",
      dataIndex: "attributes",
      render: (record) => (
        <div>
          <span>
            <Checkbox checked={JSON.parse(record.webanalyst[0])}>
              Web Analyst
            </Checkbox>
          </span>
          <span>
            <Checkbox checked={JSON.parse(record.transaction_analyst[0])}>
              Transaction Analyst
            </Checkbox>
          </span>
          <span>
            <Checkbox checked={JSON.parse(record.field_operator[0])}>
              Field Operator
            </Checkbox>
          </span>
          <span>
            <Checkbox checked={JSON.parse(record.mission_commander[0])}>
              Mission Commander
            </Checkbox>
          </span>
        </div>
      ),
    },
    {
      title: "Created at",
      responsive: ["lg", "sm", "md", "xs"],
      key: "created",
      // dataIndex: "created",
      // dateForTables
      render: (record) => <span>{dateForTables(record.created)}</span>,
    },

    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="default" // Set the button type to "default" to get a white button
            onClick={() => usersStore.DeleteUser(record.id)}
            size="medium"
            style={{
              backgroundColor: "red",
              color: "white",
              marginLeft: "6px",
            }} // Set the background color to white and font color to blue
          >
            <Row align="center">
              <span>
                <DeleteFilled
                  style={{ fontSize: "16px", marginRight: "4px" }}
                />
              </span>
              <span>Delete </span>{" "}
            </Row>
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div>
        <Row
          style={{
            marginTop: "4px",
            backgroundColor: "#003B73",
            color: "white",
            height: "70px",
            marginBottom: "24px",
          }}
          justify="space-between"
        >
          <span
            style={{
              width: "180px",
            }}
          ></span>
          <span
            style={{
              fontSize: "24px",
              marginTop: "18px",
            }}
          >
            Users
          </span>
          <span style={{ marginTop: "16px", marginRight: "8px" }}>
            <span style={{ marginRight: "4px" }}></span>
          </span>
        </Row>
      </div>
      <div>
        <Row justify="center">
          <CreateMission />
          {/* <Divider orientation="left">Missions</Divider> */}
          {usersStore.allUsers.length === 0 && <div> </div>}
          {usersStore.allUsers.length !== 0 && (
            <Table
              className="custom-table"
              style={{ width: "96vw" }}
              size="small"
              indentSize={12}
              bordered={true}
              scroll={{ x: "400px" }}
              rowClassName="custom-row-hover"
              dataSource={
                usersStore.allUsers != null
                  ? _.cloneDeep(usersStore.allUsers)
                  : Array(5).fill({})
              }
              columns={
                usersStore.allUsers != null
                  ? columns
                  : getOverridedColumns(columns)
              }
              rowKey={(record) => record.id}
            />
          )}
        </Row>
      </div>
    </div>
  );
};

export default observer(MissionsTable);
