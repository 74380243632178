import React, { useState } from "react";
import { Col, Row, Divider, Tag, Space, Button } from "antd";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import jwt from "jwt-decode";
// import { v4 as uuidv4 } from "uuid";

const Admin = (props) => {
  const { mainStore } = useMobxStores();
  const [user] = useState(jwt(localStorage.getItem("od_token")));

  const onTestKafka = () => {
    console.log("test");
  };

  // const onwater = () => {
  //   mainStore.Publish({
  //     topics: ["TOP_ADAPTED_DOVER_DONE"],
  //     body: JSON.stringify({
  //       header: {
  //         topicName: "TOP_ADAPTED_DOVER_DONE",
  //         sender: "T4i",
  //         msgId: "a9196c38-d419-4511-a050-75f120143728",
  //         sentUtc: "2023-05-05T11:10:47Z",
  //         source: "T4i adapted DOVER chemical detector",
  //         projectId: "86b8b6bd42ce110000000900",
  //       },
  //       body: {
  //         eventID: "6c1c9e5d01ad4eb5aab920e3c21e0fae",
  //         timeUTC: "2023-01-18T10:16:09z",
  //         sessionId: "234sphkfdf112sdasobjsdfy",
  //         title: "chemical detected",
  //         detectorStatus: "OK",
  //         units: "mV",
  //         lat: "23.123456",
  //         lon: "23.123456",
  //         alt: "100",
  //         peaks: [
  //           {
  //             value: 1000,
  //             compounds: ["test"],
  //           },
  //           {
  //             value: 3000,
  //             compounds: ["testA", "testB"],
  //           },
  //         ],
  //       },
  //     }),
  //   });
  // };

  // const onClickStartOperation = () => {
  //   let kafka_start_message = {
  //     header: {
  //       topicName:"FIELD_OPERATION_SESSION_ID",
  //       sender: "INTRA",
  //       sentUtc: "2023-01-01T00:0:00+0000",
  //       source: "Dashboard",
  //       caseId: "86b8b6bd42ce110000000900",
  //     },
  //     body: {
  //       enabled: true,
  //       sessionId: uuidv4(),
  //     },
  //   };

  //   mainStore.Publish({
  //     topics: ["FIELD_OPERATION_SESSION_ID"],
  //     body: JSON.stringify(kafka_start_message),
  //   });
  // };

  // const onClickStopOperation = () => {
  //   let kafka_stop_message = {
  //     header: {
  //       topicName: "FIELD_OPERATION_SESSION_ID",
  //       sender: "INTRA",
  //       sentUtc: "2023-01-01T00:0:00+0000",
  //       source: "Dashboard",
  //       caseId: "86b8b6bd42ce110000000900",
  //     },
  //     body: {
  //       enabled: false,
  //       sessionId: uuidv4(),
  //     },
  //   };
  //   mainStore.Publish({
  //     topics: ["FIELD_OPERATION_SESSION_ID"],
  //     body: JSON.stringify(kafka_stop_message),
  //   });
  // };

  const onTestSDMA = () => {
    mainStore.TestSDMA();
  };

  return (
    <Row justify="center" align="center" gutter={[20, 20]}>
      <Col span={8} lg={8} md={8} xs={24} sm={24}>
        <Divider orientation="left">User Details</Divider>
        {/* <p>
          <b>Username:</b> {user.name}
        </p> */}
        <p>
          <b>Email Verified:</b>{" "}
          {user.email_verified ? (
            <Tag color="green">Yes</Tag>
          ) : (
            <Tag color="red">no</Tag>
          )}
        </p>
        {/* <b>Roles</b> */}
        {/* <ul>
          {user.realm_access.roles.map((item, index) => {
            return <li key={index}>{item}</li>;
          })}
        </ul> */}
      </Col>
      <Col span={8} lg={8} md={8} xs={24} sm={24}>
        <Divider orientation="left">Kafka</Divider>
        <b>Kafka status :</b>{" "}
          {mainStore.isKafkaConnected ? (
            <Tag color="green">Connected</Tag>
          ) : (
            <Tag color="red">Not connected</Tag>
          )}
          <Divider orientation="left">SDMA</Divider>
          <Button
            style={{ width: "150px" }}
            type="primary"
            onClick={() => onTestSDMA()}
          >
            Test SDMA DB
          </Button>

          {/* <Button
            style={{ width: "150px", color: "white" }}
            onClick={() => onTestKafka()}
            type="primary"
          >
            Test kafka access
          </Button> */}
      </Col>
    </Row>
  );
};

export default observer(Admin);
