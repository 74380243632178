import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Divider,
  Button,
  Table,
  Space,
  Modal,
  message,
  Drawer,
  Tag,
  Spin,
  Tooltip,
} from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import TransactionMap from "./TransactionMap";
import { CSVLink } from "react-csv";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import moment from "moment";
import {
  mdiEarth,
  mdiHandshakeOutline,
  mdiWeb,
  mdiTruck,
  mdiReceiptTextOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import SearchForm from "./SearchForm";
import { v4 as uuidv4 } from "uuid";
import { getOverridedColumns } from "../../utils/tableLoader";

const TransactionsSTD = (props) => {
  const { mainStore } = useMobxStores();
  const [isModalOpen, setIsModalOpen] = useState(props.openModal);
  const [showmap, setShowMap] = useState(false);
  const [coords, setCoords] = useState([[10], [10]]);

  useEffect(() => {
    // mainStore.GetTransactions("86b8b6bd42ce110000000900");
    console.log("2");
    console.log(props.ids.projectId);
    mainStore.GetTransactions(props.ids.projectId);
  }, []);

  const onChange = (info) => {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const [reportModalData, setReportModalData] = useState([]);
  const [csvData, setData] = useState([]);

  const OnMap = (record) => {
    setShowMap(true);

    setCoords([record.attributes.latitude, record.attributes.longitude]);
  };
  const closeMap = () => {
    mainStore.setSupplyChain([]);
    setShowMap(false);
  };

  const showModal = (record) => {
    setReportModalData(record);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let user = window.localStorage.getItem("user");
  user = JSON.parse(user);

  const onClickStartTransaction = () => {
    // let exist = mainStore.ent
    // let values = {};
    // values.type = "transactions";
    // values.title = "true";
    // values.tags = [];
    // values.domainId = uuidv4();
    // values.attributes = {
    //   additionalProp1: ["string"],
    //   additionalProp2: ["string"],
    //   additionalProp3: ["string"],
    // };
    let values = mainStore.transaction;
    values.title = "true";
    mainStore.StartTransactions(values);
  };

  const onClickStopTransaction = () => {
    let values = mainStore.transaction;
    values.title = "false";
    mainStore.StopTransactions(values);
  };

  const columns = [
    // {
    //   title: "suspicious",
    //   responsive: ["lg", "xs"],
    //   key: "title",
    //   render: (record) => (
    //     <p style={{ color: "#00703c" }}>
    //       <>{record && record.attributes.suspicious}</>
    //     </p>
    //   ),
    // },

    {
      title: "Amount",
      responsive: ["lg", "sm", "md", "xs"],
      key: "Amount",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.amount}</>
        </p>
      ),
    },
    {
      title: "",
      responsive: ["lg", "sm", "md", "xs"],
      key: "transactionType",
      render: (record) => (
        <p style={{ width: "30px", height: "30px" }}>
          <>
            {record.transactionType && record.transactionType === "Online" && (
              <Icon path={mdiWeb}></Icon>
            )}
            {record.transactionType &&
              record.transactionType === "Distributor" && (
                <Icon path={mdiTruck}></Icon>
              )}
            {record.transactionType &&
              record.transactionType === "In Person" && (
                <Icon path={mdiHandshakeOutline}></Icon>
              )}
          </>
        </p>
      ),
    },
    {
      title: "Buyer",
      responsive: ["lg", "xl"],
      key: "Buyer",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.buyer}</>
        </p>
      ),
    },
    {
      title: "Buyer licence",
      responsive: ["lg", "xs"],
      key: "title",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.buyerlicence}</>
        </p>
      ),
    },
    //New
    // {
    //   title: "Buyer info",
    //   responsive: ["lg", "sm", "md", "xs"],
    //   key: "buyer",
    //   render: (record) => (
    //     <div>
    //       <p style={{ marginTop: "-2px" }}>
    //         <>{record.buyer}</>
    //       </p>
    //       <p style={{ marginTop: "-12px" }}>
    //         <>{record.buyerlicence && record.buyerlicence}</>
    //       </p>
    //       <p style={{ marginTop: "-12px", marginBottom: "-12px" }}>
    //         <>{record.cardNumber && record.cardNumber}</>
    //       </p>
    //     </div>
    //   ),
    // },
    {
      title: "Country",
      responsive: ["lg", "sm", "md", "xs"],
      key: "title",
      render: (record) => (
        <p style={{ color: "#00703c" }}>
          <>{record && record.attributes.country}</>
        </p>
      ),
    },
    // New
    // {
    //   title: "Location",
    //   responsive: ["lg", "sm", "md", "xs"],
    //   key: "country",
    //   render: (record) => (
    //     <div>
    //       <p>
    //         <>{record.country && record.country}</>
    //       </p>
    //       <p>
    //         <Icon path={mdiEarth} size={1} />
    //       </p>
    //     </div>
    //   ),
    // },

    // {
    //   title: "id",
    //   responsive: ["lg", "xl"],
    //   key: "title",
    //   width:'10%',
    //   render: (record) => (
    //     <p style={{ color: "#00703c" }}>
    //       {record && record.attributes.id}
    //     </p>
    //   ),
    // },
    {
      title: "Suspicious",
      responsive: ["lg", "xs"],
      key: "title",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.suspicious}</>
        </p>
      ),
    },
    {
      title: "Seller",
      responsive: ["lg", "sm", "md", "xs"],
      key: "title",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.seller}</>
        </p>
      ),
    },
    // new
    // {
    //   title: "Seller",
    //   responsive: ["lg", "sm", "md", "xs"],
    //   key: "seller",
    //   render: (record) => (
    //     <p>
    //       <>{record.seller && record.seller}</>
    //     </p>
    //   ),
    // },
    {
      title: "Item Description",
      responsive: ["lg", "xl"],
      key: "title",
      render: (record) => (
        <p style={{ color: "black" }}>
          <>{record && record.attributes.item_description}</>
        </p>
      ),
    },
    // {
    //   title: "latitude",
    //   responsive: ["lg", "xl"],
    //   key: "title",
    //   render: (record) => (
    //     <p style={{ color: "#00703c" }}>
    //       <>{record && record.attributes.latitude}</>
    //     </p>
    //   ),
    // },
    // {
    //   title: "longitude",
    //   responsive: ["lg", "xs"],
    //   key: "title",
    //   render: (record) => (
    //     <p style={{ color: "#00703c" }}>
    //       <>{record && record.attributes.longitude}</>
    //     </p>
    //   ),
    // },
    // {
    //   title: "unitofmeasure",
    //   responsive: ["lg", "sm", "md", "xs"],
    //   key: "title",
    //   render: (record) => (
    //     <p style={{ color: "#00703c" }}>
    //       <>{record && record.attributes.unitofmeasure}</>
    //     </p>
    //   ),
    // },
    {
      title: "Time date",
      responsive: ["lg", "xl"],
      key: "title",
      render: (record) => (
        <Tag style={{ color: "orange" }}>
          <>
            {record &&
              moment(record.attributes.timedate).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
          </>
        </Tag>
      ),
    },
    // new
    // {
    //   title: "Timedate",
    //   responsive: ["lg", "sm", "md", "xs"],
    //   key: "timedate",
    //   render: (record) => (
    //     <p>
    //       <>{record.timedate && record.timedate}</>
    //     </p>
    //   ),
    // },
    {
      title: "Actions",
      responsive: ["lg", "sm", "md", "xs"],
      key: "action",
      width: "auto",
      render: (record) => (
        <Space direction="vertical">
          <Button
            size="small"
            key={record + record}
            type="primary"
            onClick={() => OnMap(record)}
            style={{ width: "100px" }}
          >
            On Map
          </Button>
        </Space>
      ),
    },
  ];

  console.log(coords);

  return (
    <Row justify="center" align="center" className="full-viewport">
      <Col span={24} md={24} lg={24} xs={24} sm={24} xxl={24}>
        {/* <Row style={{ height: "auto" }}>
          <Col push={14}>
            <Space block={true} wrap={true} split={true}>
              {/* <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="text"
                // onChange={onChange}
              > */}
        {/* <Button
                style={{
                  width: "200px",
                }}
                type="primary"
                icon={<ArrowUpOutlined />}
                disabled={true}
              >
                Import Data
              </Button> */}
        {/* </Upload> */}
        {/* <CSVLink
                data={csvData}
                filename={"report_file.csv"}
                target="_blank"
              > */}
        {/* <Button
                type="primary"
                // onClick={() => setData(data)}
                style={{ width: "200px" }}
                disabled={true}
              >
                <ArrowDownOutlined /> Export all
              </Button>
              {/* </CSVLink> */}
        {/* </Space>
          </Col>
        </Row>  */}

        <Modal
          title="Report Information"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {/* {reportModalData !=[] && reportModalData.attributes.map((item) => {
              return item;
            })} */}
        </Modal>

        {!mainStore.isSearching ? (
          <div>
            <div
              style={{
                border: "1px black solid",
                overflowY: "auto",
                height: "35vh",

                width: "100%",
              }}
            >
              {mainStore.transactions.map((item, index) => {
                return (
                  <div
                    style={{
                      // borderTop: "2px #B0BEC5 solid",
                      // borderRadius: "6px",
                      marginBottom: "1px",
                      marginTop: "1px",
                    }}
                    key={index}
                  >
                    <Row justify="start">
                      <span style={{ width: "2%", marginLeft: "4px" }}>
                        <div
                          style={{
                            marginTop: "100%",
                          }}
                        >
                          {" "}
                          {item.attributes.suspicious[0] === "1" && (
                            <div
                              style={{
                                backgroundColor: "red",
                                width: "20px",
                                height: "20px",
                              }}
                            ></div>
                          )}
                          {item.attributes.suspicious[0] === "0" && (
                            <div
                              style={{
                                backgroundColor: "green",
                                width: "20px",
                                height: "20px",
                              }}
                            ></div>
                          )}
                        </div>
                      </span>

                      <Divider
                        style={{ height: "60px" }}
                        type="vertical"
                      ></Divider>
                      <span style={{ width: "9%" }}>
                        <Row>
                          <Col span={24}>
                            <div>{item.attributes.country[0]} </div>
                            {/* <div>{item.latitude} </div>
                          <div>{item.longitude} </div> */}
                            <div>
                              <Button
                                type="default" // Set the button type to "default" to get a white button
                                onClick={() => OnMap(item)} // Handle the click event
                                size="small"
                                style={{
                                  backgroundColor: "#354289",
                                  color: "white",
                                  marginLeft: "6px",
                                  height: "26px",
                                }} // Set the background color to white and font color to blue
                              >
                                <Row align="center">
                                  <span>
                                    {/* <RollbackOutlined
                          style={{ fontSize: "20px", marginRight: "4px" }}
                        /> */}
                                    <Icon
                                      style={{ width: "18px" }}
                                      path={mdiEarth}
                                    ></Icon>{" "}
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: "2px",
                                    }}
                                  >
                                    Map
                                  </span>{" "}
                                </Row>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </span>
                      <Divider
                        style={{ height: "60px" }}
                        type="vertical"
                      ></Divider>
                      <span style={{ width: "16%" }}>
                        <Row>
                          <Col span={24}>
                            <div
                              style={{
                                height: "60px",
                                overflowY: "auto",
                                border: "#B0BEC5 solid 1px",
                              }}
                            >
                              {item.attributes.amount &&
                                item.attributes.amount.length > 0 &&
                                item.attributes.amount.map((amount, index) => {
                                  return (
                                    <div>
                                      <Row>
                                        {" "}
                                        <span
                                          style={{
                                            marginLeft: "2px",
                                            display: "flex",
                                            alignItems: "center",
                                            fontWeight: "bold",
                                            width: "20%",
                                          }}
                                        >
                                          {amount}
                                        </span>
                                        <span
                                          style={{
                                            marginLeft: "4px",
                                            width: "75%",
                                          }}
                                        >
                                          {
                                            item.attributes.item_description[
                                              index
                                            ]
                                          }
                                        </span>
                                      </Row>
                                      <Divider
                                        style={{
                                          marginTop: "2px",
                                          marginBottom: "2px",
                                          backgroundColor: "black",
                                        }}
                                      ></Divider>
                                    </div>
                                  );
                                })}
                            </div>
                          </Col>
                        </Row>
                      </span>
                      <Divider
                        style={{ height: "60px" }}
                        type="vertical"
                      ></Divider>
                      <span style={{ width: "9%" }}>
                        <Row>
                          <Col span={24}>{item.attributes.timedate[0]}</Col>
                        </Row>
                      </span>
                      <Divider
                        style={{ height: "60px" }}
                        type="vertical"
                      ></Divider>
                      <span style={{ width: "19%" }}>
                        <div>
                          {" "}
                          <Row>
                            <Col style={{ fontWeight: "bold" }} span={8}>
                              Buyer :
                            </Col>
                            <Col span={10}>{item.attributes.buyer}</Col>
                          </Row>
                        </div>
                        <div>
                          {" "}
                          <Row>
                            <Col style={{ fontWeight: "bold" }} span={8}>
                              Licence :
                            </Col>
                            <Col span={10}>{item.attributes.buyerlicence}</Col>
                          </Row>
                        </div>
                        {/* <div>
                          {" "}
                          <Row>
                            <Col style={{ fontWeight: "bold" }} span={8}>
                              Card no. :
                            </Col>
                            <Col span={10}>{item.cardNumber}</Col>
                          </Row>
                        </div> */}
                      </span>
                      <Divider
                        style={{ height: "60px" }}
                        type="vertical"
                      ></Divider>
                      <span style={{ width: "19%" }}>
                        <div
                          style={{
                            marginTop: "5%",
                          }}
                        >
                          {" "}
                          <Row>
                            <Col style={{ fontWeight: "bold" }} span={6}>
                              Seller :
                            </Col>
                            <Col span={18}>{item.attributes.seller}</Col>
                          </Row>
                        </div>
                      </span>
                      <Divider
                        style={{ height: "60px" }}
                        type="vertical"
                      ></Divider>
                      <span style={{ width: "9%" }}>
                        <div
                          style={{
                            marginTop: "5%",
                          }}
                        >
                          {" "}
                          <Row>
                            <Col style={{ fontWeight: "bold" }} span={10}>
                              Id :
                            </Col>
                            <Col span={14}>
                              {item.id && item.id.length > 5 && (
                                <span>
                                  <span>
                                    {" "}
                                    <Tooltip title={item.id}>
                                      {item.id.substring(0, 5)}
                                    </Tooltip>
                                  </span>
                                  <span style={{ marginLeft: "1px" }}>
                                    {" "}
                                    ...{" "}
                                  </span>
                                </span>
                              )}
                              {item.id && item.id.length <= 5 && (
                                <Tooltip title={item.id}>{item.id}</Tooltip>
                              )}
                            </Col>
                          </Row>
                          {/* <Row>
                            <Col style={{ fontWeight: "bold" }} span={9}>
                              Hash :
                            </Col>
                            <Col span={15}>{item.hash}</Col>
                          </Row> */}
                        </div>
                      </span>
                      {/* <Divider
                        style={{ height: "60px", marginLeft: "-6px" }}
                        type="vertical"
                      ></Divider>
                      <span style={{ width: "4%" }}>
                        <div style={{ marginTop: "40%" }}>
                          <Button
                            type="default"
                            // onClick={() => showSTDItemsModal(item)}
                            // onClick={this.handleClick}
                            onClick={() => props.onClick(item)}
                            // onClick={() => console.log(item)}
                            size="small"
                            style={{
                              backgroundColor: "#354289",
                              color: "white",
                              display: "flex", // Use flexbox
                              alignItems: "center", // Align items vertically
                              gap: "4px", // Add gap between icon and text
                              paddingLeft: "8px", // Add left padding for icon alignment
                              paddingRight: "8px", // Add right padding for icon alignment
                              height: "26px",
                              marginLeft: "-2px",
                            }}
                          >
                            <Icon path={mdiReceiptTextOutline} size={1} />
                            <div style={{ marginLeft: "-2px" }}>Details</div>
                          </Button>
                        </div>
                      </span> */}
                    </Row>
                    <Divider
                      style={{
                        "background-color": "#90A4AE",

                        // borderTop: "2px #B0BEC5 solid",
                        // borderRadius: "6px",
                        marginBottom: "5px",
                        marginTop: "-3px",
                      }}
                    ></Divider>
                  </div>
                );
              })}
            </div>
            <div> </div>{" "}
            <Row
              style={{
                marginTop: "6px",
                marginBottom: "-16px",
              }}
            >
              <span>
                <div
                  style={{
                    backgroundColor: "red",
                    width: "20px",
                    height: "20px",
                  }}
                ></div>
              </span>
              <span
                style={{
                  marginLeft: "6px",
                }}
              >
                {" "}
                = suspicious transaction,
              </span>
              <span>
                <div
                  style={{
                    marginLeft: "6px",
                    backgroundColor: "green",
                    width: "20px",
                    height: "20px",
                  }}
                ></div>
              </span>
              <span
                style={{
                  marginLeft: "6px",
                }}
              >
                {" "}
                = Not suspicious transaction
              </span>{" "}
            </Row>
            {/*             <span
                style={{
                  width: "26px",
                }}
              >
                <Icon path={mdiHandshakeOutline}></Icon>
              </span>
              <span
                style={{
                  marginLeft: "6px",
                }}
              >
                {" "}
                = Done in person ,
              </span>
              <span
                style={{
                  width: "26px",
                }}
              >
                <Icon path={mdiWeb}></Icon>
              </span>
              <span
                style={{
                  marginLeft: "6px",
                }}
              >
                {" "}
                = Done online ,
              </span>
              <span
                style={{
                  width: "26px",
                }}
              >
                <Icon path={mdiTruck}></Icon>
              </span>
              <span
                style={{
                  marginLeft: "6px",
                }}
              >
                {" "}
                = Done by a Distributor
              </span>
            </Row> */}
          </div>
        ) : (
          <Row style={{ textAlign: "center" }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Spin tip="Searching..." />
            </Col>
          </Row>
        )}

        {/* <Divider orientation={"left"}>
          Displays the list of suspicious transactions as stored from the STD
          component in the SDMA DB
        </Divider>

        <Table
          columns={
            mainStore.transactions.length
              ? columns
              : getOverridedColumns(columns)
          }
          dataSource={
            mainStore.transactions.length
              ? mainStore.transactions.filter(
                  (item) => item.attributes.amount != "STRING"
                )
              : Array(5).fill({})
          }
          rowKey={() => uuidv4()}
          bordered={true}
          scroll={{ x: "400px" }}
        /> */}

        <Drawer
          title="Transaction on Map"
          placement="right"
          size={"large"}
          onClose={() => closeMap()}
          open={showmap}
          headerStyle={{ textAlign: "center" }}
        >
          <TransactionMap key={coords[0][0]} coords={coords} />
        </Drawer>
      </Col>
    </Row>
  );
};

export default observer(TransactionsSTD);
