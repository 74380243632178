import React, { useEffect } from "react";
import Icon from "@mdi/react";
import { mdiChartBubble } from "@mdi/js";
import { mdiPencil } from "@mdi/js";

import { Col, Row, Divider, Button, Table, Space, Popover, Tag } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import CreateMission from "../mission/CreateMission";
import { getOverridedColumns } from "../../utils/tableLoader";
import CreateOperation from "../mission/CreateOperation";
import { useNavigate } from "react-router-dom";
import { dateForTables } from "../../utils/formatDate";
import AddIngredient from "../../components/Transactions/AddIngredient";
import AddRecipe from "../../components/Transactions/AddRecipe";

var _ = require("lodash");

const MissionsTable = (props) => {
  const { transactionsStore, missionsStore } = useMobxStores();
  const navigate = useNavigate();

  useEffect(() => {
    transactionsStore.GetRecipes();
  }, []);

  useEffect(() => {
    transactionsStore.GetIngredients();
  }, []);

  const onAddIngredient = (record) => {
    transactionsStore.setIsIngredientModalOpen(true);
  };

  const onAddRecipe = (record) => {
    transactionsStore.setIsRecipeModalOpen(true);
  };
  const deleteRecipe = (record) => {
    console.log(record);
    transactionsStore.DeleteRecipeFromProject(record);
  };
  const deleteIngredient = (ingredient) => {
    console.log(ingredient);
    transactionsStore.DeleteIngredient(ingredient);
  };

  const columns = [
    {
      title: "Name - Alias",
      responsive: ["lg", "sm", "md", "xs"],
      render: (_, record) => (
        <div style={{ height: "20px", width: "100%" }}>
          {" "}
          <span style={{ width: "60%" }}>{record.name}</span>{" "}
          {record.attributes.alias[0] && (
            <span>
              <span style={{ marginLeft: "4px", marginRight: "4px" }}>-</span>
              <span> {record.attributes.alias[0]}</span>
            </span>
          )}
        </div>
      ),
      width: "300px",
    },
    {
      title: (
        <div>
          {" "}
          <div>Ingredients </div> <div>(Percentage - Name -Threshold)</div>
        </div>
      ),
      responsive: ["lg", "sm", "md", "xs"],
      render: (_, record) => (
        <div
          style={{
            height: "60px",
            overflowY: "auto",
            border: "#B0BEC5 solid 1px",
          }}
        >
          {JSON.parse(record.attributes.ingredients[0]).map((ingredient) => {
            return (
              <Row>
                <div style={{ height: "20px", width: "100%" }}>
                  {" "}
                  <span style={{ width: "60%" }}>
                    <span> {ingredient.amount}%</span>
                  </span>
                  <span style={{ marginLeft: "4px", marginRight: "4px" }}>
                    -
                  </span>
                  <span style={{ marginLeft: "4px" }}>{ingredient.name}</span>
                  <span style={{ marginLeft: "4px", marginRight: "4px" }}>
                    -
                  </span>
                  <span> {ingredient.threshold}</span>
                </div>
                <Divider
                  style={{ marginTop: "2px", marginBottom: "2px" }}
                ></Divider>
              </Row>
            );
          })}
        </div>
      ),
      width: "300px",
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              deleteRecipe(record);
            }}
            type="default" // Set the button type to "default" to get a white button
            // onClick={() => missionsStore.DeleteMission(record.id)}
            size="medium"
            style={{
              width: "60px",
              backgroundColor: "red",
              color: "white",
              marginLeft: "6px",
            }} // Set the background color to white and font color to blue
          >
            <Row align="center">
              <span>
                <DeleteFilled
                  style={{ fontSize: "16px", marginRight: "0px" }}
                />
              </span>
            </Row>
          </Button>

          {/* <Button
            type="default" // Set the button type to "default" to get a white button
            size="medium"
            style={{
              backgroundColor: "blue",
              color: "white",
              marginLeft: "6px",
            }} // Set the background color to white and font color to blue
          >
            <Row align="center">
              <span>
                <Icon style={{ color: "white" }} path={mdiPencil} size={1} />
              </span>
              <span>Edit</span>{" "}
            </Row>
          </Button> */}
        </Space>
      ),
      width: "70px",
    },
  ];

  return (
    <div>
      <div>
        <Row justify="start" align="start" gutter={[20, 20]}>
          <Col span={16} lg={16} md={16} xs={24} sm={24}>
            <Row
              style={{
                marginTop: "4px",
                // backgroundColor: "#003B73",
                // color: "white",
                height: "40px",
                marginBottom: "24px",
                marginTop: "-12px",
              }}
              justify="space-between"
            >
              <span
                style={{
                  width: "180px",
                }}
              ></span>
              <span
                style={{
                  fontSize: "24px",
                  marginTop: "18px",
                }}
              >
                Recipes
              </span>
              <span style={{ marginTop: "16px" }}>
                <span style={{ marginRight: "4px" }}>
                  <Button
                    type="default" // Set the button type to "default" to get a white button
                    size="medium"
                    onClick={() => transactionsStore.setIsRecipeModalOpen(true)}
                    style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
                  >
                    <div align="center">
                      <span>
                        <PlusOutlined
                          style={{
                            fontSize: "20px",
                            marginRight: "4px",
                            color: "white",
                          }}
                        />
                      </span>
                      <span>Add Recipe</span>
                    </div>
                  </Button>
                </span>
              </span>
            </Row>{" "}
            <Row justify="center">
              <AddRecipe />
              <AddIngredient />
              {/* <Divider orientation="left">Missions</Divider> */}
              {transactionsStore.Recipes.length === 0 && <div> </div>}
              {transactionsStore.Recipes.length !== 0 && (
                <Table
                  className="custom-table"
                  style={{ width: "100%" }}
                  size="small"
                  indentSize={12}
                  bordered={true}
                  scroll={{ x: "400px" }}
                  rowClassName="custom-row-hover"
                  dataSource={
                    transactionsStore.Recipes != null
                      ? _.cloneDeep(transactionsStore.Recipes)
                      : Array(5).fill({})
                  }
                  columns={
                    transactionsStore.Recipes != null
                      ? columns
                      : getOverridedColumns(columns)
                  }
                  rowKey={(record) => record.id}
                />
              )}
            </Row>
          </Col>
          <Divider style={{ height: "500px" }} type="vertical"></Divider>
          <Col span={5} lg={5} md={5} xs={24} sm={24}>
            {" "}
            <Row>
              <span style={{ marginTop: "6px" }}>
                <span style={{ marginRight: "4px" }}>
                  <Button
                    type="default" // Set the button type to "default" to get a white button
                    size="medium"
                    onClick={() =>
                      transactionsStore.setIsIngredientModalOpen(true)
                    }
                    style={{ backgroundColor: "#354289", color: "white" }} // Set the background color to white and font color to blue
                  >
                    <div align="center">
                      <span>
                        <PlusOutlined
                          style={{
                            fontSize: "20px",
                            marginRight: "4px",
                            color: "white",
                          }}
                        />
                      </span>
                      <span>Add Ingredient</span>
                    </div>
                  </Button>
                </span>
              </span>
            </Row>
            <div
              style={{
                marginTop: "16px",
                color: "black",
                border: "solid 1px grey",
                minHeight: "200px",
              }}
            >
              {/* style=
              {{
                marginTop: "4px",
                // backgroundColor: "#003B73",
                // color: "white",
                height: "40px",
                marginBottom: "24px",
                marginTop: "-12px",
              }} */}
              <div
                style={{
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  backgroundColor: "#003B73",
                  color: "white",
                }}
              >
                {" "}
                <Row>
                  <span style={{ marginLeft: "6px" }}> Ingredients </span>
                </Row>
              </div>
              <div
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                  //   display: "flex",
                  //   justify: "center",
                }}
              >
                {transactionsStore.Ingredients.length > 0 &&
                  transactionsStore.Ingredients.map((ingredient) => {
                    return (
                      <div>
                        <div>
                          <span style={{ marginLeft: "6px" }}>
                            {ingredient.title}
                          </span>
                          {ingredient.attributes &&
                            ingredient.attributes.threshold && (
                              <span style={{ marginLeft: "6px" }}>
                                - {ingredient.attributes.threshold[0]}
                              </span>
                            )}
                          <span style={{ width: "20%", color: "red" }}>
                            {" "}
                            <DeleteFilled
                              onClick={() => {
                                deleteIngredient(ingredient);
                              }}
                              style={{ fontSize: "16px", marginRight: "0px" }}
                            />
                          </span>
                        </div>
                        <Divider
                          style={{ marginTop: "0px", marginBottom: "0px" }}
                        ></Divider>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default observer(MissionsTable);
