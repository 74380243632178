import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet.heat";
import "leaflet/dist/leaflet.css";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react-lite";

const HeatmapLayer = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    const heatLayer = L.heatLayer(points, {
      radius: 25,
      blur: 15,
      maxZoom: 6,
    }).addTo(map);

    return () => {
      map.removeLayer(heatLayer);
    };
  }, [map, points]);

  return null;
};

const Heatmap = observer(() => {
  const { transactionsStore } = useMobxStores();
  // const [points, setPoints] = useState(transactionsStore.ETD_HeatMap_realtime);
  const [points, setPoints] = useState(
    transactionsStore.SelectedRecipeETD_HeatMap_realtime
  );

  useEffect(() => {
    // Update the points from the store every 5 seconds
    const interval = setInterval(() => {
      // setPoints([...transactionsStore.ETD_HeatMap_realtime]); // Update heatmap points
      setPoints(transactionsStore.SelectedRecipeETD_HeatMap_realtime); // Update heatmap points
    }, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [transactionsStore]);

  return (
    <MapContainer
      center={[51.35084, -1.99421, 0.7149372494898543]} // Customize this to your desired center
      zoom={6}
      style={{ height: "60vh", width: "50vw", marginLeft: "40px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <HeatmapLayer points={points} />
    </MapContainer>
  );
});

export default Heatmap;
