import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import axios from "axios";
import keycloak from "../../keycloak/keycloak";
import { Card, Row, Col, Divider } from "antd";
import { NavLink } from "react-router-dom";

const mapStyle = { height: "85vh", borderRadius: "15px" };

const MainMap = (props) => {
  const { mainStore,textStore } = useMobxStores();
  const [maxBounds, setMaxBounds] = useState(null);

  const dummy_data_to_certh = [
    {
      eventID: "6c1c9e5d01ad4eb5aab920e3c21e0fae",
      timeUTC: "2023-01-18T10:16:09z",
      title: "chemical detected",
      detectorStatus: "OK",
      value: 1025,
      units: "mV",
      lat: "23.123456",
      lon: "23.123456",
      alt: "100",
      compound: "test",
    },
  ];

  useEffect(() => {
    axios
      .get(
        "https://cdn.rawgit.com/johan/world.geo.json/34c96bba/countries/GRC.geo.json"
      )
      .then((response) => {
        const geoJSON = response.data;
        setMaxBounds(L.geoJSON(geoJSON).getBounds());
      });
  }, [setMaxBounds, mainStore.markers]);

  return maxBounds ? (
    <div id="map">
      {() => keycloak.init()}

      <MapContainer
        zoom={5}
        minZoom={1}
        scrollWheelZoom={false}
        bounds={maxBounds}
        // maxBounds={maxBounds}
        style={mapStyle}
        center={["23.123456", "23.123456"]}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {dummy_data_to_certh.map((item, index) => {
          return item.lon !== null && item.lat !== null ? (
            <Marker
              key={index}
              position={[item.lon, item.lat]}
              icon={
                new L.divIcon({
                  iconSize: "auto",
                  html: `<p style={{color:red}}>${item.value}</p>`,
                  bgPos: [0, 0],
                  className: "leaflet-div-icon",
                })
              }
            >
              <Popup style={{ width: "max-content" }}>
                <Card
                  title={item.title}
                  style={{
                    width: "auto",
                    minWidth: "300px",
                    textAlign: "left",
                  }}
                  bodyStyle={{
                    width: "auto",
                    textAlign: "left",
                    overflow: "scroll",
                    maxHeight: "60vh",
                    height: "auto",
                  }}
                >
                <p><b>Event ID:</b> {item.eventID}</p>
                <p><b>Time UTC:</b>  {item.timeUTC}</p>
                <p><b>Detector Status:</b> {item.detectorStatus}</p>
                <p><b>Value:</b>  {item.value}</p>
                <p><b>Units: </b> {item.units}</p>
                <p><b>Alt:</b>  {item.alt}</p>
                <p><b>Compound:</b>  {item.compound}</p>
                </Card>
              </Popup>
            </Marker>
          ) : null;
        })}
      </MapContainer>
    </div>
  ) : (
    <></>
  );
};

export default observer(MainMap);
