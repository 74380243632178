import { makeAutoObservable } from "mobx";
import {
  POST,
  PUBLISH,
  GET_SDMA,
  POST_SDMA,
  DELETE_SDMA,
  PUT_SDMA,
  GET_SDMA_IMAGE,
  POST_Test,
  keycloakApi,
} from "../apis/apis";
import { constants } from "../consts/Variables";
import { v4 as uuidv4 } from "uuid";
const currentDate = new Date();
const formattedDate = currentDate.toISOString().slice(0, 19) + "+0000";
class MainStore {
  constructor({ errStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
  }

  showGroups = false;

  Recipes = [];
  Ingredients = [];
  allUsers = [];

  gas = null;

  isRecipeModalOpen = false;
  isIngredientModalOpen = false;
  UsersProjectId = "";
  analyzedCharacters = [];
  storedMissions = null;
  alertsOn = false;
  selectedKey = "1";
  dateFormat = "YYYY/MM/DD";
  status = "success";
  audit_items = [];
  audit_item = null;
  transaction = null;
  transactions = [];
  webdata = [];
  field_operation = null;
  changeLogItem = [];
  artefacts = [];
  projects = [];
  artefact = null;
  entities = [];
  entity = null;
  links = [];
  link = null;
  accessLogItem = [];
  currentKey = window.location.pathname;

  pageSize = 100000000;
  waterSensorData = [];
  searchedData = [];
  isSearching = false;
  battery = "";
  notifications = [];
  platform_administrators = [];

  temp = { body: null, images: [] };

  wp_connection = false;

  analyse = false;

  isMissionModalOpen = false;
  isOperationModalOpen = false;
  projectIdForClustering = "";
  projectIdForSN = "";
  snDomainId = "";
  currentUser = "";

  web_analysts = [];
  transaction_analysts = [];
  field_operators = [];

  SetCurrentUser = (user) => {
    this.currentUser = user;
  };
  CreateUser = (values) => {
    // this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.GetUsers();
        // this.setIsMissionModalOpen(false);
        // this.errStore.setInfo(true);
        // this.errStore.setMessage("Mission Created!");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  AddUserToProject = (values) => {
    console.log(values);
    this.errStore.setLoading(true);
    let attributes = {
      mission_commander: [false],
      webanalyst: [false],
      transaction_analyst: [false],
      field_operator: [false],
      platform_administrator: [false],
    };
    if (values.type.includes("mission_commander")) {
      attributes.mission_commander = [true];
    }
    if (values.type.includes("webanalyst")) {
      attributes.webanalyst = [true];
    }
    if (values.type.includes("transaction_analyst")) {
      attributes.transaction_analyst = [true];
    }
    if (values.type.includes("platform_administrator")) {
      attributes.platform_administrator = [true];
    }
    if (values.type.includes("field_operator")) {
      attributes.field_operator = [true];
    }
    console.log(attributes);
    if (values.type)
      return POST_SDMA(`/api/projects/${this.UsersProjectId}/entities`, {
        attributes: attributes,
        domainId: values.domainId,
        name: values.name,
      })
        .then((data) => {
          console.log(data);
          this.SetCurrentUser(values);
          console.log(this.currentUser);
          this.errStore.setInfo(true);
          this.errStore.setMessage("User added to mission!");
        })
        .catch((error) => {
          this.errStore.setError(true);
          this.errStore.setMessage("SDMA ERROR");
          console.log(error);
        });
  };
  CreateMission = (values) => {
    this.errStore.setLoading(true);
    POST_SDMA(`/api/projects`, values)
      .then((data) => {
        console.log(data);
        this.UsersProjectId = data.data.id;
        // return data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetProjectIdOfUsers = () => {
    this.errStore.setLoading(true);
    return GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data);
        let projects = data.data;
        let foundMission = projects.find((project) => {
          return project.name === "UsersDataBase";
        });
        console.log(foundMission);

        if (!foundMission) {
          this.CreateMission({
            name: "UsersDataBase",
            description: "UsersDataBase",
            status: "Enabled",
          });
        } else {
          this.UsersProjectId = foundMission.id;
          console.log(this.UsersProjectId);
          this.GetAllUsers();
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetAllUsers = () => {
    return GET_SDMA(`/api/projects/${this.UsersProjectId}/entities`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        this.allUsers = data.data;
        return data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  DeleteUser = (id) => {
    return DELETE_SDMA(`/api/projects/${this.UsersProjectId}/entities/${id}`)
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        // this.allUsers = [data.data];
        return data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetPlatformAdministrators = () => {
    return GET_SDMA(
      `/api/projects/${this.UsersProjectId}/entities?type=platform_administrator`
    )
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        this.platform_administrators = data.data;
        return data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetTransactionAnalysts = () => {
    return GET_SDMA(
      `/api/projects/${this.UsersProjectId}/entities?type=transaction_analyst`
    )
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        this.transaction_analysts = data.data;
        return data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetMissionCommanders = () => {
    return GET_SDMA(
      `/api/projects/${this.UsersProjectId}/entities?type=mission_commander`
    )
      .then((data) => {
        this.errStore.setLoading(false);
        return data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetWebAnalysts = () => {
    return GET_SDMA(
      `/api/projects/${this.UsersProjectId}/entities?type=webanalyst`
    )
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        this.web_analysts = data.data;
        return data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetTransactionAnalysts = () => {
    return GET_SDMA(
      `/api/projects/${this.UsersProjectId}/entities?type=transaction_analyst`
    )
      .then((data) => {
        this.errStore.setLoading(false);
        console.log(data.data);
        this.transaction_analysts = data.data;
        return data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetMissionCommanders = () => {
    return GET_SDMA(
      `/api/projects/${this.UsersProjectId}/entities?type=mission_commander`
    )
      .then((data) => {
        this.errStore.setLoading(false);
        return data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // get project
  GetProject = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setMessage("");
        this.projects = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Artefacts
  GetArtefacts = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefacts = data.data;
        this.transaction = data.data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Entities

  CreateEntities = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/entities`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.entities.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };
  // audit Items

  GetAuditItems = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/audit?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        // this.audit_items = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetAuditItemsById = (id) => {
    GET_SDMA(`/api/auditItems/${id}`)
      .then((data) => {
        this.audit_item = data;
      })
      .catch(this.errStore.handlerErrors);
  };

  // Change Log Items

  CreateChangeLogItem = (values) => {
    POST_SDMA(`/api/changeLogItem`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Access Log Items

  CreateAccessLogItem = (values) => {
    POST_SDMA(`/api/accessLogItem`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.accessLogItem.push(data);
        this.errStore.handlerCussess("AccessLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Change Log Items

  PostKafka = (values) => {
    POST(`/test`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setInfo(true);
        this.errStore.handlerCussess("Message Published");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("Something went wrong");
      });
  };

  // Publish to kafka

  Publish = (values) => {
    PUBLISH(`/kafka/publish`, values)
      .then((data) => {
        // this.errStore.setMessage(data);
        // this.errStore.setInfo(true);
      })
      .catch(this.errStore.handlerErrors);
  };

  isAnalysing = false;

  setIsAnalyzing = (value) => {
    this.isAnalysing = value;
  };

  isAnalysingFinished = false;

  setIsAnalyzingFinished = (value) => {
    this.isAnalysingFinished = value;
  };

  setAnalyseModal = (value) => {
    this.analyseModal = value;
  };
}

export default MainStore;
