import React, { useEffect, useState } from "react";
import { Row } from "antd";

import PageFilter from "./PageFilter";
import CommentsShown from "./CommentsShown";
import PagesPagination from "./PagesPagination";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { useParams } from "react-router-dom";

const TextTranslationPage = (props) => {
  const { mainStore, webSocialMediaStore, errorStore } = useMobxStores();

  const [text_words, setTextWords] = useState([]);

  const [pageShown, setPageShown] = useState(-1);
  const [itemShown, setItemShown] = useState("");

  const [content, setContent] = useState(null);
  const [textTranslation, setTextTranslation] = useState(
    mainStore.textTranslation
  );

  var _ = require("lodash");
  let params = useParams();
  // useEffect(() => {
  //   console.log(params.pk);
  //   webSocialMediaStore.setProjectIdForSocial(params.pk);
  // }, []);
  // useEffect(() => {
  //   console.log(params.pk);
  //   webSocialMediaStore.setProjectIdForSocial(params.pk);
  // }, [params.id]);
  // Function to handle sorting based on classification

  return (
    <div
      style={{
        border: "1px black solid",
        overflowY: "auto",
        // minHeight: "380px",
        height: "100%",
        width: "100%",
      }}
    >
      <div style={{ backgroundColor: "#CFD8DC", color: "black" }}>
        <Row justify="center" align="center">
          Comments And Replies
        </Row>
      </div>
      {/* <PageFilter></PageFilter> */}
      {/* <PagesPagination></PagesPagination> */}
      <CommentsShown></CommentsShown>
    </div>
  );
};

export default observer(TextTranslationPage);
