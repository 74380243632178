import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Divider,
  Button,
  Tag,
  Tooltip,
  Image,
  Tree,
  Radio,
  Checkbox,
} from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { mdiBomb } from "@mdi/js";
import PieChartComponent from "../../components/web-crawl/PieChartComments";
import Loader from "../../components/loader/Loader";
import EmptyData from "../../components/emptydata/EmptyData";
import { mdiTranslate, mdiGraph, mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";

import { mdiTextBoxSearchOutline } from "@mdi/js";
import { mdiCommentOutline, mdiReplyOutline } from "@mdi/js";
import { dateForTables } from "../../utils/formatDate";

import { useParams } from "react-router-dom";

import { mdiYoutube } from "@mdi/js";
import { mdiAccount } from "@mdi/js";
import { mdiReddit } from "@mdi/js";
import { mdiClose } from "@mdi/js";
import { mdiDotsVertical } from "@mdi/js";
import Confer from "../../components/web-crawl/ConferComment";
import Ref from "../../components/web-crawl/RefComment";

const ButtonGroup = Button.Group;

{
  /* <Icon path={mdiYoutube} size={1} /> */
}

const TextTranslationPage = (props) => {
  const { mainStore, webSocialMediaStore } = useMobxStores();

  const [pageAnalysisView, setPageAnalysisView] = useState("ner");

  const [text_words, setTextWords] = useState([]);

  const [postTranslation, setPostTranslation] = useState("");

  const [groupBlueChecked, setGroupBlueChecked] = useState(true);
  const [groupOrangeChecked, setGroupOrangeChecked] = useState(true);
  const [groupRedChecked, setGroupRedChecked] = useState(true);
  const [groupLimeChecked, setGroupLimeChecked] = useState(true);
  const [groupPurpleChecked, setGroupPurpleChecked] = useState(true);
  const [groupGreenChecked, setGroupGreenChecked] = useState(true);
  const [groupCyanChecked, setGroupCyanChecked] = useState(true);
  const [groupYellowChecked, setGroupYellowChecked] = useState(true);

  const onCheckBlue = (e) => {
    setGroupBlueChecked(e.target.checked);
  };
  const onCheckOrange = (e) => {
    setGroupOrangeChecked(e.target.checked);
  };
  const onCheckRed = (e) => {
    setGroupRedChecked(e.target.checked);
  };
  const onCheckLime = (e) => {
    setGroupLimeChecked(e.target.checked);
  };
  const onCheckPurple = (e) => {
    setGroupPurpleChecked(e.target.checked);
  };
  const onCheckGreen = (e) => {
    setGroupGreenChecked(e.target.checked);
  };
  const onCheckCyan = (e) => {
    setGroupCyanChecked(e.target.checked);
  };
  const onCheckYellow = (e) => {
    setGroupYellowChecked(e.target.checked);
  };

  const renderedText = webSocialMediaStore.textWords.map((word, index) => {
    const matchedItem = webSocialMediaStore.analyse_entities.find(
      (item) => item.text == word
    );
    if (matchedItem && matchedItem.color === "red") {
      return (
        <span
          key={index}
          style={{
            backgroundColor: matchedItem.color,
            marginLeft: "3px",
            marginRight: "3px",
            marginTop: "3px",
            marginBottom: "3px",
            color: "white",
          }}
        >
          {word}
        </span>
      );
    }
    if (matchedItem && matchedItem.color === "#00008B") {
      return (
        <span
          key={index}
          style={{
            backgroundColor: matchedItem.color,
            marginLeft: "3px",
            marginRight: "3px",
            marginTop: "3px",
            marginBottom: "3px",
            color: "white",
          }}
        >
          {word}
        </span>
      );
    }
    if (
      (matchedItem && matchedItem.color !== "red") ||
      (matchedItem && matchedItem.color !== "#00008B")
    ) {
      return (
        <span
          key={index}
          style={{
            backgroundColor: matchedItem.color,
            marginLeft: "3px",
            marginRight: "3px",
            marginTop: "3px",
            marginBottom: "3px",
            color: "black",
          }}
        >
          {word}
        </span>
      );
    }

    return (
      <span
        key={index}
        style={{
          marginLeft: "3px",
          marginRight: "3px",
        }}
      >
        {word}
      </span>
    );
  });
  const [pageShown, setPageShown] = useState(1);
  const [itemShown, setItemShown] = useState("");
  const [treeData, setTreeData] = useState([]);

  const [content, setContent] = useState(null);
  const [filteredItems, setFilteredItems] = useState(
    webSocialMediaStore.filteredItems
  );
  const [loading, setLoading] = useState(false);
  const [loadingNewWeb, setLoadingNewWeb] = useState(false);
  const [clusterClicked, setClusterClicked] = useState("");
  const [filteredPagesByCluster, setFilteredPagesByCluster] = useState([]);
  const [IdShown, setIdShown] = useState("");
  const [translationText, setTranslationText] = useState("");

  const [analysis, setAnalysis] = useState(webSocialMediaStore.analysis);
  const [replies, setReplies] = useState(webSocialMediaStore.replies);
  const [post, setPost] = useState("");
  const [classification, setClassification] = useState("");
  const [url, setUrl] = useState("");
  const [postDomainId, setPostDomainId] = useState("");
  const [postPublished, setPostPublished] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postAuthor, setPostAuthor] = useState("");

  const [visibleAnalysis, setVisibleAnalysis] = useState("");
  const [bubbleType, setBubbleType] = useState("replies");

  var _ = require("lodash");
  let params = useParams();

  const [itemsToShow, setItemsToShow] = useState([]);

  const [repliesToShow, setRepliesToShow] = useState(
    webSocialMediaStore.repliesObjects
  );

  useEffect(() => {
    setFilteredItems(webSocialMediaStore.filteredItems);
    console.log(webSocialMediaStore.filteredItems);
  }, [webSocialMediaStore.filteredItems]);

  useEffect(() => {
    setRepliesToShow(webSocialMediaStore.repliesObjects);
  }, [webSocialMediaStore.repliesObjects]);

  useEffect(() => {
    setAnalysis(webSocialMediaStore.analysis);
    console.log(webSocialMediaStore.analysis);
  }, [webSocialMediaStore.analysis]);

  useEffect(() => {
    setPageShown(webSocialMediaStore.webCrawlPaginationPage);
  }, [webSocialMediaStore.webCrawlPaginationPage]);

  useEffect(() => {
    setReplies(webSocialMediaStore.replies);
  }, [webSocialMediaStore.replies]);

  useEffect(() => {
    if (webSocialMediaStore.filteredPosts.length > 0) {
      let newPost = webSocialMediaStore.filteredPosts.find((post) => {
        return post.content;
      });
      if (newPost) {
        setPost(newPost.content);
        setPostDomainId(newPost.domainId);
        setPostPublished(newPost.published);
        setPostTitle(newPost.title);
        setClassification(newPost.classification);
        setPostAuthor(newPost.name);
        if (newPost.attributes && newPost.attributes.url) {
          setUrl(newPost.attributes.url[0]);
        } else {
          setUrl("No url found ");
        }
        if (newPost.attributes && newPost.attributes.translation) {
          setPostTranslation(newPost.attributes.translation[0]);
        } else {
          setPostTranslation("No translation found");
        }
      } else {
        setPost("No main post found on the webpage");
        setUrl("No url found ");
      }
    } else {
      setPost("No main post found on the webpage");
      setUrl("No url found ");
    }
  }, [webSocialMediaStore.filteredPosts]);
  // useEffect(() => {
  //   if (webSocialMediaStore.url) {
  //   } else {
  //     setUrl("No url found");
  //   }
  // }, [webSocialMediaStore.url]);

  useEffect(() => {
    let treeDataNew = [];
    let commentIndex = 0;
    for (let i = 0; i < webSocialMediaStore.filteredItems.length; i++) {
      if (webSocialMediaStore.filteredItems[i].type.includes("comment")) {
        commentIndex++;
        let childrenNew = [];
        if (
          webSocialMediaStore.filteredItems[i].attributes &&
          webSocialMediaStore.filteredItems[i].attributes.sub_domainIds &&
          webSocialMediaStore.filteredItems[i].attributes.sub_domainIds.length >
            0
        ) {
          for (
            let y = 0;
            y <
            webSocialMediaStore.filteredItems[i].attributes.sub_domainIds
              .length;
            y++
          ) {
            childrenNew.push({
              title:
                "reply " +
                webSocialMediaStore.filteredItems[i].attributes.sub_domainIds[
                  y
                ],
              key: i + "-" + y,

              // onSelect: () => {
              //   console.log(
              //     webSocialMediaStore.filteredItems[i].attributes.sub_domainIds[
              //       y
              //     ]
              //   );
              // },
              icon: ({ selected }) =>
                selected ? (
                  <Icon path={mdiReplyOutline} size={1} />
                ) : (
                  <Icon path={mdiReplyOutline} size={1} />
                ),
            });
          }
        }
        treeDataNew.push({
          domainId: webSocialMediaStore.filteredItems[i].domainId,
          authorTitle: webSocialMediaStore.filteredItems[i].title,
          title: (
            <div>
              <Row
                style={{
                  display: "flex",
                  marginTop: "-28px",
                  alignItems: "center",
                  marginLeft: "28px",
                }}
              >
                <span>
                  {"Comment " +
                    commentIndex +
                    ": Content: " +
                    webSocialMediaStore.filteredItems[i].content}
                </span>
                <span>
                  <Icon
                    color="blue"
                    path={mdiTranslate}
                    size={1}
                    style={{
                      // display: "flex",
                      marginTop: "4px",
                      // alignItems: "center",
                      marginLeft: "4px",
                    }}
                  />
                </span>
                <span>
                  <Icon
                    color="orange"
                    onClick={() => handleSearchClick(i)}
                    path={mdiTextBoxSearchOutline}
                    size={1}
                    style={{
                      // display: "flex",
                      marginTop: "4px",
                      // alignItems: "center",
                      marginLeft: "4px",
                    }}
                  />
                </span>
                <span>
                  <Icon
                    color="green"
                    onClick={() => handleSearchClick(i)}
                    path={mdiAccount}
                    size={1}
                    style={{
                      // display: "flex",
                      marginTop: "4px",
                      // alignItems: "center",
                      marginLeft: "4px",
                    }}
                  />
                </span>
              </Row>
              {visibleAnalysis === i && (
                <div style={{ marginLeft: "28px" }}> hello </div>
              )}
              {visibleAnalysis !== i && (
                <div style={{ marginLeft: "28px" }}> </div>
              )}
            </div>
          ),
          key: i,
          icon: <Icon path={mdiCommentOutline} size={1} />,
          children: childrenNew,
        });
      }
    }
    setTreeData(treeDataNew);
  }, [webSocialMediaStore.filteredItems]);

  const handleSearchClick = (key) => {
    console.log(key);
    setVisibleAnalysis(key);
    console.log(webSocialMediaStore.filteredItems[key]);
  };
  const showReport = (item) => {
    webSocialMediaStore.setWebCrawlPaginationPageToReport(item);
  };

  const closeSubContent = () => {
    setIdShown("");
  };

  const showAnalysis = (bubbleType, item) => {
    if (item !== "post") {
      setPageAnalysisView("ner");
      setBubbleType(bubbleType);
      setTextWords(item.content.split(" "));
      webSocialMediaStore.setTextWords(item.content.split(" "));
      webSocialMediaStore.setCharachters(
        item.attributes.translation
          ? item.attributes.translation[0].split("")
          : item.content.split("")
      );
      // let post2511 =
      //   "The deacylation step was where I found that I could not reproduce the process. I boiled the nitration product for an hour with 20% sulfuric acid as detailed. Upon cooling a large amount of un-deacylated material crystallized. The clear red supernatant still precipitated a quantity of the expected product upon partial neutralization (asdetailed) with ammonia. At this point I stopped with the process.";
      // setTextWords(post2511.split(" "));
      // webSocialMediaStore.setTextWords(post2511.split(" "));

      setIdShown(item.id);
      webSocialMediaStore.GetAnalyse(params.pk, item.domainId);
      setItemShown(item);
      // webSocialMediaStore.GetConfer(props.pk, props.domainId);
    } else {
      setBubbleType(bubbleType);
      setTextWords(post.split(" "));
      webSocialMediaStore.setTextWords(post.split(" "));
      webSocialMediaStore.setCharachters(
        postTranslation === "No translation found"
          ? post.split("")
          : postTranslation.split("")
        //   : item.content
      );

      // let post2511 =
      //   "The deacylation step was where I found that I could not reproduce the process. I boiled the nitration product for an hour with 20% sulfuric acid as detailed. Upon cooling a large amount of un-deacylated material crystallized. The clear red supernatant still precipitated a quantity of the expected product upon partial neutralization (asdetailed) with ammonia. At this point I stopped with the process.";
      // setTextWords(post2511.split(" "));
      // webSocialMediaStore.setTextWords(post2511.split(" "));

      setIdShown("post");
      webSocialMediaStore.GetAnalyse(params.pk, postDomainId);
    }

    // webSocialMediaStore.setWebCrawlPaginationPageToReport(item);
  };

  const showTranslation = (bubbleType, item) => {
    setBubbleType(bubbleType);
    if (item !== "post") {
      setIdShown(item.id);
      if (
        item.attributes &&
        item.attributes.translation &&
        item.attributes.translation[0]
      ) {
        setTranslationText(item.attributes.translation[0]);
      } else {
        setTranslationText("No translation yet");
      }
    } else {
      setIdShown("post");
      if (
        post.attributes &&
        post.attributes.translation &&
        post.attributes.translation[0]
      ) {
        setTranslationText(post.attributes.translation[0]);
      } else {
        setTranslationText("No translation yet");
      }
    }

    // webSocialMediaStore.setWebCrawlPaginationPageToReport(item);
  };

  return (
    <div
      style={{
        wordBreak: "break-word",
        height: "410px",
        height: "auto",
      }}
    >
      <div
        style={{
          wordBreak: "break-word",
          width: "100%",

          padding: "6px",
        }}
      >
        {" "}
        {(filteredItems[0].type.includes("youtube") ||
          filteredItems[1].type.includes("youtube")) && (
          <Row justify="center" align="center">
            <span>
              <Icon path={mdiYoutube} size={2} color="red" />
            </span>
            <span style={{ fontSize: "28px" }}> Youtube</span>
          </Row>
        )}
        {(filteredItems[0].type.includes("reddit") ||
          filteredItems[1].type.includes("reddit")) && (
          <Row justify="center" align="center">
            <span>
              <Icon path={mdiReddit} size={2} color="#FB8C00" />
            </span>
            <span style={{ fontSize: "28px" }}> Reddit</span>
          </Row>
        )}
        {(filteredItems[0].type.includes("tiktok") ||
          filteredItems[1].type.includes("tiktok")) && (
          <Row justify="center" align="center">
            <span>
              <Image
                src="/tiktok-color-icon.svg"
                width={"40px"}
                height={"40px"}
                preview={false}
              />
            </span>
            <span style={{ fontSize: "28px" }}> tiktok</span>
          </Row>
        )}
      </div>
      <div
        style={{
          marginTop: "-12px",
          marginBottom: "10px",
          fontWeight: "bold",
          marginLeft: "6px",
        }}
      >
        <span style={{ fontWeight: "bold" }}> Url: </span>
        {url && (
          <span>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {" "}
              {url}{" "}
            </a>
          </span>
        )}
      </div>
      <Divider
        style={{
          marginTop: "-12px",
        }}
      ></Divider>
      <div
        style={{
          marginTop: "-12px",
          marginBottom: "4px",
          fontWeight: "bold",
          marginLeft: "6px",
        }}
      >
        {post && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                textAlign: "start", // Optional: Ensures the text inside the span is centered
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                created by
                {postAuthor && postAuthor.length > 20 && (
                  <span style={{ fontWeight: "bold" }}>
                    <span>
                      {" "}
                      <Tooltip title={postAuthor}>
                        {postAuthor.substring(0, 20)}
                      </Tooltip>
                    </span>
                    <span style={{ marginLeft: "1px" }}> ... </span>
                  </span>
                )}
                {postAuthor && postAuthor.length <= 20 && (
                  <Tooltip style={{ fontWeight: "bold" }} title={postAuthor}>
                    {postAuthor}
                  </Tooltip>
                )}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center", // Optional: Ensures the text inside the span is centered
              }}
            >
              <span style={{ fontWeight: "bold" }}>{postTitle}</span>
            </div>

            <div>
              <span style={{ marginLeft: "6px", fontWeight: "normal" }}>
                {post}
              </span>
            </div>
            <div>
              {" "}
              {classification && (
                <span>
                  <span style={{ fontWeight: "bold" }}>
                    <Tag
                      style={{
                        width: "140px",
                        height: "28px",
                      }}
                      color="orange"
                    >
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyItems: "center",
                        }}
                      >
                        <span>
                          <Icon path={mdiBomb} size={1} />
                        </span>
                        <span>{classification}</span>
                      </Row>
                    </Tag>
                  </span>
                </span>
              )}
            </div>
            <div>
              <span>
                <span
                  style={{
                    width: "180px",
                  }}
                >
                  {dateForTables(postPublished)}
                </span>

                <span
                  style={{
                    marginLeft: "4px",
                  }}
                >
                  <Button
                    onClick={() => showTranslation("translation", "post")}
                  >
                    Translate <Icon color="blue" path={mdiTranslate} size={1} />
                  </Button>
                </span>
                <span
                  style={{
                    marginLeft: "4px",
                  }}
                >
                  <Button onClick={() => showAnalysis("analysis", "post")}>
                    {" "}
                    Analyse
                    <Icon
                      color="orange"
                      path={mdiTextBoxSearchOutline}
                      size={1}
                    />
                  </Button>
                </span>
              </span>
            </div>
            {IdShown === "post" && bubbleType === "analysis" && (
              <div>
                <div>
                  <div
                    // justify="end"
                    style={{
                      width: "98%",
                      marginLeft: "1%",
                      fontWeight: "normal",
                      backgroundColor: "white",
                      marginBottom: "10px",
                      padding: "6px",
                      borderRadius: "6px",
                      border: "solid black 2px",
                    }}
                  >
                    <div>
                      <div>
                        <Row>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center", // Center vertically
                              justifyContent: "center",
                              marginTop: "4px",
                              marginBottom: "4px",
                            }}
                          >
                            {" "}
                            <ButtonGroup>
                              <Radio.Group
                                value={pageAnalysisView}
                                onChange={(e) =>
                                  setPageAnalysisView(e.target.value)
                                }
                              >
                                <Radio.Button value="ner">
                                  NER VIEW
                                </Radio.Button>
                                <Radio.Button value="coref">
                                  COREF VIEW
                                </Radio.Button>
                              </Radio.Group>
                            </ButtonGroup>
                          </div>
                        </Row>

                        {pageAnalysisView === "ner" && (
                          <div>
                            <Row
                              style={{
                                borderTop: "1px  solid black",
                              }}
                            >
                              <span>Labels:</span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center",
                                  width: "76px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  marginLeft: "12px",
                                  marginRight: "2px",
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "1px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  Substance
                                </div>
                              </span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center",
                                  width: "46px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  backgroundColor: "#BDB76B",
                                  color: "black",
                                }}
                              >
                                <div
                                  style={{
                                    justify: "center",
                                    display: "flex",
                                    marginRight: "1px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  Date
                                </div>
                              </span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center",
                                  width: "72px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  backgroundColor: "#FAEBD7",
                                  color: "black",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "1px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  Glassware
                                </div>
                              </span>

                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center",
                                  width: "66px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  backgroundColor: "#7FFF00",
                                  color: "black",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "1px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  Location
                                </div>
                              </span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center",
                                  width: "66px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  backgroundColor: "#D2691E",
                                  color: "black",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "1px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  Group
                                </div>
                              </span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center",
                                  width: "66px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  backgroundColor: "#FF7F50",
                                  color: "black",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "1px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  Item
                                </div>
                              </span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center",
                                  width: "66px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  backgroundColor: "#40C4FF",
                                  color: "black",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "1px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  Person
                                </div>
                              </span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center",
                                  width: "66px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "1px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  Quantity
                                </div>
                              </span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center",
                                  width: "66px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  backgroundColor: "#00008B",
                                  color: "white",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "1px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  Status
                                </div>
                              </span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center", // Center vertically
                                  justifyContent: "center",
                                  width: "60px",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  backgroundColor: "#E040FB",
                                  color: "black",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "1px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  Art
                                </div>
                              </span>
                            </Row>

                            <Ref pk={params.pk}></Ref>
                            <PieChartComponent
                              pieData={webSocialMediaStore.pieData}
                            />
                          </div>
                        )}
                        {pageAnalysisView === "coref" && (
                          <div>
                            <Row
                              style={{
                                borderTop: "1px  solid black",
                              }}
                            >
                              {" "}
                              <span
                                style={{
                                  marginLeft: "3px  ",
                                }}
                              >
                                Words marked :
                              </span>
                              {webSocialMediaStore.blueGroup.length > 0 && (
                                <span
                                  style={{
                                    marginLeft: "3px  ",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      backgroundColor: "blue",
                                      padding: "2px",
                                      color: "white",
                                    }}
                                  >
                                    Group 1
                                  </span>{" "}
                                  <span>
                                    <Checkbox
                                      checked={groupBlueChecked}
                                      onChange={onCheckBlue}
                                    ></Checkbox>
                                  </span>
                                </span>
                              )}
                              {webSocialMediaStore.orangeGroup.length > 0 && (
                                <span
                                  style={{
                                    marginLeft: "6px  ",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      backgroundColor: "orange",
                                      padding: "2px",
                                      color: "black",
                                    }}
                                  >
                                    Group 2
                                  </span>{" "}
                                  <span>
                                    <Checkbox
                                      checked={groupOrangeChecked}
                                      onChange={onCheckOrange}
                                    ></Checkbox>
                                  </span>
                                </span>
                              )}{" "}
                              {webSocialMediaStore.redGroup.length > 0 && (
                                <span
                                  style={{
                                    marginLeft: "6px  ",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      backgroundColor: "red",
                                      padding: "2px",
                                      color: "white",
                                    }}
                                  >
                                    Group 3
                                  </span>{" "}
                                  <span>
                                    <Checkbox
                                      checked={groupRedChecked}
                                      onChange={onCheckRed}
                                    ></Checkbox>
                                  </span>
                                </span>
                              )}
                              {webSocialMediaStore.limeGroup.length > 0 && (
                                <span
                                  style={{
                                    marginLeft: "6px  ",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      backgroundColor: "#7FFF00",
                                      padding: "2px",
                                      color: "black",
                                    }}
                                  >
                                    Group 4
                                  </span>{" "}
                                  <span>
                                    <Checkbox
                                      checked={groupLimeChecked}
                                      onChange={onCheckLime}
                                    ></Checkbox>
                                  </span>
                                </span>
                              )}{" "}
                              {webSocialMediaStore.purpleGroup.length > 0 && (
                                <span
                                  style={{
                                    marginLeft: "6px  ",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      backgroundColor: "#E040FB",
                                      padding: "2px",
                                      color: "black",
                                    }}
                                  >
                                    Group 5
                                  </span>{" "}
                                  <span>
                                    <Checkbox
                                      checked={groupPurpleChecked}
                                      onChange={onCheckPurple}
                                    ></Checkbox>
                                  </span>
                                </span>
                              )}{" "}
                              {webSocialMediaStore.greenGroup.length > 0 && (
                                <span
                                  style={{
                                    marginLeft: "6px  ",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      backgroundColor: "green",
                                      padding: "2px",
                                      color: "white",
                                    }}
                                  >
                                    Group 6
                                  </span>{" "}
                                  <span>
                                    <Checkbox
                                      checked={groupGreenChecked}
                                      onChange={onCheckGreen}
                                    ></Checkbox>
                                  </span>
                                </span>
                              )}
                              {webSocialMediaStore.cyanGroup.length > 0 && (
                                <span
                                  style={{
                                    marginLeft: "6px  ",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      backgroundColor: "#00BCD4",
                                      padding: "2px",
                                      color: "white",
                                    }}
                                  >
                                    Group 7
                                  </span>{" "}
                                  <span>
                                    <Checkbox
                                      checked={groupCyanChecked}
                                      onChange={onCheckCyan}
                                    ></Checkbox>
                                  </span>
                                </span>
                              )}
                              {webSocialMediaStore.yellowGroup.length > 0 && (
                                <span
                                  style={{
                                    marginLeft: "6px  ",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      backgroundColor: "yellow",
                                      padding: "2px",
                                      color: "black",
                                    }}
                                  >
                                    Group 8
                                  </span>{" "}
                                  <span>
                                    <Checkbox
                                      checked={groupYellowChecked}
                                      onChange={onCheckYellow}
                                    ></Checkbox>
                                  </span>
                                </span>
                              )}
                              <span> </span>
                            </Row>
                            <Confer
                              pk={params.pk}
                              domainId={postDomainId}
                              groupBlueChecked={groupBlueChecked}
                              groupOrangeChecked={groupOrangeChecked}
                              groupRedChecked={groupRedChecked}
                              groupLimeChecked={groupLimeChecked}
                              groupPurpleChecked={groupPurpleChecked}
                              groupGreenChecked={groupGreenChecked}
                              groupCyanChecked={groupCyanChecked}
                              groupYellowChecked={groupYellowChecked}
                            ></Confer>
                          </div>
                        )}

                        {/* {
                        webSocialMediaStore.analyse_entities.length > 0 ? (
                         
                        ) : (
                          <span>No data</span>
                        )
    
                      } */}
                      </div>
                      {webSocialMediaStore.analyse_entities.length > 0 ? (
                        <span>
                          <Row>
                            <span>Labels:</span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "12px",
                                marginRight: "2px",
                                backgroundColor: "red",
                                color: "white",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Chemical
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "46px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#BDB76B",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  justify: "center",
                                  display: "flex",
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Date
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "72px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#FAEBD7",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Glassware
                              </div>
                            </span>

                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#7FFF00",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Location
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#D2691E",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Group
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#FF7F50",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Item
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#40C4FF",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Person
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#00FFFF",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Quantity
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#00008B",
                                color: "white",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Status
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "60px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#E040FB",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Art
                              </div>
                            </span>
                          </Row>

                          {pageAnalysisView === "ner" && (
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Divider orientation="left">
                                Analysed text{" "}
                              </Divider>

                              <div style={{ width: "100%" }}>
                                <Row> {renderedText}</Row>
                              </div>
                              <div>
                                <Ref pk={params.pk}></Ref>
                                <PieChartComponent
                                  pieData={webSocialMediaStore.pieData}
                                />
                              </div>

                              <div style={{}}>
                                <Row>
                                  {" "}
                                  <PieChartComponent
                                    pieData={webSocialMediaStore.pieData}
                                  />{" "}
                                </Row>
                              </div>
                            </div>
                          )}

                          {/* <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Divider orientation="left">
                                Analysed text{" "}
                              </Divider>

                              <div style={{ width: "100%" }}>
                                <Row> {renderedText}</Row>
                                
                              </div>
                              <div style={{}}>
                                <Row>
                                  {" "}
                                  <PieChartComponent
                                    pieData={webSocialMediaStore.pieData}
                                  />{" "}
                                </Row>
                              </div>
                            </div> */}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>{" "}
                </div>
              </div>
            )}
            {IdShown === "post" && bubbleType === "translation" && (
              <div>
                <div
                  // justify="end"
                  style={{
                    width: "98%",
                    marginLeft: "1%",
                    backgroundColor: "white",
                    marginBottom: "10px",
                    padding: "6px",
                    borderRadius: "6px",
                    border: "solid black 2px",
                    fontWeight: "normal",
                  }}
                >
                  <div>{translationText}</div>
                </div>{" "}
              </div>
            )}
          </div>
        )}
      </div>

      <Divider></Divider>
      <div
        style={{
          marginTop: "-12px",
          marginBottom: "4px",
          fontWeight: "bold",
          marginLeft: "6px",
        }}
      >
        Comments:
      </div>
      {/* <Tree
        onSelect={onSelect}
        showIcon
        defaultExpandAll
        defaultSelectedKeys={["0-0-0"]}
        switcherIcon={<DownOutlined />}
        treeData={treeData}
      /> */}
      {filteredItems.length > 0 &&
        filteredItems.map((post) => {
          return (
            <div>
              <div>
                <Row
                  justify="start"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      maxWidth: "70%",
                      width: "auto",
                      minWidth: "50%",
                    }}
                  >
                    <div
                      justify="start"
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#CFD8DC",
                        marginBottom: "10px",
                        marginTop: "30px",
                        padding: "6px",
                        borderRadius: "6px",
                      }}
                    >
                      <div>
                        <span
                          style={{ marginRight: "1px", fontWeight: "bold" }}
                        >
                          {post.title && post.title.length > 20 && (
                            <span>
                              <span>
                                {" "}
                                <Tooltip title={post.title}>
                                  {post.title.substring(0, 20)}
                                </Tooltip>
                              </span>
                              <span style={{ marginLeft: "1px" }}> ... </span>
                            </span>
                          )}
                          {post.title && post.title.length <= 20 && (
                            <Tooltip title={post.title}>{post.title}</Tooltip>
                          )}{" "}
                        </span>
                      </div>
                      <div>
                        {/* <span style={{ fontWeight: "bold" }}> {post.title}</span>*/}
                        <span>{post.content}</span>
                        {post.classification && (
                          <span>
                            <span
                              style={{ fontWeight: "bold", marginLeft: "20px" }}
                            >
                              <Tag
                                style={{
                                  width: "140px",
                                  height: "28px",
                                }}
                                color="orange"
                              >
                                <Row
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyItems: "center",
                                  }}
                                >
                                  <span>
                                    <Icon path={mdiBomb} size={1} />
                                  </span>
                                  <span>{post.classification}</span>
                                </Row>
                              </Tag>
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                        marginBottom: "12px",
                      }}
                    >
                      <span
                        style={{
                          width: "180px",
                        }}
                      >
                        {dateForTables(post.published)}
                      </span>
                    </div>
                  </span>{" "}
                  {IdShown !== post.id ? (
                    <span
                      style={{
                        marginLeft: "8px",
                      }}
                    >
                      <Tooltip
                        placement="right"
                        title={
                          <div>
                            <div
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                showTranslation("translation", post)
                              }
                            >
                              <span>Translate</span>
                              <span>
                                <Icon
                                  color="white"
                                  path={mdiTranslate}
                                  size={1}
                                />
                              </span>
                            </div>
                            <Divider
                              style={{
                                backgroundColor: "grey",
                                marginTop: "-2px",
                                marginBottom: "-2px",
                              }}
                            ></Divider>
                            <div
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => showAnalysis("analysis", post)}
                            >
                              <span>Analyse</span>
                              <span>
                                <Icon
                                  color="white"
                                  path={mdiTextBoxSearchOutline}
                                  size={1}
                                />
                              </span>
                            </div>
                          </div>
                        }
                      >
                        <Button shape="circle">
                          <Icon path={mdiDotsVertical} size={1} />
                        </Button>
                      </Tooltip>
                    </span>
                  ) : (
                    <span
                      style={{
                        marginLeft: "8px",
                      }}
                    >
                      <span>
                        {" "}
                        <Button
                          onClick={() => closeSubContent()}
                          shape="circle"
                        >
                          <Icon path={mdiClose} size={1} />
                        </Button>
                      </span>
                    </span>
                  )}
                </Row>
              </div>

              {IdShown === post.id && bubbleType === "analysis" && (
                <div>
                  <div
                    // justify="end"
                    style={{
                      width: "98%",
                      marginLeft: "1%",
                      backgroundColor: "white",
                      marginBottom: "10px",
                      padding: "6px",
                      borderRadius: "6px",
                      border: "solid black 2px",
                    }}
                  >
                    <div>
                      <Row>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center", // Center vertically
                            justifyContent: "center",
                            marginTop: "4px",
                            marginBottom: "4px",
                          }}
                        >
                          {" "}
                          <ButtonGroup>
                            <Radio.Group
                              value={pageAnalysisView}
                              onChange={(e) =>
                                setPageAnalysisView(e.target.value)
                              }
                            >
                              <Radio.Button value="ner">NER VIEW</Radio.Button>
                              <Radio.Button value="coref">
                                COREF VIEW
                              </Radio.Button>
                            </Radio.Group>
                          </ButtonGroup>
                        </div>
                      </Row>

                      {pageAnalysisView === "ner" && (
                        <div>
                          <Row
                            style={{
                              borderTop: "1px  solid black",
                            }}
                          >
                            <span>Labels:</span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "76px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "12px",
                                marginRight: "2px",
                                backgroundColor: "red",
                                color: "white",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Substance
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "46px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#BDB76B",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  justify: "center",
                                  display: "flex",
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Date
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "72px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#FAEBD7",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Glassware
                              </div>
                            </span>

                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#7FFF00",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Location
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#D2691E",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Group
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#FF7F50",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Item
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#40C4FF",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Person
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#00FFFF",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Quantity
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "66px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#00008B",
                                color: "white",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Status
                              </div>
                            </span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center", // Center vertically
                                justifyContent: "center",
                                width: "60px",
                                marginTop: "2px",
                                marginBottom: "2px",
                                marginLeft: "1px",
                                marginRight: "1px",
                                backgroundColor: "#E040FB",
                                color: "black",
                              }}
                            >
                              <div
                                style={{
                                  marginRight: "1px",
                                  marginLeft: "1px",
                                }}
                              >
                                Art
                              </div>
                            </span>
                          </Row>

                          <Ref pk={params.pk}></Ref>
                          <PieChartComponent
                            pieData={webSocialMediaStore.pieData}
                          />
                        </div>
                      )}
                      {pageAnalysisView === "coref" && (
                        <div>
                          <Row
                            style={{
                              borderTop: "1px  solid black",
                            }}
                          >
                            {" "}
                            <span
                              style={{
                                marginLeft: "3px  ",
                              }}
                            >
                              Words marked :
                            </span>
                            {webSocialMediaStore.blueGroup.length > 0 && (
                              <span
                                style={{
                                  marginLeft: "3px  ",
                                }}
                              >
                                {" "}
                                <span
                                  style={{
                                    backgroundColor: "blue",
                                    padding: "2px",
                                    color: "white",
                                  }}
                                >
                                  Group 1
                                </span>{" "}
                                <span>
                                  <Checkbox
                                    checked={groupBlueChecked}
                                    onChange={onCheckBlue}
                                  ></Checkbox>
                                </span>
                              </span>
                            )}
                            {webSocialMediaStore.orangeGroup.length > 0 && (
                              <span
                                style={{
                                  marginLeft: "6px  ",
                                }}
                              >
                                {" "}
                                <span
                                  style={{
                                    backgroundColor: "orange",
                                    padding: "2px",
                                    color: "black",
                                  }}
                                >
                                  Group 2
                                </span>{" "}
                                <span>
                                  <Checkbox
                                    checked={groupOrangeChecked}
                                    onChange={onCheckOrange}
                                  ></Checkbox>
                                </span>
                              </span>
                            )}{" "}
                            {webSocialMediaStore.redGroup.length > 0 && (
                              <span
                                style={{
                                  marginLeft: "6px  ",
                                }}
                              >
                                {" "}
                                <span
                                  style={{
                                    backgroundColor: "red",
                                    padding: "2px",
                                    color: "white",
                                  }}
                                >
                                  Group 3
                                </span>{" "}
                                <span>
                                  <Checkbox
                                    checked={groupRedChecked}
                                    onChange={onCheckRed}
                                  ></Checkbox>
                                </span>
                              </span>
                            )}
                            {webSocialMediaStore.limeGroup.length > 0 && (
                              <span
                                style={{
                                  marginLeft: "6px  ",
                                }}
                              >
                                {" "}
                                <span
                                  style={{
                                    backgroundColor: "#7FFF00",
                                    padding: "2px",
                                    color: "black",
                                  }}
                                >
                                  Group 4
                                </span>{" "}
                                <span>
                                  <Checkbox
                                    checked={groupLimeChecked}
                                    onChange={onCheckLime}
                                  ></Checkbox>
                                </span>
                              </span>
                            )}{" "}
                            {webSocialMediaStore.purpleGroup.length > 0 && (
                              <span
                                style={{
                                  marginLeft: "6px  ",
                                }}
                              >
                                {" "}
                                <span
                                  style={{
                                    backgroundColor: "#E040FB",
                                    padding: "2px",
                                    color: "black",
                                  }}
                                >
                                  Group 5
                                </span>{" "}
                                <span>
                                  <Checkbox
                                    checked={groupPurpleChecked}
                                    onChange={onCheckPurple}
                                  ></Checkbox>
                                </span>
                              </span>
                            )}{" "}
                            {webSocialMediaStore.greenGroup.length > 0 && (
                              <span
                                style={{
                                  marginLeft: "6px  ",
                                }}
                              >
                                {" "}
                                <span
                                  style={{
                                    backgroundColor: "green",
                                    padding: "2px",
                                    color: "white",
                                  }}
                                >
                                  Group 6
                                </span>{" "}
                                <span>
                                  <Checkbox
                                    checked={groupGreenChecked}
                                    onChange={onCheckGreen}
                                  ></Checkbox>
                                </span>
                              </span>
                            )}
                            {webSocialMediaStore.cyanGroup.length > 0 && (
                              <span
                                style={{
                                  marginLeft: "6px  ",
                                }}
                              >
                                {" "}
                                <span
                                  style={{
                                    backgroundColor: "#00BCD4",
                                    padding: "2px",
                                    color: "white",
                                  }}
                                >
                                  Group 7
                                </span>{" "}
                                <span>
                                  <Checkbox
                                    checked={groupCyanChecked}
                                    onChange={onCheckCyan}
                                  ></Checkbox>
                                </span>
                              </span>
                            )}
                            {webSocialMediaStore.yellowGroup.length > 0 && (
                              <span
                                style={{
                                  marginLeft: "6px  ",
                                }}
                              >
                                {" "}
                                <span
                                  style={{
                                    backgroundColor: "yellow",
                                    padding: "2px",
                                    color: "black",
                                  }}
                                >
                                  Group 8
                                </span>{" "}
                                <span>
                                  <Checkbox
                                    checked={groupYellowChecked}
                                    onChange={onCheckYellow}
                                  ></Checkbox>
                                </span>
                              </span>
                            )}
                            <span> </span>
                          </Row>
                          <Confer
                            pk={params.pk}
                            domainId={itemShown.domainId}
                            groupBlueChecked={groupBlueChecked}
                            groupOrangeChecked={groupOrangeChecked}
                            groupRedChecked={groupRedChecked}
                            groupLimeChecked={groupLimeChecked}
                            groupPurpleChecked={groupPurpleChecked}
                            groupGreenChecked={groupGreenChecked}
                            groupCyanChecked={groupCyanChecked}
                            groupYellowChecked={groupYellowChecked}
                          ></Confer>
                        </div>
                      )}

                      {/* {
                        webSocialMediaStore.analyse_entities.length > 0 ? (
                         
                        ) : (
                          <span>No data</span>
                        )
    
                      } */}
                    </div>
                  </div>{" "}
                </div>
              )}
              {IdShown === post.id && bubbleType === "translation" && (
                <div>
                  <div
                    // justify="end"
                    style={{
                      width: "98%",
                      marginLeft: "1%",
                      backgroundColor: "white",
                      marginBottom: "10px",
                      padding: "6px",
                      borderRadius: "6px",
                      border: "solid black 2px",
                    }}
                  >
                    <div>{translationText}</div>
                  </div>{" "}
                </div>
              )}
              {replies.filter((reply) => {
                return post.attributes.sub_domainIds.includes(reply.domainId);
              }).length > 0 && (
                <div>
                  {replies
                    .filter((reply) => {
                      return post.attributes.sub_domainIds.includes(
                        reply.domainId
                      );
                    })
                    .map((reply) => {
                      return (
                        <div>
                          <Row
                            justify="end"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            {IdShown !== reply.id ? (
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "8px",
                                }}
                              >
                                <Tooltip
                                  placement="left"
                                  title={
                                    <div>
                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          showTranslation("translation", reply)
                                        }
                                      >
                                        <span>Translate</span>
                                        <span>
                                          <Icon
                                            color="white"
                                            path={mdiTranslate}
                                            size={1}
                                          />
                                        </span>
                                      </div>
                                      <Divider
                                        style={{
                                          backgroundColor: "grey",
                                          marginTop: "-2px",
                                          marginBottom: "-2px",
                                        }}
                                      ></Divider>
                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          showAnalysis("analysis", reply)
                                        }
                                      >
                                        <span>Analyse</span>
                                        <span>
                                          <Icon
                                            color="white"
                                            path={mdiTextBoxSearchOutline}
                                            size={1}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  }
                                >
                                  <Button shape="circle">
                                    <Icon path={mdiDotsVertical} size={1} />
                                  </Button>
                                </Tooltip>
                              </span>
                            ) : (
                              <span
                                style={{
                                  marginRight: "8px",
                                }}
                              >
                                {" "}
                                <Button
                                  onClick={() => closeSubContent()}
                                  shape="circle"
                                >
                                  <Icon path={mdiClose} size={1} />
                                </Button>
                              </span>
                            )}
                            <span
                              style={{
                                maxWidth: "70%",
                                width: "auto",
                                minWidth: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  color: "white",
                                  backgroundColor: "#065ac7",
                                  marginBottom: "10px",
                                  padding: "6px",
                                  borderRadius: "6px",
                                }}
                              >
                                <div>
                                  <span
                                    style={{
                                      marginRight: "1px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {reply.title && reply.title.length > 20 && (
                                      <span>
                                        <span>
                                          {" "}
                                          <Tooltip title={reply.title}>
                                            {reply.title.substring(0, 20)}
                                          </Tooltip>
                                        </span>
                                        <span style={{ marginLeft: "1px" }}>
                                          {" "}
                                          ...{" "}
                                        </span>
                                      </span>
                                    )}
                                    {reply.title &&
                                      reply.title.length <= 20 && (
                                        <Tooltip title={reply.title}>
                                          {reply.title}
                                        </Tooltip>
                                      )}{" "}
                                  </span>
                                </div>

                                <div>
                                  <span>{reply.content}</span>

                                  {reply.classification && (
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          marginLeft: "20px",
                                        }}
                                      >
                                        <Tag
                                          style={{
                                            width: "140px",
                                            height: "28px",
                                          }}
                                          color="orange"
                                        >
                                          <Row
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyItems: "center",
                                            }}
                                          >
                                            <span>
                                              <Icon path={mdiBomb} size={1} />
                                            </span>
                                            <span>{reply.classification}</span>
                                          </Row>
                                        </Tag>
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </span>
                          </Row>{" "}
                          <Row
                            justify="end"
                            style={{
                              marginBottom: "10px",
                            }}
                          >
                            <span
                              style={{
                                maxWidth: "70%",
                                width: "auto",
                                minWidth: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {dateForTables(reply.published)}
                            </span>
                          </Row>
                          {IdShown === reply.id &&
                            bubbleType === "analysis" && (
                              <div>
                                <div
                                  // justify="end"
                                  style={{
                                    width: "98%",
                                    marginLeft: "1%",
                                    backgroundColor: "white",
                                    marginBottom: "10px",
                                    padding: "6px",
                                    borderRadius: "6px",
                                    border: "solid black 2px",
                                  }}
                                >
                                  <div>
                                    <Row>
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          alignItems: "center", // Center vertically
                                          justifyContent: "center",
                                          marginTop: "4px",
                                          marginBottom: "4px",
                                        }}
                                      >
                                        {" "}
                                        <ButtonGroup>
                                          <Radio.Group
                                            value={pageAnalysisView}
                                            onChange={(e) =>
                                              setPageAnalysisView(
                                                e.target.value
                                              )
                                            }
                                          >
                                            <Radio.Button value="ner">
                                              NER VIEW
                                            </Radio.Button>
                                            <Radio.Button value="coref">
                                              COREF VIEW
                                            </Radio.Button>
                                          </Radio.Group>
                                        </ButtonGroup>
                                      </div>
                                    </Row>

                                    {pageAnalysisView === "ner" && (
                                      <div>
                                        <Row
                                          style={{
                                            borderTop: "1px  solid black",
                                          }}
                                        >
                                          <span>Labels:</span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center", // Center vertically
                                              justifyContent: "center",
                                              width: "76px",
                                              marginTop: "2px",
                                              marginBottom: "2px",
                                              marginLeft: "12px",
                                              marginRight: "2px",
                                              backgroundColor: "red",
                                              color: "white",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: "1px",
                                                marginLeft: "1px",
                                              }}
                                            >
                                              Substance
                                            </div>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center", // Center vertically
                                              justifyContent: "center",
                                              width: "46px",
                                              marginTop: "2px",
                                              marginBottom: "2px",
                                              marginLeft: "1px",
                                              marginRight: "1px",
                                              backgroundColor: "#BDB76B",
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{
                                                justify: "center",
                                                display: "flex",
                                                marginRight: "1px",
                                                marginLeft: "1px",
                                              }}
                                            >
                                              Date
                                            </div>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center", // Center vertically
                                              justifyContent: "center",
                                              width: "72px",
                                              marginTop: "2px",
                                              marginBottom: "2px",
                                              marginLeft: "1px",
                                              marginRight: "1px",
                                              backgroundColor: "#FAEBD7",
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: "1px",
                                                marginLeft: "1px",
                                              }}
                                            >
                                              Glassware
                                            </div>
                                          </span>

                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center", // Center vertically
                                              justifyContent: "center",
                                              width: "66px",
                                              marginTop: "2px",
                                              marginBottom: "2px",
                                              marginLeft: "1px",
                                              marginRight: "1px",
                                              backgroundColor: "#7FFF00",
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: "1px",
                                                marginLeft: "1px",
                                              }}
                                            >
                                              Location
                                            </div>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center", // Center vertically
                                              justifyContent: "center",
                                              width: "66px",
                                              marginTop: "2px",
                                              marginBottom: "2px",
                                              marginLeft: "1px",
                                              marginRight: "1px",
                                              backgroundColor: "#D2691E",
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: "1px",
                                                marginLeft: "1px",
                                              }}
                                            >
                                              Group
                                            </div>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center", // Center vertically
                                              justifyContent: "center",
                                              width: "66px",
                                              marginTop: "2px",
                                              marginBottom: "2px",
                                              marginLeft: "1px",
                                              marginRight: "1px",
                                              backgroundColor: "#FF7F50",
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: "1px",
                                                marginLeft: "1px",
                                              }}
                                            >
                                              Item
                                            </div>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center", // Center vertically
                                              justifyContent: "center",
                                              width: "66px",
                                              marginTop: "2px",
                                              marginBottom: "2px",
                                              marginLeft: "1px",
                                              marginRight: "1px",
                                              backgroundColor: "#40C4FF",
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: "1px",
                                                marginLeft: "1px",
                                              }}
                                            >
                                              Person
                                            </div>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center", // Center vertically
                                              justifyContent: "center",
                                              width: "66px",
                                              marginTop: "2px",
                                              marginBottom: "2px",
                                              marginLeft: "1px",
                                              marginRight: "1px",
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: "1px",
                                                marginLeft: "1px",
                                              }}
                                            >
                                              Quantity
                                            </div>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center", // Center vertically
                                              justifyContent: "center",
                                              width: "66px",
                                              marginTop: "2px",
                                              marginBottom: "2px",
                                              marginLeft: "1px",
                                              marginRight: "1px",
                                              backgroundColor: "#00008B",
                                              color: "white",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: "1px",
                                                marginLeft: "1px",
                                              }}
                                            >
                                              Status
                                            </div>
                                          </span>
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center", // Center vertically
                                              justifyContent: "center",
                                              width: "60px",
                                              marginTop: "2px",
                                              marginBottom: "2px",
                                              marginLeft: "1px",
                                              marginRight: "1px",
                                              backgroundColor: "#E040FB",
                                              color: "black",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: "1px",
                                                marginLeft: "1px",
                                              }}
                                            >
                                              Art
                                            </div>
                                          </span>
                                        </Row>

                                        <Ref pk={params.pk}></Ref>
                                        <PieChartComponent
                                          pieData={webSocialMediaStore.pieData}
                                        />
                                      </div>
                                    )}
                                    {pageAnalysisView === "coref" && (
                                      <div>
                                        <Row
                                          style={{
                                            borderTop: "1px  solid black",
                                          }}
                                        >
                                          {" "}
                                          <span
                                            style={{
                                              marginLeft: "3px  ",
                                            }}
                                          >
                                            Words marked :
                                          </span>
                                          {webSocialMediaStore.blueGroup
                                            .length > 0 && (
                                            <span
                                              style={{
                                                marginLeft: "3px  ",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{
                                                  backgroundColor: "blue",
                                                  padding: "2px",
                                                  color: "white",
                                                }}
                                              >
                                                Group 1
                                              </span>{" "}
                                              <span>
                                                <Checkbox
                                                  checked={groupBlueChecked}
                                                  onChange={onCheckBlue}
                                                ></Checkbox>
                                              </span>
                                            </span>
                                          )}
                                          {webSocialMediaStore.orangeGroup
                                            .length > 0 && (
                                            <span
                                              style={{
                                                marginLeft: "6px  ",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{
                                                  backgroundColor: "orange",
                                                  padding: "2px",
                                                  color: "black",
                                                }}
                                              >
                                                Group 2
                                              </span>{" "}
                                              <span>
                                                <Checkbox
                                                  checked={groupOrangeChecked}
                                                  onChange={onCheckOrange}
                                                ></Checkbox>
                                              </span>
                                            </span>
                                          )}{" "}
                                          {webSocialMediaStore.redGroup.length >
                                            0 && (
                                            <span
                                              style={{
                                                marginLeft: "6px  ",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{
                                                  backgroundColor: "red",
                                                  padding: "2px",
                                                  color: "white",
                                                }}
                                              >
                                                Group 3
                                              </span>{" "}
                                              <span>
                                                <Checkbox
                                                  checked={groupRedChecked}
                                                  onChange={onCheckRed}
                                                ></Checkbox>
                                              </span>
                                            </span>
                                          )}
                                          {webSocialMediaStore.limeGroup
                                            .length > 0 && (
                                            <span
                                              style={{
                                                marginLeft: "6px  ",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{
                                                  backgroundColor: "#7FFF00",
                                                  padding: "2px",
                                                  color: "black",
                                                }}
                                              >
                                                Group 4
                                              </span>{" "}
                                              <span>
                                                <Checkbox
                                                  checked={groupLimeChecked}
                                                  onChange={onCheckLime}
                                                ></Checkbox>
                                              </span>
                                            </span>
                                          )}{" "}
                                          {webSocialMediaStore.purpleGroup
                                            .length > 0 && (
                                            <span
                                              style={{
                                                marginLeft: "6px  ",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{
                                                  backgroundColor: "#E040FB",
                                                  padding: "2px",
                                                  color: "black",
                                                }}
                                              >
                                                Group 5
                                              </span>{" "}
                                              <span>
                                                <Checkbox
                                                  checked={groupPurpleChecked}
                                                  onChange={onCheckPurple}
                                                ></Checkbox>
                                              </span>
                                            </span>
                                          )}{" "}
                                          {webSocialMediaStore.greenGroup
                                            .length > 0 && (
                                            <span
                                              style={{
                                                marginLeft: "6px  ",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{
                                                  backgroundColor: "green",
                                                  padding: "2px",
                                                  color: "white",
                                                }}
                                              >
                                                Group 6
                                              </span>{" "}
                                              <span>
                                                <Checkbox
                                                  checked={groupGreenChecked}
                                                  onChange={onCheckGreen}
                                                ></Checkbox>
                                              </span>
                                            </span>
                                          )}
                                          {webSocialMediaStore.cyanGroup
                                            .length > 0 && (
                                            <span
                                              style={{
                                                marginLeft: "6px  ",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{
                                                  backgroundColor: "#00BCD4",
                                                  padding: "2px",
                                                  color: "white",
                                                }}
                                              >
                                                Group 7
                                              </span>{" "}
                                              <span>
                                                <Checkbox
                                                  checked={groupCyanChecked}
                                                  onChange={onCheckCyan}
                                                ></Checkbox>
                                              </span>
                                            </span>
                                          )}
                                          {webSocialMediaStore.yellowGroup
                                            .length > 0 && (
                                            <span
                                              style={{
                                                marginLeft: "6px  ",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{
                                                  backgroundColor: "yellow",
                                                  padding: "2px",
                                                  color: "black",
                                                }}
                                              >
                                                Group 8
                                              </span>{" "}
                                              <span>
                                                <Checkbox
                                                  checked={groupYellowChecked}
                                                  onChange={onCheckYellow}
                                                ></Checkbox>
                                              </span>
                                            </span>
                                          )}
                                          <span> </span>
                                        </Row>
                                        <Confer
                                          pk={params.pk}
                                          domainId={itemShown.domainId}
                                          groupBlueChecked={groupBlueChecked}
                                          groupOrangeChecked={
                                            groupOrangeChecked
                                          }
                                          groupRedChecked={groupRedChecked}
                                          groupLimeChecked={groupLimeChecked}
                                          groupPurpleChecked={
                                            groupPurpleChecked
                                          }
                                          groupGreenChecked={groupGreenChecked}
                                          groupCyanChecked={groupCyanChecked}
                                          groupYellowChecked={
                                            groupYellowChecked
                                          }
                                        ></Confer>
                                      </div>
                                    )}
                                  </div>
                                </div>{" "}
                              </div>
                            )}
                          {IdShown === reply.id &&
                            bubbleType === "translation" && (
                              <div>
                                <div
                                  // justify="end"
                                  style={{
                                    width: "98%",
                                    marginLeft: "1%",
                                    backgroundColor: "white",
                                    marginBottom: "10px",
                                    padding: "6px",
                                    borderRadius: "6px",
                                    border: "solid black 2px",
                                  }}
                                >
                                  <div>{translationText}</div>
                                </div>{" "}
                              </div>
                            )}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default observer(TextTranslationPage);
