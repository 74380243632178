import { makeAutoObservable } from "mobx";
import {
  POST,
  PUBLISH,
  GET_SDMA,
  POST_SDMA,
  DELETE_SDMA,
  PUT_SDMA,
  GET_SDMA_IMAGE,
  POST_Test,
} from "../apis/apis";
import { constants } from "../consts/Variables";
import { v4 as uuidv4 } from "uuid";

class WebCrawlStore {
  constructor({ errStore, webSocialMediaStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
    this.webSocialMediaStore = webSocialMediaStore;
  }

  webCrawlTypeIsSocialMedial = false;
  reportPage = "";
  webCrawlView = "list";
  withTransaltionChecked = false;
  withVisualAnalysis = false;
  selectedClassification = "All";
  selectedCluster = "All";
  filteredPagesByCluster = [];
  storedMissions = null;
  alertsOn = false;
  selectedKey = "1";
  dateFormat = "YYYY/MM/DD";
  status = "success";
  webdata = [];
  field_operation = null;
  changeLogItem = [];
  artefacts = [];
  projects = [];
  artefact = null;
  entities = [];
  entity = null;
  links = [];
  link = null;
  accessLogItem = [];
  currentKey = window.location.pathname;
  date = new Date();
  session = this.addMinutes(
    this.date,
    process.env.NODE_ENV === "development" ? 480 : 60
  );
  session_id = localStorage.getItem("session_id") || "";
  isKafkaConnected = "failed";
  transcation_coords = ["23.123456", "23.123456"];
  receivedItems = [];
  pageSize = 100000000;
  waterSensorData = [];
  ugvstatus = [];
  classification_module = [];
  clustering_module = [];
  social_network_analysis = [];
  filteredItems = [];
  searchedData = [];
  isSearching = false;
  battery = "";
  notifications = [];
  temp = { body: null, images: [] };
  higher_TAS = "";
  higher_TASWater = "";
  higher_TASNtdl = "";
  TASNtdl = "";
  TAS_markers = [];
  wp_connection = false;
  pieData = [];
  analyse = false;
  analyse_entities = [];
  mapping = [
    { type2: "ner:chemical", type1: "hasChemical", color: "#F44336" },
    { type2: "ner:date", type1: "hasDate", color: "#BDB76B" },
    { type2: "ner:glassware", type1: "hasGlassware", color: "#FAEBD7" },
    { type2: "ner:location", type1: "hasLocation", color: "#7FFF00" },
    { type2: "ner:group", type1: "hasGroup", color: "#D2691E" },

    { type2: "ner:item", type1: "hasItem", color: "#FF7F50" },
    // { type2: "ner:person", type1: "hasPerson", color: "#DC143C" },
    { type2: "ner:person", type1: "hasPerson", color: "#40C4FF" },

    { type2: "ner:quantity", type1: "hasQuantity", color: "#00FFFF" },
    { type2: "ner:status", type1: "hasStatus", color: "#00008B" },
    // { type2: "ner:art", type1: "hasArt", color: "#FF8C00" },
    { type2: "ner:art", type1: "hasArt", color: "#E040FB" },
  ];

  transactionsETDRealTime = [];
  isMissionModalOpen = false;
  isOperationModalOpen = false;
  clusterDomainId = "";
  projectIdForClustering = "";
  projectIdForSN = "";
  snDomainId = "";

  webCrawlPaginationPage = 1;
  webCrawlPaginationLastPage = false;

  setNextWebCrawlPaginationPage = () => {
    this.webCrawlPaginationPage++;
    if (this.webCrawlPaginationPage * 4 >= this.filteredItems.length) {
      this.webCrawlPaginationLastPage = true;
    }
  };
  setPreviousWebCrawlPaginationPage = () => {
    if (this.webCrawlPaginationPage > 1) {
      this.webCrawlPaginationPage--;
      if (this.webCrawlPaginationPage * 4 < this.filteredItems.length) {
        this.webCrawlPaginationLastPage = false;
      }
    }
  };

  setWebCrawlPaginationPageToReport = (item) => {
    this.reportPage = item;
    this.webCrawlView = "report";
  };
  setWebCrawlPaginationPageToList = () => {
    this.webCrawlPaginationPage = 1;
    this.webCrawlView = "list";
  };

  setWithVisualAnalysis = (value) => {
    this.withVisualAnalysis = value;
    this.filterPages();
    this.webCrawlPaginationPage = 1;
  };

  setWithTransaltionChecked = (value) => {
    this.withTransaltionChecked = value;
    this.filterPages();
    this.webCrawlPaginationPage = 1;
  };

  setSelectedClassification = (value) => {
    this.selectedClassification = value;
    this.filterPages();
    this.webCrawlPaginationPage = 1;
  };

  setSelectedCluster = (value) => {
    this.selectedCluster = value;
    this.filterPages();
    this.webCrawlPaginationPage = 1;
  };

  setFilteredPagesByCluster = (value) => {
    this.filteredPagesByCluster = value;
    this.filterPages();
    this.webCrawlPaginationPage = 1;
  };

  filterPages = () => {
    console.log(this.selectedCluster);
    console.log(this.filteredPagesByCluster);
    let filteredByClassification = [];
    if (this.selectedClassification === "All") {
      filteredByClassification = this.receivedItems;
    }
    if (this.selectedClassification !== "All")
      filteredByClassification = this.receivedItems.filter((item) => {
        return item.classification === this.selectedClassification;
      });

    let filteredByHasVisual = [];
    if (this.withVisualAnalysis) {
      filteredByHasVisual = filteredByClassification.filter((item) => {
        return item.images.preview;
      });
    } else {
      filteredByHasVisual = filteredByClassification;
    }
    let filteredByTranslation = [];
    if (this.withTransaltionChecked) {
      filteredByTranslation = filteredByHasVisual.filter((item) => {
        return item.attributes.translation;
      });
    } else {
      filteredByTranslation = filteredByHasVisual;
    }
    let filterBySelectedCluster = [];
    console.log(this.filteredPagesByCluster);
    if (this.filteredPagesByCluster.length > 0) {
      filterBySelectedCluster = filteredByTranslation.filter((item) => {
        return this.filteredPagesByCluster.includes(item.domainId);
      });
    } else {
      filterBySelectedCluster = filteredByTranslation;
    }
    console.log(filterBySelectedCluster);
    this.filteredItems = filterBySelectedCluster;
    this.webCrawlPaginationPage = 1;
    if (this.webCrawlPaginationPage * 4 >= this.filteredItems.length) {
      this.webCrawlPaginationLastPage = true;
    } else {
      this.webCrawlPaginationLastPage = false;
    }
  };
  filterComments = () => {
    this.webCrawlPaginationPage = 1;
    if (this.webCrawlPaginationPage * 4 >= this.filteredItems.length) {
      this.webCrawlPaginationLastPage = true;
    } else {
      this.webCrawlPaginationLastPage = false;
    }
  };

  setIsOperationModalOpen = (value) => {
    this.isOperationModalOpen = value;
  };

  setIsMissionModalOpen = (value) => {
    this.isMissionModalOpen = value;
  };

  setStoredMissions = (value) => {
    this.storedMissions = value;
  };

  setAlertsOn = (value) => {
    this.alertsOn = value;
  };

  setAnalyseEntities = (value) => {
    this.analyse_entities = value;
  };

  setAnalyse = (value) => {
    this.analyse = value;
  };

  analysisPercent = 0;

  setAnalysisPercent = (value) => {
    this.analysisPercent = value;
  };

  setPieData = (value) => {
    this.setAnalysisPercent(0);
    this.pieData = [];
    let metaDataTemp = {};
    for (let j = 0; j < this.mapping.length; j++) {
      let value = this.analyse_entities.filter(
        (item) => item.type == this.mapping[j].type2
      ).length;
      metaDataTemp = {
        color: this.mapping[j].color,
        name: this.mapping[j].type1,
        value: value,
        // value: 2,
      };
      this.pieData.push(metaDataTemp);
      // this.setAnalysisPercent(((j + 1) / this.mapping.length) * 100);
    }
  };

  SetWSConnection = (value) => {
    this.wp_connection = value;
  };

  setTASMarkers = (value) => {
    this.TAS_markers = value;
  };

  setTemp = (value) => {
    this.temp = value;
  };

  setUGVRealTimeDataFull = (value) => {
    console.log(value);
    this.ugv_real_time_data_full = value;
  };

  setNotifications = (value) => {
    this.notifications = value;
  };

  setSessionId = (value) => {
    this.session_id = value;
  };

  setIsSearching = (value) => {
    this.isSearching = value;
  };

  setBattery = (value) => {
    this.battery = value;
  };

  setSelectedKey = (value) => {
    this.selectedKey = value;
  };

  // handle deleting objects
  isItemDeleting = false;

  setIsItemDeleting = (value) => {
    this.isItemDeleting = value;
  };

  setIsKafkaConnected = (value) => {
    this.isKafkaConnected = value;
  };

  addMinutes(date, minutes) {
    date.setMinutes(date.getMinutes() + minutes);

    return date;
  }

  resetSession = () => {
    this.date = new Date();
    this.session = this.addMinutes(this.date, 60);
  };

  // Api Calls SDMA

  // test SDMA
  TestSDMA = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setMessage("SDMA connected");
        this.errStore.setInfo(true);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // ETD

  GetETDRealTime = (pId, id) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?domainId=${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setTransactionsETDRealTime(JSON.parse(data.data[0].raw));
        this.setETDCoordsRealTime(JSON.parse(data.data[0].raw));
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // drones

  Session = (body) => {
    this.session_data.push(body);
  };

  // transactions
  CreateTransactions = (values) => {
    POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities`, values)
      .then((data) => {
        this.errStore.setMessage("Transactions data collection started");
        this.errStore.setInfo(true);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");

        console.log(error);
      });
  };

  // get project
  GetProject = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects`)
      .then((data) => {
        this.errStore.setMessage("");
        this.projects = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Artefacts
  GetArtefacts = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefacts = data.data;
        this.transaction = data.data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setVisualImages = (value) => {
    this.visual_images = value;
  };

  GetVisualImages = (id, media) => {
    console.log(id, media);
    this.errStore.setLoading(true);
    this.setVisualImages([]);
    // let newImages = [];
    Object.entries(media).map((item) => {
      GET_SDMA_IMAGE(`${constants.SDMA_API.slice(0, -1)}${item[1].raw.url}`)
        .then((data) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            this.errStore.setMessage("");
            this.errStore.setLoading(false);
            this.visual_images.push(reader.result);
          };
        })
        .catch((error) => {
          this.errStore.setError(true);
          this.errStore.setMessage("SDMA ERROR");
          console.log(error);
        });
    });
    // this.setVisualImages(newImages);
  };
  GetVisualImagesNew = (id, media) => {
    console.log(id, media);
    this.errStore.setLoading(true);
    this.setVisualImages([]);
    Object.entries(media).map((item) => {
      GET_SDMA_IMAGE(`${constants.SDMA_API.slice(0, -1)}${item[1].raw.url}`)
        .then((data) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            this.errStore.setMessage("");
            this.errStore.setLoading(false);
            this.visual_images.push(reader.result);
          };
        })
        .catch((error) => {
          this.errStore.setError(true);
          this.errStore.setMessage("SDMA ERROR");
          console.log(error);
        });
    });
  };

  GetVU = (pId, pageSize) => {
    this.errStore.setLoading(true);
    this.SetUV_data([]);
    GET_SDMA(
      `/api/projects/${pId}/artefacts?type=certh:vu:images&pageSize=${pageSize}`
    )
      .then((data) => {
        data.data.map((item) => {
          GET_SDMA(`/api/projects/${pId}/artefacts/${item.id}`)
            .then((data) => {
              this.errStore.setMessage("");
              this.errStore.setLoading(false);
              this.UV_data.push(data.data);
            })
            .catch((error) => {
              this.errStore.setError(true);
              this.errStore.setMessage("SDMA ERROR");
              console.log(error);
            });
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  setReceivedItems = (value) => {
    console.log(value);
    this.receivedItems = value.filter((item) => {
      return item.name && item.title;
    });
    this.filterPages();
  };
  setReceivedComments = (value) => {
    this.receivedItems = value;
    this.filterPages();
  };

  nonHME = [];
  HME = [];
  non = [];

  GetAllPagesFromWebCrawl = (pId, oId) => {
    console.log(oId);
    this.errStore.setLoading(true);
    this.setVisualImages([]);

    GET_SDMA(`/api/projects/${pId}/artefacts?tags=t:operationId:${oId}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        console.log(data.data);
        console.log(this.classification_module);
        let newdata = data.data;
        for (let i = 0; i < newdata.length; i++) {
          if (
            this.classification_module.filter(
              (link) => link.sourceDomainId == newdata[i].domainId
            )[0]
          ) {
            var temp = this.classification_module
              .filter((link) => link.sourceDomainId == newdata[i].domainId)[0]
              .targetDomainId.split(":")[2];
            if (temp) {
              newdata[i].classification = temp;
            } else {
              newdata[i].classification = "Unclassified";
            }

            //     if (temp == "non-HME") {
            //       this.nonHME.push(item);
            //     } else if (temp == "HME") {
            //       this.HME.push(item);
            //     } else {
            //       this.non.push(item);
            //     }
          } else {
            newdata[i].classification = "Unclassified";
          }
        }
        console.log(newdata);

        if (
          newdata[0].domainId.includes("reddit") ||
          newdata[0].domainId.includes("youtube") ||
          newdata[0].domainId.includes("tiktok")
        ) {
          this.webSocialMediaStore.setReceivedItems(pId, newdata);
          this.webCrawlTypeIsSocialMedial = true;
          console.log(this.webCrawlTypeIsSocialMedial);
        } else {
          this.setReceivedItems(newdata);

          this.webCrawlTypeIsSocialMedial = false;
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Artefacts
  GetClusteringFromArtefacts = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${"641da605f611310001fbe67d"}/artefacts?type=ma:clustering&pageSize=${10}`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.clustering_module = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };
  GetClusteringFromArtefactsNew = (x) => {
    this.errStore.setLoading(true);

    GET_SDMA(
      `/api/projects/${this.projectIdForClustering}/artefacts?type=ma:clustering&domainId=${x}`
      // `/api/projects/65e9d1f2a773470001250386/artefacts?type=ma:clustering&domainId=taskId9876`

      // `/api/projects/641da605f611310001fbe67d/artefacts?type=ma:clustering&domainId=ma:clustering:1687252136491`
    )
      .then((data) => {
        this.errStore.setMessage("");
        console.log(data.data);
        this.clustering_module = data.data;
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  //social network analysis
  GetSocialNetworkAnalysis = (pId) => {
    this.errStore.setLoading(true);
    console.log("SNA1");
    GET_SDMA(
      // `/api/projects/6423f44ccf6a810001866fc5/artefacts?pageSize=${this.pageSize}`

      `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1712049342730 `
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.social_network_analysis = data.data.filter(
          (item) => item.type == "ma:sna"
        );
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetSocialNetworkAnalysisNew = (pId) => {
    console.log("SNA6");
    this.errStore.setLoading(true);
    GET_SDMA(
      // `/api/projects/6423f44ccf6a810001866fc5/artefacts?pageSize=${this.pageSize}`
      // `api/projects/${projectIdForSN}/artefacts?domainId=${snDomainId}`

      //new social network
      `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1712049056454`
      //   `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1712049342730`
      // `api/projects/86b8b6bd42ce110000000900/artefacts?domainId=ma:sna:1707817667584`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.social_network_analysis = data.data.filter(
          (item) => item.type == "ma:sna"
        );
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetArtefactByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.artefact = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateArtefacts = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/artefacts`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  GetArtefactByDomainId = (pId, domaiId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/artefacts?domainId=${domaiId}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        console.log(data);
        this.GetVisualImages(data.data[0].id, data.data[0].media);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Links
  GetLinksSourceDomainId = (pId, sourceDomainId) => {
    console.log(sourceDomainId);
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${pId}/links?sourceDomainId=${sourceDomainId}&type=hasVisualAnalysis`
      // "/api/projects/86b8b6bd42ce110000000900/links?sourceDomainId=web:page:20240319T123955Z:565ddc4c2b2a5d681991dc313f34bb70ebc1c00a&type=hasVisualAnalysis"
      // `/api/projects/${pId}/links?sourceDomainId=${sourceDomainId}&type=hasImage`

      // 86b8b6bd42ce110000000900
    )
      .then((data) => {
        console.log(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        if (data.data) {
          this.GetArtefactByDomainId(pId, data.data[0].targetDomainId);
        }
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Links
  GetLinks = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/links?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.links = data;
        this.errStore.setLoading(false);
        this.classification_module = data.data.filter(
          (item) => item.type == "classifiedAs"
        );
        console.log(this.classification_module);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // Links ?tags=t:operationId:24cfb4f2-61a7-46d9-b410-54c1f7c1381181189905-24bf-4519-8bb8-ac211b5e58e9
  GetclassificationModule = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/links?type=classifiedAs&&pageSize=2000`)
      .then((data) => {
        this.errStore.setMessage("");
        this.links = data;
        this.errStore.setLoading(false);
        this.classification_module = data.data;
        console.log("classific");
        console.log(data.data);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetLinkByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/links/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.link = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateLinks = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/links`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.links.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Entities
  GetEntities = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.entities = data;
        this.errStore.setLoading(false);
        this.transaction = data.data.filter(
          (item) => item.type == "transactions"
        )[0];
        this.drones = data.data.filter((item) => item.type == "drone");
        this.field_operation = data.data.filter(
          (item) => item.type == "field_operation"
        )[0];
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  // get drones
  GetDrones = (pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?type=drone`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.drones = data.data;
      })
      .catch((error) => {
        console.log(error);
        this.errStore.setError(true);
        this.errStore.setMessage("Drones were not loaded");
      });
  };

  GetEntityByID = (id, pId) => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/projects/${pId}/entities?tags=t:operationId:${id}`)
      // /entities/${id}
      .then((data) => {
        console.log(data);
        this.errStore.setMessage("");
        this.entity = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  CreateEntities = (values, pId) => {
    POST_SDMA(`/api/projects/${pId}/entities`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.entities.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };
  // audit Items

  GetAuditItems = () => {
    this.errStore.setLoading(true);
    GET_SDMA(`/api/audit?pageSize=${this.pageSize}`)
      .then((data) => {
        this.errStore.setMessage("");
        // this.audit_items = data;
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  GetAuditItemsById = (id) => {
    GET_SDMA(`/api/auditItems/${id}`)
      .then((data) => {
        this.audit_item = data;
      })
      .catch(this.errStore.handlerErrors);
  };

  // Change Log Items

  CreateChangeLogItem = (values) => {
    POST_SDMA(`/api/changeLogItem`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.changeLogItem.push(data);
        this.errStore.handlerCussess("ChangeLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Access Log Items

  CreateAccessLogItem = (values) => {
    POST_SDMA(`/api/accessLogItem`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.accessLogItem.push(data);
        this.errStore.handlerCussess("AccessLogItem Created");
      })
      .catch(this.errStore.handlerErrors);
  };

  // Change Log Items

  PostKafka = (values) => {
    POST(`/test`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setInfo(true);
        this.errStore.handlerCussess("Message Published");
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("Something went wrong");
      });
  };

  // Publish to kafka

  Publish = (values) => {
    PUBLISH(`/kafka/publish`, values)
      .then((data) => {
        // this.errStore.setMessage(data);
        // this.errStore.setInfo(true);
      })
      .catch(this.errStore.handlerErrors);
  };

  isAnalysing = false;

  setIsAnalyzing = (value) => {
    this.isAnalysing = value;
  };

  isAnalysingFinished = false;

  setIsAnalyzingFinished = (value) => {
    this.isAnalysingFinished = value;
  };

  setAnalyseModal = (value) => {
    this.analyseModal = value;
  };

  textWords = [];

  setTextWords = (value) => {
    this.textWords = value;
  };

  GetAnalyse = async (pId, type) => {
    console.log(type);
    // this.setAnalyseModal(true);
    this.errStore.setLoading(true);
    this.setIsAnalyzing(true);
    this.setAnalyseEntities([]);
    let temp = [];
    GET_SDMA(`/api/projects/${pId}/links?sourceDomainId=${type}`)
      .then((data) => {
        // this.errStore.setMessage("");
        // this.errStore.setLoading(false);
        data.data.map((item) => {
          if (
            this.mapping.filter((data) => data.type1 == item.type).length > 0
          ) {
            let request_type = this.mapping.filter(
              (data) => data.type1 == item.type
            )[0].type2;
            GET_SDMA(
              `/api/projects/${pId}/entities?type=${request_type}&domainId=${item.targetDomainId}`
            )
              .then((data) => {
                // this.errStore.setMessage("");
                // this.errStore.setLoading(false);
                if (data.data != []) {
                  let word = {
                    text: data.data[0].title,
                    type: data.data[0].type,
                    color: this.mapping.filter(
                      (item) => item.type2 == data.data[0].type
                    )[0].color,
                  };
                  temp.push(word);
                  // this.analyse_entities.push(word);
                  this.setAnalyseEntities(temp);
                }
              })
              .catch((error) => {
                // this.errStore.setError(true);
                // this.errStore.setMessage("SDMA ERROR");
                console.log(error);
              });
          }
        });
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
    this.setIsAnalyzing(false);
    this.setIsAnalyzingFinished(true);
  };

  GetAnalyseEntities = (type) => {
    this.errStore.setLoading(true);
    GET_SDMA(
      `/api/projects/${"86b8b6bd42ce110000000900"}/entities?sourceDomainId=${type}`
    )
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setAnalyseEntities(data.data);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
      });
  };

  StoreData = (type, data) => {
    let values = {
      type: "",
      tags: [],
      domainId: "",
      attributes: {
        additionalProp1: [],
        additionalProp2: [],
        additionalProp3: [],
      },
    };
    this.errStore.setLoading(true);
    values.type = type;
    values.tags = [];
    values.domainId = uuidv4();
    values.attributes = {
      additionalProp1: [data],
      additionalProp2: [],
      additionalProp3: [],
    };

    POST_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities`, values)
      .then((data) => {
        this.errStore.setMessage("Data Stored");
        this.errStore.setInfo(true);
        this.GetStoredMissions();
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
      });
  };

  GetStoredMissions = () => {
    GET_SDMA(`/api/projects/86b8b6bd42ce110000000900/entities?type=mission`)
      .then((data) => {
        console.log(data);
        let temp = [];
        data.data.map((item) => {
          temp.push(JSON.parse(item.attributes.additionalProp1[0]));
        });
        let temp2 = [];
        temp.map((item) => {
          item.map((value) => {
            temp2.push(value);
          });
        });
        this.setStoredMissions(temp2);
      })
      .catch((error) => {
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
      });
  };

  // missions
  DeleteMission = (id) => {
    this.setIsItemDeleting(true);
    DELETE_SDMA(`api/projects/86b8b6bd42ce110000000900/entities/${id}`)
      .then((data) => {
        this.GetTotalMissions();
      })
      .catch((error) => {
        this.setIsItemDeleting(false);
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA ERROR");
        console.log(error);
      });
  };

  total_missions = [];

  setTotalMission = (value) => {
    this.total_missions = value;
  };

  AddOperationToMission = (values) => {
    values.active = "false";
    this.pickedMission.attributes.additionalProp2.push(JSON.stringify(values));
    console.log(this.pickedMission);
    PUT_SDMA(
      `/api/projects/86b8b6bd42ce110000000900/entities/${this.pickedMission.id}`,
      this.pickedMission
    )
      .then((data) => {
        this.setIsOperationModalOpen(false);
        this.GetTotalMissions();
      })
      .catch((error) => {
        this.setIsDroneCreating(false);
        this.errStore.setError(true);
        this.errStore.setMessage("SDMA Error");
        console.log(error);
      });
  };
}

export default WebCrawlStore;
