import axios from "axios";
import { constants } from "../consts/Variables";

export const mapUrl = `https://map.bulgaria.odysseus-${constants.MODE}.rid-intrasoft.eu/tile/{z}/{x}/{y}.png`;
export const keycl = `${constants.AUTH_URL}/auth/realms/${constants.AUTH_REALM}/protocol/openid-connect/token/introspect`;
export const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000/api/v1"
    : `${constants.API_URL}/api/v1`;

export const THIN_API = `${constants.THIN_API_HTTP}`;

export const SDMA_API = `${constants.SDMA_API}`;

export const ATDL_URL = `${constants.ATDL_URL}`;

const keyclapi = axios.create({ baseURL: keycl });

const api = axios.create({ baseURL: API_URL });

export const ETD_API =
  process.env.NODE_ENV === "development"
    ? "https://etd-api.odysseus-online.rid-intrasoft.eu/"
    : constants.ETD_API;

export const TAS_WRAPPER_URL =
  process.env.NODE_ENV === "development"
    ? "https://tas-wrapper.odysseus-online.rid-intrasoft.eu/"
    : constants.TAS_WRAPPER_URL;

const apiReal = axios.create({
  baseURL: ETD_API,
});

const thin_api = axios.create({ baseURL: THIN_API });

const sdma_api = axios.create({ baseURL: SDMA_API });

const tas_api = axios.create({ baseURL: TAS_WRAPPER_URL });

const token = localStorage.getItem("od_token");

const headers = {
  headers: {
    Authorization: `Token ${token}`,
  },
};

export const PUBLISH = async (url, values) => {
  const { data } = await thin_api.post(url, values, headers);
  return data;
};

export const PUBLISH_MANY = async (url, data) => {
  return axios
    .all(data.map((obj) => thin_api.post(url, obj, headers)))
    .then((response) => {
      return response;
      console.log(response);
    });
};

export const POST = async (url, values) => {
  const { data } = await api.post(url, values, headers);
  return data;
};

export const GET = async (url) => {
  const { data } = await api.get(url);
  return data;
};

export const PUT = async (url, values) => {
  const { data } = await api.put(url, values, headers);
  return data;
};

export const DELETE = async (url, values) => {
  const { data } = await api.delete(url, headers);
  return data;
};

// SDMA REQUESTS
const headers_keycl = {
  headers: {
    Authorization: `Basic ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
export const keycloakApi = async (url) => {
  const { data } = await keyclapi.post(url, {}, headers_keycl);
  return data;
};

const headers_sdma = {
  headers: {
    Authorization: `Bearer ${constants.SDMA_KEY}`,
  },
};
// export const keycloakApi = async (url) => {
//   const { data } = await keyclapi.post(url, {}, headers_sdma);
//   return data;
// };

export const POST_SDMA = async (url, values) => {
  const { data } = await sdma_api.post(url, values, headers_sdma);
  return data;
};

export const POST_TAS_CONFIG = async (url, values) => {
  const { data } = await tas_api.post(
    url,
    values
    // headers_sdma
  );
  return data;
};

export const GET_TAS_CONFIG = async (url, values) => {
  const { data } = await tas_api.get(
    url
    //  headers_sdma
  );
  return data;
};

export const GET_SDMA = async (url) => {
  const { data } = await sdma_api.get(url, headers_sdma);
  return data;
};

export const GET_SDMA_MANY = async (urls) => {
  return axios
    .all(urls.map((url) => sdma_api.get(url, headers_sdma)))
    .then((response) => {
      return response;
      console.log(response);
    });
};

export const GET_LIVE_ATDL = async () => {
  const { data } = await axios.get(ATDL_URL + "/analysis?preProcess=true");
  return data;
};

export const POST_SDMA_MANY = async (data) => {
  return axios
    .all(data.map((obj) => sdma_api.post(obj.url, obj.value, headers_sdma)))
    .then((response) => {
      return response;
      console.log(response);
    });
};

export const DELETE_SDMA_MANY = async (urls) => {
  return axios
    .all(urls.map((url) => sdma_api.delete(url, headers_sdma)))
    .then((response) => {
      return response;
      console.log(response);
    });
};

export const GET_SDMA_IMAGE = async (url) => {
  const { data } = await sdma_api.get(url, {
    responseType: "blob",
    headers: headers_sdma.headers,
  });
  return data;
};

export const DELETE_SDMA = async (url) => {
  const { data } = await sdma_api.delete(url, headers_sdma);
  return data;
};

export const PUT_SDMA = async (url, values) => {
  const { data } = await sdma_api.put(url, values, headers_sdma);
  return data;
};

// Realtime test requests
// export const POST_Test = async (url) => {
//   const { data } = await apiReal.get(url);
//   return data;
// };
export const POST_Test = async (url) => {
  try {
    const { data } = await apiReal.get(url);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);

    // Check if it's a CORS error
    if (error.response && error.response.status === 0) {
      console.error("CORS error detected.");
    }

    // Handle the error based on your app's needs
    // You can return a default value, throw a custom error, or handle it in any other way
    return null; // or an appropriate fallback value
  }
};
