import { makeAutoObservable } from "mobx";

class UserStore {
  constructor({ errStore, textStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
    this.textStore = textStore;
  }

  isLoginModalOpen = false;
  isUserTryingToLogin = false;
  isUserSuperAdmin = false;
  token = null;
  name = null;
  loginTime = 0;

  setLoginTime = (value) => {
    this.loginTime = value;
  };

  setToken = (value) => {
    this.token = value;
  };

  setName = (value) => {
    this.name = value;
  };

  init = () => {
    this.token = localStorage.getItem("od_token");
    this.name = localStorage.getItem("od_name");
  };

  userLoginDetails = [
    {
      name: ["username"],
      value: "",
    },
    {
      name: ["password"],
      value: "",
    },
  ];
}

export default UserStore;
