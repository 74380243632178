import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Divider,
  Button,
  Input,
  Form,
  message,
  Upload,
  Table,
  Select,
} from "antd";
import { useMobxStores } from "../stores/stores";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import { getOverridedColumns } from "../utils/tableLoader";
import UploadData from "../components/upload-data/UploadData";

const { Option } = Select;

var _ = require("lodash");

const Dashboard = () => {
  const { mainStore } = useMobxStores();
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    mainStore.GetWebDataCollection();
  }, []);
  useEffect(() => {
    // Redirect to "/missions" when the component mounts
    navigate("/missions");
  }, []);

  const onClickStopTransaction = (values) => {
    mainStore.StopWebDataCollection(values.id);
  };

  const data_collection_columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "title",
      width: "10%",
    },
    {
      title: "endpointRunStatus",
      dataIndex: "endpointRunStatus",
      key: "title",
      width: "10%",
    },
    {
      title: "Urls",
      dataIndex: "attributes",
      key: "attributes",
      width: "10%",
      render: (attributes) => (
        <p style={{ color: "#00703c" }}>
          {attributes.urls.map((item, index) => {
            return (
              <a key={index} href={item}>
                {item}
              </a>
            );
          })}
        </p>
      ),
    },
    {
      title: "Actions",
      responsive: ["lg", "sm", "md", "xs"],
      key: "action",
      width: "10%",
      render: (record) => (
        <Button
          size="small"
          type="primary"
          danger
          style={{ width: "auto" }}
          onClick={() => onClickStopTransaction(record)}
        >
          Delete this data collection
        </Button>
      ),
    },
  ];

  const onChange = (info) => {
    console.log(info);
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onClickStartTransaction = (values) => {
    const currentDate = new Date();

    const formattedDate = currentDate.toISOString().slice(0, 19) + "+0000";

    let correct = {
      type: "Web",
      status: "Adhoc",
      projectId: "86b8b6bd42ce110000000900",
      endpointRunStatus: "Waiting",
      nextScheduled: "2023-04-18T09:30:00.000Z",
      expiresAfter: "2023-04-20T09:30:00.000Z",
      maxTaskInterval: 10,
      minTaskInterval: 5,
      depth: 1,
      attributes: {
        urls: [
          "https://www.sciencemadness.org/member.php?action=viewpro&member=Marvin",
        ],
        protocol: ["Web"],
        operationId: ["e661d6ba-638c-4d76-bf30-6b45938627f9"],
        depth: [1],
      },
    };

    // pleon paei kafka me to akinouyrgio message
    let post_values = {
      type: "Web",
      status: "Adhoc",
      projectId: "86b8b6bd42ce110000000900",
      endpointRunStatus: "Waiting",
      nextScheduled: "2023-04-18T09:30:00.000Z",
      expiresAfter: "2023-04-20T09:30:00.000Z",
      maxTaskInterval: 10,
      minTaskInterval: 5,
      depth: 1,
      attributes: {
        urls: [values.url],
        protocol: ["Web"],
        operationId: [uuidv4()],
        depth: [values.depth],
      },
    };

    let kafka_web_crawl_message = {
      header: {
        topicName: "TOP200_WEB_CRAWLING_REQUEST",
        sender: "INTRA",
        sentUtc: formattedDate,
        source: "Dashboard",
        caseId: "86b8b6bd42ce110000000900",
      },
      body: {
        sessionId: "234sphkfdf112sdasobjsdfy",
        enabled: true,
        urls: [values.url],
        depth: [values.depth],
      },
    };
    mainStore.StartWebDataCollection(post_values);
    // mainStore.Publish({
    //   topics: ["TOP200_WEB_CRAWLING_REQUEST"],
    //   body: JSON.stringify(kafka_web_crawl_message),
    // });
  };

  const types = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
  ];

  return (
    <div className="full-viewport">
      <Row justify="center" align="middle">
        <Divider orientation="left"> Dashboard</Divider>
        <Col span={18} xs={24} sm={24} md={18} xxl={18} xl={18}>
          <Form
            name="drone-form"
            onFinish={onClickStartTransaction}
            layout="horizontal"
          >
            <Row>
              <Col>
                <Form.Item
                  name={"url"}
                  rules={[
                    {
                      required: true,
                      message: `Please input ${"url"}!`,
                      type: "url",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setUrl(e.target.value)}
                    type="url"
                    placeholder="Start endpoint"
                  />
                </Form.Item>
                <Form.Item
                  name="depth"
                  rules={[
                    {
                      required: true,
                      message: "Please input depth!",
                    },
                  ]}
                >
                  <Select placeholder={"depth"}>
                    {types.map((item, index) => {
                      return (
                        <Option key={index} value={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col>
                <Form.Item>
                  <Button
                    // disabled={url == "" ? true : false}
                    type="primary"
                    htmlType="submit"
                  >
                    Start Web data collection
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={4} xs={24} sm={24} md={4} xxl={4} xl={4}>
          {/* <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="text"
            onChange={onChange}
          >
          <Button
            style={{
              width: "200px",
            }}
            disabled={false}
            type="primary"
            icon={<ArrowUpOutlined />}
          >
            Import batch Data
          </Button>
          </Upload> */}
          <UploadData type="dataCollection" text={"Import batch Data"} />
        </Col>
        <Col span={24}>
          <Divider>Active Data collections</Divider>
          <Table
            dataSource={
              mainStore.webdata.length
                ? _.cloneDeep(mainStore.webdata)
                : Array(5).fill({})
            }
            columns={
              mainStore.webdata.length
                ? data_collection_columns
                : getOverridedColumns(data_collection_columns)
            }
            width="auto"
            rowKey={() => uuidv4()}
            bordered={true}
            defaultSortOrder="descend"
            scroll={{ x: "400px" }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default observer(Dashboard);
