import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Divider,
  Button,
  Select,
  Radio,
  Input,
  Checkbox,
} from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import Loader from "../../components/loader/Loader";
import EmptyData from "../../components/emptydata/EmptyData";
import { mdiTranslate, mdiGraph, mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";
import { ReactComponent as TextSearchIcon } from "../../assets/textSearch.svg";
import { FileTextOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
const { Option } = Select;

const TextTranslationPage = (props) => {
  const { mainStore, webCrawlStore, errorStore } = useMobxStores();

  const [itemShown, setItemShown] = useState("");

  const [chosenClassification, setChosenClassification] = useState("");
  const [tabContent, setTabContent] = useState("1");
  const [tabTop, setTabTop] = useState("1");
  const [clusterData, setClusterData] = useState([]);
  const [loading, setLoading] = useState(false);

  const sort_options = [
    { name: "All", value: "All" },
    { name: "Unclassified", value: "Unclassified" },
    { name: "HME", value: "HME" },
    { name: "non-HME", value: "non-HME" },
  ];
  const [value, setValue] = useState(1);
  const changeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
    if (e.target.value === 1) {
      webCrawlStore.setSelectedCluster("All");
    } else {
      webCrawlStore.setSelectedCluster("");
    }
  };
  const setSelectedCluster = (e) => {
    webCrawlStore.setSelectedCluster(e.target.value);
  };

  const handleChangeClassification = (value) => {
    webCrawlStore.setSelectedClassification(value);
  };

  const onCheckWithVisualAnalysis = () => (e) => {
    let value = e.target.checked;
    webCrawlStore.setWithVisualAnalysis(value);
  };

  const setWithTransaltionChecked = () => (e) => {
    let value = e.target.checked;
    webCrawlStore.setWithTransaltionChecked(value);
  };

  var _ = require("lodash");
  let params = useParams();

  // Function to handle sorting based on classification

  return (
    <div>
      <Row justify="space-between">
        <span>
          <span style={{ fontWeight: "bold", marginLeft: "4px" }}>
            Classification:
          </span>
          <span>
            {" "}
            <Select
              // onChange={handleChangeClassification()}
              value={webCrawlStore.selectedClassification}
              placeholder={"sort items"}
              width={"auto"}
              style={{ width: "130px", marginTop: "4px" }}
              popupMatchSelectWidth={false}
            >
              {sort_options.map((item, index) => {
                return (
                  <Option key={index} value={item.name}>
                    <Button
                      style={{ width: "100%" }}
                      type="text"
                      onClick={() => handleChangeClassification(item.value)}
                    >
                      {item.name}
                    </Button>
                  </Option>
                );
              })}
            </Select>
          </span>
          <span
            style={{
              marginLeft: "12px",
              fontWeight: "bold",
            }}
          >
            Cluster:
          </span>
          <span
            style={{
              marginLeft: "4px",
            }}
          >
            <Radio.Group onChange={changeRadio}>
              <Radio value={1}>
                <div
                  style={{
                    marginLeft: "-4px",
                  }}
                >
                  All
                </div>
              </Radio>
              <Radio
                value={2}
                style={{
                  marginLeft: "0px",
                }}
              >
                <span
                  style={{
                    marginLeft: "-4px",
                  }}
                >
                  Select
                </span>

                {value === 2 ? (
                  <Input
                    value={webCrawlStore.selectedCluster}
                    onChange={(e) => setSelectedCluster(e)}
                    style={{
                      width: 52,
                      marginLeft: 4,
                    }}
                  />
                ) : null}
              </Radio>
            </Radio.Group>
          </span>
        </span>
      </Row>
      <Row
        style={{
          marginTop: 4,
        }}
      >
        <span>
          <span style={{ fontWeight: "bold", marginLeft: "4px" }}>
            With translation:
          </span>
          <span>
            {" "}
            <Checkbox
              onChange={setWithTransaltionChecked()}
              checked={webCrawlStore.withTransaltionChecked}
            ></Checkbox>
          </span>
        </span>
        <span
          // style={{
          //   marginLeft: 14,
          // }}
          style={{ fontWeight: "bold", marginLeft: "14px" }}
        >
          {" "}
          With visual analysis:
        </span>
        <span
          style={{
            marginLeft: 4,
          }}
        >
          {" "}
          <Checkbox
            onChange={onCheckWithVisualAnalysis()}
            checked={webCrawlStore.withVisualAnalysis}
          ></Checkbox>
        </span>
      </Row>
      <Divider
        style={{
          "background-color": "grey",
          marginBottom: "5px",
          marginTop: "3px",
        }}
      ></Divider>
    </div>
  );
};

export default observer(TextTranslationPage);
