import React from "react";
import { Col, Row, Divider, Tag } from "antd";

const Profile = (props) => {
  let user = window.localStorage.getItem("user");
  user = JSON.parse(user);

  return (
    <Row justify="center" align="center" className="full-viewport">
      <Col span={8} lg={8} xs={24} sm={24}>
        <Divider>User Profile</Divider>
        <p>
          <b>Username:</b> {user.username}
        </p>
        <p>
          <b>ID:</b> {user.id}
        </p>
        <p>
          <b>Email Verified:</b>{" "}
          {user.emailVerified ? (
            <Tag color="green">Yes</Tag>
          ) : (
            <Tag color="red">no</Tag>
          )}
        </p>
      </Col>
    </Row>
  );
};

export default Profile;
