import { getEnvVar } from "./envVars";

export const constants = {
  API_URL: getEnvVar("{API_URL}"),
  AUTH_URL: getEnvVar("{AUTH_URL}"),
  AUTH_REALM: getEnvVar("{AUTH_REALM}"),
  AUTH_CLIENT: getEnvVar("{AUTH_CLIENT}"),
  AUTH_CLIENT_SECRET: getEnvVar("{AUTH_CLIENT_SECRET}"),
  THIN_API_HTTP: getEnvVar("{THIN_API_HTTP}"),
  THIN_API_WS: getEnvVar("{THIN_API_WS}"),
  SDMA_API: getEnvVar("{SDMA_API}"),
  SDMA_KEY: getEnvVar("{SDMA_KEY}"),
  STREAM_SERVER_URL: getEnvVar("{STREAM_SERVER_URL}"),
  UAV_STREAM_SERVER_URL: getEnvVar("{UAV_STREAM_SERVER_URL}"),
  ONLINE: getEnvVar("{ONLINE}"),
  ETD_API: getEnvVar("{ETD_API}"),
  TAS_WRAPPER_URL: getEnvVar("{TAS_WRAPPER_URL}"),
  ATDL_URL: getEnvVar("{ATDL_URL}"),
};
