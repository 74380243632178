import React, { useState, useEffect } from "react";
import { Col, Row, Divider, Card } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import Chart from "react-apexcharts";
import EmptyData from "../emptydata/EmptyData";

var _ = require("lodash");

const WaterSensorPeaks = (props) => {
  const { mainStore, fieldHistoryStore } = useMobxStores();

  useEffect(() => {}, [mainStore.sensor_water_data, mainStore.gas]);
  const gass = [4.99933222, 6.332222, 6.38228082];
  const gass2 = [1111000, 1116000, 1130000];
  const gass3 = [6.320802, 8.367982, 16.7567232];

  const formattedDate = (d) => {
    const date = new Date(d); // Assuming 223223 represents milliseconds

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${hours}:${minutes}:${seconds}  ${year}-${month}-${day}`;
  };

  const formattedTime = (d) => {
    const date = new Date(d); // Assuming 223223 represents milliseconds

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${hours}:${minutes}:${seconds} `;
  };

  return (
    <Row justify="center" align="center">
      <Col span={24} lg={24} xs={24} sm={24}>
        <Divider style={{ marginTop: "42px", marginBottom: "-2px" }}>
          {" "}
          Water Sensor Peaks
        </Divider>
        {_.cloneDeep(fieldHistoryStore.waterSensorY).length != 0 &&
        (_.cloneDeep(fieldHistoryStore.waterSensorH202).length != 0 ||
          _.cloneDeep(fieldHistoryStore.waterSensorNH3).length != 0) ? (
          /* {_.cloneDeep(gass).length != 0 && _.cloneDeep(gass2).length != 0 ? ( */
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                marginTop: "-20px",
                marginLeft: "46%",
                zIndex: 10,
              }}
            >
              Concentration
            </div>
            <div
              style={{
                position: "absolute",
                marginTop: "20px",
                marginLeft: "80%",
                padding: "4px",
                zIndex: 11,
                border: "solid black 1px",
              }}
            >
              {/* <div style={{ backgroundColor: "#F5F5F5" }}>
                <div>Last Measurements</div>
                <div>
                  <span>on </span>
                  <span>
                    {formattedTime(
                      _.cloneDeep(mainStore.sensor_water_data.header.published)
                    )}
                  </span>
                </div>
              </div> */}
              {/* {mainStore.gas && mainStore.gas[0] && mainStore.gas[0].gas ? (
                <div>
                  <span style={{ fontWeight: "bold" }}>
                    {_.cloneDeep(mainStore.gas[0].gas)} :
                  </span>{" "}
                  <span style={{ fontSize: "22px", color: "#314881" }}>
                    {_.cloneDeep(mainStore.gas[0].data).toFixed(2)}
                  </span>{" "}
                  <span>{_.cloneDeep(mainStore.gas[0].units)}</span>
                </div>
              ) : (
                <div>
                  <span style={{ marginLeft: "40px" }}>---</span>
    
                </div>
              )}
              {mainStore.gas && mainStore.gas[1] && mainStore.gas[1].gas ? (
                <div>
                  <span style={{ fontWeight: "bold" }}>
                    {_.cloneDeep(mainStore.gas[1].gas)}:
                  </span>{" "}
                  <span style={{ fontSize: "22px", color: "#f57c00" }}>
                    {" "}
                    {_.cloneDeep(mainStore.gas[1].data).toFixed(2)}
                  </span>{" "}
                  <span>{_.cloneDeep(mainStore.gas[1].units)}</span>
                </div>
              ) : (
                <div>
                  <span style={{ marginLeft: "40px" }}> --- </span>
         
                </div>
              )} */}
            </div>
            <Chart
              options={{
                dataLabels: {
                  enabled: false,
                },
                tooltip: {
                  custom: function ({
                    series,
                    seriesIndex,
                    dataPointIndex,
                    w,
                  }) {
                    const seriesName = w.config.series[seriesIndex].name;
                    const value =
                      w.config.series[seriesIndex].data[dataPointIndex];
                    const xValue = w.config.xaxis.categories[dataPointIndex];
                    const color = w.config.colors[seriesIndex];
                    return `
                    <div  style='color: ${color}; min-width: 200px ; margin : 4px' >
                     <div style='background-color: #EEEEEE; border-bottom: 1px solid black ; font-size : 14px '>${seriesName}</div>
                    <div 
                    style='margin : 4px '
                    class="custom-tooltip"> ${formattedDate(xValue)}  </div>   
                    <div  style='margin : 4px ' class="custom-tooltip"> ${value} ppm</div>
                        
                    </div>`;
                  },
                },
                legend: {
                  position: "left",
                },
                chart: {
                  id: "basic-bar",
                },
                // tooltip: {
                //   x: {
                //     format: "h:mm:ss",
                //   },
                // },
                xaxis: {
                  type: "datetime",
                  title: {
                    text: "Time",
                  },

                  categories: _.cloneDeep(fieldHistoryStore.waterSensorY),
                  // categories: _.cloneDeep(gass2),
                  labels: {
                    style: {
                      colors: "#f57c00",
                    },
                    datetimeFormatter: {
                      year: "yyyy",
                      month: "MMM 'yy",
                      day: "dd MMM",
                      hour: "HH:mm",
                    },
                  },
                },
                yaxis: {
                  title: {
                    rotate: 0,
                    text: "ppm",
                    offsetX: -20,
                  },
                  min: 20,
                  max: 1500,
                  categories: ["1", "1"],
                  labels: {
                    style: {
                      colors: "#f57c00",
                    },
                    formatter: function (value) {
                      return value.toFixed(2); // Displaying two decimals
                    },
                  },
                },
                markers: {
                  size: 6,
                  strokeWidth: 0,
                  hover: {
                    sizeOffset: 2,
                  },
                },
                colors: ["#f57c00", "#314881"],
              }}
              series={[
                {
                  name: "H2O2 ",
                  data: _.cloneDeep(fieldHistoryStore.waterSensorH202),
                  // data: _.cloneDeep(gass),
                },
                {
                  name: "NH3 ",
                  data: _.cloneDeep(fieldHistoryStore.waterSensorNH3),
                  // data: _.cloneDeep(gass3),
                },
              ]}
              type="area"
              style={{
                color: "#f57c00",
                width: "50vw",
                minHeight: "300px",
                maxHeight: "360px",
                marginTop: "20px",
              }}
              height={300}
            />
          </div>
        ) : (
          <EmptyData />
        )}
      </Col>
    </Row>
  );
};

export default observer(WaterSensorPeaks);
