import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import axios from "axios";
import _ from "lodash";
import EmptyData from "../emptydata/EmptyData";

const mapStyle = { minHeight: "400px", width: "auto", borderRadius: "15px" };

const customMarkerAir = new L.icon({
  iconUrl: "../../../../../orange-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customMarkerWater = new L.icon({
  iconUrl: "../../../../../blue-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customMarkerNtdl = new L.icon({
  iconUrl: "../../../../../red-pin.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const TASMap = (props) => {
  const { mainStore } = useMobxStores();
  const [maxBounds, setMaxBounds] = useState(null);

  useEffect(() => {
    axios
      .get(
        "https://cdn.rawgit.com/johan/world.geo.json/34c96bba/countries/GRC.geo.json"
      )
      .then((response) => {
        const geoJSON = response.data;
        setMaxBounds(L.geoJSON(geoJSON).getBounds());
      });
  }, [
    setMaxBounds,
    mainStore.TAS_markers,
    mainStore.TASWater,
    mainStore.TASNtdl,
    mainStore.TAS,
  ]);

  console.log(
    "water",
    [mainStore.TASWater.latitude, mainStore.TASWater.longitude],
    "air:",
    [mainStore.TAS.latitude, mainStore.TAS.longitude]
  );

  return maxBounds ? (
    <div>
      <div>
        {" "}
        <span>
          {" "}
          <img width="26" src="../../../../../orange-pin.png" />
        </span>
        <span> = Air Sensor Marker</span>
        <span>
          {" "}
          <img width="26" src="../../../../../blue-pin.png" />
        </span>
        <span> = Water Sensor Marker</span>
      </div>
      <div id="map">
        {mainStore.TAS != "" ||
        mainStore.TASWater != "" ||
        mainStore.TASNtdl != "" ? (
          <MapContainer
            zoom={3}
            minZoom={1}
            scrollWheelZoom={true}
            style={mapStyle}
            center={
              mainStore.TASWater != ""
                ? [mainStore.TASWater.latitude, mainStore.TASWater.longitude]
                : [mainStore.TAS.latitude, mainStore.TAS.longitude]
            }
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {mainStore.TAS != "" &&
              mainStore.TAS.latitude != null &&
              mainStore.TAS.longitude != null && (
                <Marker
                  position={
                    mainStore.TAS != "" && [
                      mainStore.TAS.latitude,
                      mainStore.TAS.longitude,
                    ]
                  }
                  icon={customMarkerAir}
                >
                  <Tooltip title="air"></Tooltip>
                </Marker>
              )}
            {mainStore.TASWater != "" &&
              mainStore.TASWater.latitude != null &&
              mainStore.TASWater.longitude != null && (
                <Marker
                  position={
                    mainStore.TASWater != "" && [
                      mainStore.TASWater.latitude,
                      mainStore.TASWater.longitude,
                    ]
                  }
                  icon={customMarkerWater}
                >
                  {" "}
                  <Tooltip title="water"></Tooltip>
                </Marker>
              )}
          </MapContainer>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default observer(TASMap);
